import { post } from './request';

const product = {
  create: (data, isUpdate = false) => {
    return post('/api/createOrderItem', {
      ...data,
      isUpdate,
    }).then((productInfo) => productInfo?.[0]?.id);
  },
  update: (data) => {
    return product.create(data, true);
  },
  addAll: (productArray = [], orderId, country) => {
    return Promise.all(
      productArray.forEach((item) => {
        product.create({ ...item, orderId, country });
      }),
    ).then(() => true);
  },
  updateAll: (productArray = [], orderId, country) => {
    Promise.all(
      productArray.forEach((item) => {
        product.update({ ...item, orderId, country });
      }),
    ).then(() => true);
  },
  getLocaleId: (country, lang) =>
    post('/api/getLocaleId', { country, lang }).then(
      ({ localeId }) => localeId,
    ),
};
export default product;
