export default function JapanEnrollmentTooltip({ data }) {
  const { item4 } = data?.fields;
  return (
    <section>
      <div className="info-alert form-max">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM9 12V4H11V12H9ZM9 14V16H11V14H9Z"
          />
        </svg>
        <p>{item4}</p>
      </div>
    </section>
  );
}
