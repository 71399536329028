import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

// const CODES = {
//   1: { cause: "Validation Success", description: "You're you!" },
//   2: { cause: "Statement Mismatch", description: "Resident registration number is searched, but name does not match" },
//   3: { cause: "No Data", description: "Failure to check resident registration number or alien name mismatch" },
//   4: { cause: "System Failure", description: "Company network failure or firewall" },
//   5: { cause: "Resident Number Checksum Error", description: "Resident number does not comply with the resident number creation rules" },
//   6: { cause: "Minor Error", description: "In case of contract with adult verification service, it occurs when entering resident registration number of those under 19 years of age" },
//   9: { cause: "Input Information Error", description: "Missing/abnormal input, bad encoding, or blocked resident number (due to illegal use)" },
//   10: { cause: "Site Code Error", description: "Something's wrong with the credentials used" },
//   11: { cause: "Freeze Site Error", description: "Our contract has been terminated or suspended" },
//   12: { cause: "Password Mismatch Error", description: "Password doesn't match the Site Code" },
//   13: { cause: "Authentication System Error", description: "We need to update the module" },
//   15: { cause: "Decryption Data Error", description: "We need to update the module" },
//   16: { cause: "Decryption System Error", description: "We need to update the module" },
//   21: { cause: "Input Information Format Error", description: "Bad input. Resident/Alien number should be 13 digits, password should be 8 digits" },
//   24: { cause: "Cryptographic Operation Error", description: "Incorrect resident/alien ID" },
//   31: { cause: "Connection Failure Error", description: "Firewall, maybe?" },
//   32: { cause: "Connection Failure Error", description: "Firewall, maybe?" },
//   34: { cause: "Connection Failure Error", description: "Firewall, maybe?" },
//   44: { cause: "Connection Failure Error", description: "Firewall, maybe?" },
//   50: { cause: "Identity Theft Blocking Error", description: "This resident ID currently has identity theft blocking enabled, it needs to be temporarily disabled" },
//   55: { cause: "Foreigner Number Error", description: "Foreigner number verification failed" },
//   56: { cause: "Foreigner Number Error", description: "Foreigner number verification failed" },
//   57: { cause: "Foreigner Number Error", description: "Foreigner number verification failed" },
//   58: { cause: "KAIT Communication Error", description: "Immigration office alien registration db communication failed" },
//   60: { cause: "Network Failure", description: "Cannot communicate with remote host" },
//   61: { cause: "Network Failure", description: "Cannot communicate with remote host" },
//   62: { cause: "Network Failure", description: "Cannot communicate with remote host" },
//   63: { cause: "Network Failure", description: "Cannot communicate with remote host" }
// }
// https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb?m=authMain

const CallbackContainer = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const status = queryParams.get('code')
  const encodeData = queryParams.get('EncodeData')
  const plainData = queryParams.get('PlainData')

  useEffect(() => {
    if (status && encodeData && window.opener && !window.opener.closed) {
      window.opener.postMessage({
        status,
        encodeData,
        plainData,
      }, '*');
      window.close()
    }
  }, [
    status,
    encodeData,
    plainData,
  ])

  return null

}

export default CallbackContainer