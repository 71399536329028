import { Typography, Paper, Stack } from '@newulife/common'

/**
 * @props title (optional) Renders a title before a shadow card
 */

function ShadowCard(props) {
  const { children, title, ...rest } = props;
  return (
    <Stack
      data-testid={`${title?.replace(/\W/g, '')}-section`}
      width="100%"
      pb={4}
      {...rest}
    >
      {title && (
        <Typography
          variant="body3"
          textAlign="center"
          mb={2}
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {title}
        </Typography>
      )}
      <Paper
        elevationVariant="section"
        sx={{
          width: '100%',
          p: 2,
          borderRadius: '17px',
        }}
      >
        {children}
      </Paper>
    </Stack>
  );
}

export default ShadowCard;
