import {
  match,
  govIdValidation,
} from 'components/common/form/formValidationRules';
import { SSNRegex } from 'helpers/regex';

const US = {
  market: 'US',
  enrollment: {
    form: {
      sections: {
        shipping: {
          fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'STATE', 'ZIP', 'COUNTY'],
        },
        tax: {
          fields: ['IDENTITY'],
        },
      },
    },
  },
  inputs: {
    fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'STATE', 'ZIP', 'COUNTY'],
    ZIP: {
      validationChecks: [match(/^[0-9]{5}(?:-[0-9]{4})?$/)],
    },
    IDENTITY: {
      labelRef: 'SSN',
      validationChecks: [match(SSNRegex)],
      required: false,
      // pattern: SSNRegex,
      onBlur: govIdValidation,
    },
    COUNTY: {
      hide: true,
      required: false,
    },
  },
};
export default US;
