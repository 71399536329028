import Modal from './Modal';
import { Box, Typography } from '@newulife/common';
import { parseRichText } from 'helpers/contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import './styles/TermsPageModal.scss';

export default function TermsPageModal({ close, modalText, ...rest }) {

  return (
    <Modal 
      confirmationType={false}
      cancelType 
      id="terms-page-modal" 
      close={close} 
      modalStyles={{ overflow: 'auto', minHeight: '50%' }}
    >
      {/* <section className="modal-title">
        {title && (
          <Typography
            as="h2"
            variant="header1"
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            as="p"
            variant="body3"
          >
            {subtitle}
          </Typography>
        )}
      </section> */}
      <section className="modal-content terms-modal-content">
        {modalText && parseRichText(modalText)}
      </section>
      {/* <Box p={4}>
        {modalText && documentToReactComponents(modalText)}
      </Box> */}
    </Modal>
  );
}