import { useMemo, memo, useEffect } from 'react'
import { Button, Stack, Typography, Link, Alert, Grid } from '@newulife/common'
import ShadowCard from 'components/common/cards/ShadowCard'
import useCheckPlus from 'hooks/useCheckPlus'
import { ReactComponent as IconPhone } from 'assets/svg/phone.svg'
import { find } from 'lodash'
import { useRecoilState } from 'recoil'
import { inputState } from 'state'

const KRPhoneValidation = memo(({
  dictionary,
  onValid,
}) => {
  const startVerification = useCheckPlus()
  const [inputs, setInputs] = useRecoilState(inputState)

  const [
    phoneLabel,
    clearLabel,
    verifyLabel,
    niceError,
    niceSuccess,
  ] = useMemo(() => {
    return [
      find(dictionary, { id: 'PHONE' })?.text,
      find(dictionary, { id: 'CLEAR' })?.text,
      find(dictionary, { id: 'VERIFY_PHONE_NUMBER' })?.text,
      find(dictionary, { id: 'NICE_ERROR' })?.text,
      find(dictionary, { id: 'NICE_SUCCESS' })?.text,
    ]
  }, [dictionary])

  const handleClear = () => {
    setInputs(prev => ({
      ...prev,
      PHONE: undefined,
      PHONE_STATUS: undefined,
      PHONE_ERROR_CODE: undefined,
      miscFields: {
        ...prev.miscFields || {},
        IsPhoneValidated: false
      }
    }))
  }

  useEffect(() => {
    onValid(inputs.PHONE_STATUS === 'success')
  }, [inputs.PHONE_STATUS])

  return (
    <>
      {inputs.PHONE ? (
        <Stack
          display="grid"
          gridAutoFlow="column"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="body3"
            color="neutralCool.main"
          >
            {phoneLabel}: {inputs.PHONE}
          </Typography>
          <Link
            sx={{
              cursor: 'pointer',
            }}
            onClick={handleClear}
          >
            {clearLabel}
          </Link>
        </Stack>
      ) : (
        <Button
        className="phone-validation-btn"
          onClick={startVerification}
        >
          <IconPhone />
          {verifyLabel}
        </Button>
      )}
      {inputs.PHONE_STATUS && (
        <Alert
          severity={inputs.PHONE_STATUS}
          variant="standard"
        >
          <Grid gap={0.5} direction="column">
            <Typography align="center" variant="body2">
              {inputs.PHONE_STATUS === 'success' ? niceSuccess : `${niceError}: ${inputs.PHONE_ERROR_CODE}`}
            </Typography>
          </Grid>
        </Alert>
      )}
    </>
  )
})

const KRPhoneValidationSection = ({
  data: { title },
  inputs: dictionary,
  isValid: onValid,
}) => {

  return (
    <ShadowCard
      title={title}
    >
      <div
        data-testid="kr-phone-validation-section"
        id="kr-phone-validation-section"
        className="form-max flex-20 column"
      >
        <KRPhoneValidation
          dictionary={dictionary}
          onValid={onValid}
        />
      </div>
    </ShadowCard>
  )
}

export default KRPhoneValidationSection