import { Typography, Stack, Link, Divider } from '@newulife/common'
import CartOrderCell from 'components/common/cart/CartOrderCell';
import CartOrderHeader from 'components/common/cart/CartOrderHeader';
import { ProductContextProvider } from 'contexts/ProductContext';
import { SubscriptionContextProvider } from 'contexts/SubscriptionContext';
import SubscriptionOrderProductModal from './SubscriptionOrderProductModal';
import { Fragment } from 'react';

export default function SubscriptionOrderProductSection({
  data,
  modalData,
  subscriptions,
  modalOpen,
  setModalOpen,
  loading,
  save,
  close,
}) {
  const { item8, item10, item11, item12, item19, item20, item21 } = data.fields;

  return (
    <>
      <Typography
        as="p"
        variant="body3"
        mb={4}
      >
        {item19}
        <strong>{item20}</strong>
      </Typography>
      <Stack
        gap={1}
      >
        <Stack
          display="grid"
          gridAutoFlow="column"
          justifyContent="flex-start"
          gap={2}
          alignItems="flex-end"
          data-testid="subscription-section"
        >
          <Typography
            as="p"
            variant="body1"
          >
            <strong>{item21}</strong>
          </Typography>
          <Link
            datatestid="edit-subscription"
            underline="hover"
            sx={{
              fontWeight: 400,
              cursor: 'pointer',
            }}
            onClick={() => setModalOpen(true)}
          >
            {item8}
          </Link>
        </Stack>
        <Divider light />
      </Stack>
      <CartOrderHeader
        productText={item10}
        qtyText={item11}
        unitPriceText={item12}
      />
      {subscriptions.map((product, index) => (
        <Fragment
          key={index}
        >
          <CartOrderCell
            imgSrc={product.SmallImageURL}
            imgAlt={product.DisplayName}
            title={product.DisplayName}
            price={product.WholesalePrice}
            cv={product.Volume}
            qv={product.Volume2}
            qty={product.Quantity}
          />
          {index !== subscriptions.length - 1 && <Divider light />}
        </Fragment>
      ))}
      {modalOpen && (
        <ProductContextProvider>
          <SubscriptionContextProvider>
            <SubscriptionOrderProductModal
              data={modalData}
              subscriptions={subscriptions}
              loading={loading}
              save={save}
              close={close}
            />
          </SubscriptionContextProvider>
        </ProductContextProvider>
      )}
    </>
  );
}
