import { useEffect, useRef, useState } from 'react';
import { isNull } from 'lodash';
import { Typography } from '@newulife/common'
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function ConfirmAndPay({ data }) {
  const { guid } = useRecoilValue(formState);
  const ref = useRef();

  const [maxHeight] = useState('1200px');

  useEffect(() => {
    if (!isNull(ref.current)) {
      sendMessage();
    }
  }, []);

  const sendMessage = () => {
    const { href } = window.location;
    console.info('Post Message Params:');
    console.info(`message: ${url}`);
    console.info(`targetOrigin: ${href}`);
    ref?.current?.contentWindow?.postMessage(url, href);
  };

  const { title, item1 } = data.fields;
  const url = `https://extranet.securefreedom.com/NewULifeSandbox/Personal/Signup/EnrollNew_Payment_Custom.asp?sessionGuid=${guid}`;

  return (
    <>
      <section className="intro-text center pb-20">
        <Typography
          variant="header1"
          mb={4}
        >
          {title}
        </Typography>
        <p className="text-center wide-desktop">{item1}</p>
      </section>
      <section>
        {guid && (
          <iframe
            ref={ref}
            src={url}
            frameBorder="0"
            scrolling="no"
            height={maxHeight}
            onLoad={() => {
              // TODO: Blocked due to cross-origin policy since the iframe is in a different domain.
              //
              // setMaxHeight(
              //   ref.current.contentWindow.document.body.scrollHeight + 'px',
              // );
            }}
          />
        )}
      </section>
    </>
  );
}
