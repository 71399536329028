import { post } from 'api/request';

//return valid address or nothing
export function secondaryAddressValidation(address) {
  // parse
  const addressObj = parseSORequestPerCountry(address);
  // pre-validate
  if (
    !addressObj ||
    (!addressObj.Address1 &&
      !addressObj.Address2 &&
      !addressObj.Address3 &&
      !addressObj.Address4 &&
      !addressObj.Address5)
  )
    return Promise.resolve();
  return post(`/api/addressValidationMigration`, addressObj)
    .then(({ AddressInfo = {} }) => {
      superLog(addressObj, AddressInfo);
      // post-validate
      return validateReturnedAddress(AddressInfo) ? AddressInfo : undefined;
    })
    .catch(() => {
      return;
    });
}

export default {
  secondaryAddressValidation,
};

// data going to Service Objects
function parseSORequestPerCountry(addressObj = {}) {
  let {
    ADDRESS1: Address1 = '',
    ADDRESS2: Address2 = '',
    ADDRESS3: Address3 = '',
    ADDRESS4: Address4 = '',
    ADDRESS5: Address5 = '',
    CITY: Locality = '',
    ZIP: PostalCode = '',
    COUNTRY: Country = '',
    OutputLanguage = 'BOTH',
    STATE: AdministrativeArea = '',
  } = addressObj;
  switch (Country) {
    case 'CN':
      OutputLanguage = 'LOCAL';
    case 'TW':
      OutputLanguage = 'LOCAL';
    case 'KR':
      if (Address1.includes(Locality)) Locality = '';
      if (Address1.includes(AdministrativeArea)) AdministrativeArea = '';
      OutputLanguage = 'LOCAL';
      break;
  }
  return {
    Address1,
    Address2,
    Address3,
    Address4,
    Address5,
    Locality,
    PostalCode,
    Country,
    OutputLanguage,
    AdministrativeArea,
  };
}
// all validations and checks on the SO returned object to validate
function validateReturnedAddress(address) {
  const { Status, ResolutionLevel } = address;
  const validStatuses = ['Valid'];
  const validResolutionLevel = ['DPV', 'Premise'];
  const isValid =
    validStatuses.includes(Status) &&
    validResolutionLevel.includes(ResolutionLevel);
  if (!isValid)
    console.debug(
      `Status - '${Status}' - not in range '${JSON.stringify(
        validStatuses,
      )}'\nResolutionLevel - '${ResolutionLevel}' - not in range '${JSON.stringify(
        validResolutionLevel,
      )}'`,
    );
  return isValid;
}

function superLog(add1, add2) {
  console.groupCollapsed('Service Objects - Super Log. Open for details')
  console.log('Data-Sent:');
  console.log({ ...add1 });
  console.log('Address is Valid: ' + validateReturnedAddress(add2));
  console.log('Data-Recieved:');
  console.log({ ...add2 });
  console.groupEnd()
}
