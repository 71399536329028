import { useEffect, useReducer } from 'react';

export default function useValidationConsolidator(
  arrayOfFields = [],
  validationCallback = () => true,

) {
  const defaultState = arrayOfFields.reduce((acc, field) => {
    return { ...acc, [field]: false };
  }, {});
  const [state, set_state] = useReducer((prev, curr) => {
    return { ...prev, ...curr };
  }, defaultState);

  function validator(value, id) {
    set_state({
      [id]: value,
    });
  }
  const allFieldsValid = arrayOfFields.every((field) => {
    return !!state[field];
  });

  useEffect(() => {
    console.debug(
      `Section ${allFieldsValid ? '' : 'not-'}validated for ${JSON.stringify(
        arrayOfFields,
      )}`,
    );
    validationCallback(allFieldsValid);
  }, [allFieldsValid]);

  return arrayOfFields.map((field) => {
    return (value) => {
      return validator(value, field);
    };
  });
}
