import ShadowCard from 'components/common/cards/ShadowCard';
import Price from 'components/common/Price';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import ReviewOrderModal from './ReviewOrderModal';
import useModal from 'hooks/useModal';
import { Typography, Stack, Link, Box, Badge, Divider } from '@newulife/common';
import formatImageSrc from 'helpers/formatImageSrc'
import { useEffect } from 'react';
import { getShopifyDisplayProducts } from 'helpers/shopifyHelpers';

export default function ReviewOrder({ data }) {
  const { pageData, modalData } = data;
  const { state, getTotalProductPrice } = useProducts();
  const { state: subState, getTotalSubscriptionPrice } = useSubscriptions();
  const { Modal, openModal } = useModal();

  const {
    text1: orderSummary,
    text2: todaysOrder,
    text3: subscriptionOrder,
    text4: reviewOrder,
  } = pageData?.fields;

  const productTotal = getTotalProductPrice();
  const subscriptionTotal = getTotalSubscriptionPrice();
  const shopifyDisplay = getShopifyDisplayProducts(state?.products || [], subState?.subscriptions || []);
  const allProducts = (state?.starterKit ? [state?.starterKit] : []).concat(shopifyDisplay || []);

  if (data.isShopifyUser) {
    if (!allProducts.length) return null;
    return (
      <ShadowCard 
        title={orderSummary}
      >
        <Stack
          pt={2}
          display="grid"
          gap={2}
          maxWidth="600px"
          mx="auto"
        >
          <Stack
            display="grid"
            gap={2}
          >
            {allProducts?.map(o => (
              <Stack 
                key={o.ProductID}
                display="flex"
                direction="row"
                justifyContent="space-between"
                gap={2}
                width="100%"
                alignItems="center"
              >
                <Stack
                  display="inline-grid"
                  justifyItems="flex-start"
                  alignItems="center"
                  gridTemplateColumns={{
                    sm: 'auto 1fr',
                  }}
                  gap={2}
                >
                  <Badge 
                    badgeContent={o.Quantity}
                    sx={{
                      '.MuiBadge-badge': {
                        background: 'rgb(125 125 125)',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: '8px',
                        overflow: 'hidden',
                        border: '1px solid #e0e0e0',
                        'img': {
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        },
                      }}
                    >
                      <img src={formatImageSrc(o.SmallImageURL)} alt="product image" />
                    </Box>
                  </Badge>
                  <Stack
                    gap={.5}
                  >
                    <Typography
                      variant="body1"
                    >
                      {o.DisplayName}
                    </Typography>
                    <Typography
                      variant="label1"
                      sx={{
                        opacity: .7,
                      }}
                    >
                      {!o.isAutoship || o.IsStarterKit ? 'One Time' : 'Subscribe & Save'}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  display="inline-grid"
                  gridTemplateColumns={{
                    sm: 'repeat(2, 80px)',
                  }}
                  textAlign="right"
                  gap={2}
                >
                  {!!o.WholesalePrice ? (
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      sx={{
                        opacity: .7,
                        textDecoration: 'line-through',
                      }}
                    >
                      {o.CurrencySymbol}{(o.RetailPrice * o.Quantity)?.toFixed(2)}
                    </Typography>
                  ) : <div />}
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                  >
                    {o.CurrencySymbol}{((o.WholesalePrice || o.Price) * o.Quantity)?.toFixed(2)}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Divider />
          <Stack
            gap={.5}
          >
            {!!+productTotal && (
              <ReviewLine title={todaysOrder} total={productTotal} />
            )}
            {subState.subscriptions.length !== 0 && (
              <ReviewLine title={subscriptionOrder} total={subscriptionTotal} />
            )}
          </Stack>
          {/* <Stack
            gap={2}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Typography
                variant="body2"
              >
                Today's Order Total
              </Typography>
              <Typography
                variant="body2"
                fontWeight="bold"
              >
                {products?.[0]?.CurrencySymbol}{products?.reduce((acc, o) => acc + o.WholesalePrice, 0).toFixed(2)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <Typography
                variant="body2"
              >
                Subscription Order
              </Typography>
              <Typography
                variant="body2"
                fontWeight="bold"
              >
                {products?.[0]?.CurrencySymbol}{products?.reduce((acc, o) => acc + o.WholesalePrice, 0).toFixed(2)}
              </Typography>
            </Stack>
          </Stack> */}
        </Stack>
      </ShadowCard>
    )
  }

  return (
    <>
      <ShadowCard title={orderSummary}>
        <Stack
          className="form-max"
          gap={.5}
        >
          <ReviewLine title={todaysOrder} total={productTotal} />
          {subState.subscriptions.length !== 0 && (
            <ReviewLine title={subscriptionOrder} total={subscriptionTotal} />
          )}
          <Link
            datatestid="reviewOrder"
            sx={{
              cursor: 'pointer',
              textAlign: 'center',
              py: 1,
            }}
            underline="hover"
            onClick={() => openModal(true)}
          >
            {reviewOrder}
          </Link>
        </Stack>
      </ShadowCard>
      <Modal buttonText={modalData.fields.buttonText}>
        <ReviewOrderModal data={modalData} />
      </Modal>
    </>
  );
}

const ReviewLine = ({ title, total }) => {
  return (
    <Stack
      display="grid"
      gridAutoFlow="column"
      alignItems="center"
      alignContent="center"
      justifyContent="space-between"
    >
      <Typography
        variant="subhead2"
      >
        {title}
      </Typography>
      <Typography
        data-testid={`${title?.replace(/\W/g, '')}-orderSummary`}
        variant="header2"
      >
        <strong>
          <Price value={total} />
        </strong>
      </Typography>
    </Stack>
  );
};
