import { useEffect, useState } from 'react';
import { getEntryByLocale } from 'api/contentful';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function useContentfulApi(entryId, pushToWindow = false) {
  const { locale } = useRecoilValue(formState);
  const [data, setData] = useState();

  useEffect(() => {
    if (entryId) {
      getEntryByLocale(entryId, locale).then((res) => {
        const { error } = res;
        !error && setData(res);
        if (pushToWindow) {
          window.contentfulData = window.contentfulData || {};
          window.contentfulData[entryId] = res;          
        }
      });
    }
  }, [locale, entryId]);

  return data;
}
