import React from 'react';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: 'Server Error',
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service - after render
    const { message = 'MISC.ERROR' } = error;
    this.setState({ ...this.state, errorMessage: message });
    console.error({ error, errorInfo });
  }

  render() {
    // You can render any custom fallback UI
    if (this.state.hasError) {
      switch (this.props.type) {
        case 'component':
          return <div>{this.state.errorMessage}</div>;
        default:
          return <ErrorPage errorMessage={this.state.errorMessage} />;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
