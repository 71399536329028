import Price from '../Price';
import Volume from '../Volume';
import {
  Stack,
  Typography,
  Paper,
  Box,
  Divider,
  NumberInput,
  Switch,
} from '@newulife/common'
import { FormControlLabel } from '@mui/material'
import formatImageSrc from 'helpers/formatImageSrc'

export default function ProductCard({
  subscribeText,
  initialOrderText,
  imgSrc,
  imgAlt,
  title,
  cv,
  qv,
  price,
  qty,
  maxQty,
  decrement,
  increment,
  disableSubscribe,
  isSubscribed,
  isAddedToInitialOrder,
  toggleSubscription,
  toggleAdditionToInitialOrder,
}) {
  return (
    <Paper
      elevationVariant="card"
      active={qty > 0}
      data-testid={`${title?.replace(/\W/g, '')}-card`}
      sx={{
        display: 'inline-grid',
        width: '100%',
        height: '100%',
      }}
      className="product-card"
    >
      <Stack
        display="grid"
        p={{
          xs: 1.5,
          md: 2,
        }}
        pb={0}
        height="100%"
        width="100%"
        maxWidth="340px"
        justifySelf="center"
      >
        <Stack
          display="grid"
          gap={{
            md: 3,
          }}
          gridTemplateColumns={{
            md: 'auto 1fr',
          }}
          justifyItems={{
            xs: 'center',
            md: 'initial',
          }}
          height="100%"
          mb={2}
        >
          <Box
            width="120px"
            height="120px"
            sx={{
              'img': {
                width: '100%',
                height: 'auto',
              },
            }}
          >
            <img src={formatImageSrc(imgSrc)} alt={imgAlt} />
          </Box>
          <Stack
            gap={1}
          >
            <Typography
              variant="body2"
              textAlign="center"
            >
              {title}
            </Typography>
            <Divider
              light
            />
            {cv && qv && (
              <>
                <Stack
                  color="#747679"
                  gap={.5}
                >
                  <Typography
                    variant="subbody2"
                  >
                    <Volume type="cv" value={cv} />
                  </Typography>
                  <Typography
                    variant="subbody2"
                  >
                    <Volume type="qv" value={qv} />
                  </Typography>
                </Stack>
                <Divider
                  light
                />
              </>
            )}
            <Typography
              data-testid={`${title?.replace(/\W/g, '')}-price`}
              variant="header2"
              color="neutralCool.dark"
              sx={{
                fontWeight: 500,
              }}
            >
              <Price value={price} />
            </Typography>
          </Stack>
        </Stack>
        <Divider
          light
          sx={{
            mb: 2,
          }}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
        >
          <NumberInput
            data-testid={`${title?.replace(/\W/g, '')}-footer`}
            min={0}
            max={maxQty}
            value={qty}
            onChange={data => {
              data > qty ? increment() : decrement()
            }}
            IconButtonProps={{
              sx: { // app.min.css breaks the button states
                '&:active, &:focus, &:hover': {
                  backgroundColor: '#fff',
                  color: '#7ab800',
                },
              },
            }}
          />
          <FormControlLabel
            disabled={disableSubscribe}
            control={
              <Switch
                checked={isSubscribed}
                onChange={toggleSubscription}
              />
            }
            label={
              <Typography
                variant="subbody2"
                color="neutralCool.dark"
              >
                {subscribeText}
              </Typography>
            }
            sx={{
              ':has(.Mui-disabled)': {
                opacity: .4,
              },
            }}
          />
        </Stack>
        {isSubscribed && !disableSubscribe && (
          <Box
            mt={2}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={isAddedToInitialOrder}
                  onChange={toggleAdditionToInitialOrder}
                />
              }
              label={
                <Typography
                  variant="subbody2"
                  color="neutralCool.dark"
                >
                  {initialOrderText}
                </Typography>
              }
              sx={{
                ':has(.Mui-disabled)': {
                  opacity: .4,
                },
              }}
            />
          </Box>
        )}
      </Stack>
    </Paper>
  );
}
