/**
 * @param {boolean} active whether the loader is active or not.
 * @returns an animated loader that is used when a user must wait when there is a system action blocking the loading of content.
 */

import { useEffect, useState } from 'react';
import Rive from '@rive-app/react-canvas';
import animation from 'assets/new-u-life-loading.riv';
import { Box, Typography, Stack, LinearProgress, Alert, Grid } from '@newulife/common';

export default function AnimatedLoader({ active = false, data = {} }) {
  const {
    text1: header,
    text2: subtext1,
    text3: subtext2,
    text4: subtextWarning
  } = data?.fields;

  const [subtext, setSubtext] = useState(subtext1);
  const [error, setError] = useState(false);
  useEffect(() => {
    const timer1 = setTimeout(() => setSubtext(subtext2), 6000);
    const timer2 = setTimeout(() => {
      setError(true)
      setSubtext('')
  }, 20000);
    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    }
  }, [subtext2, subtextWarning])
  
  if (!active) {
    return null;
  }

  return (
    <Stack 
      justifyContent="center"
      alignItems="center"
      sx={{ 
        position: 'fixed',
        'z-index': 200,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100vh',
        width: '100%',
        overflowY: 'auto',
        background: 'rgba(255, 255, 255, 0.90)',
        backdropFilter: 'blur(3px)',
        padding: '20px',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{ maxWidth: '475px' }}
      >
        <Typography align="center" variant="header1">{ header }</Typography>
        {error ? (
            <Alert
              message="This is an alert message"
              severity="error"
              variant="standard"
            >
              <Grid gap={0.5} direction="column">
                <Typography align="center" variant="body2">
                  {subtextWarning}
                </Typography>
              </Grid>
            </Alert>
          ) : (
            <>
              <Box sx={{ width: '150px', height: '150px' }}>
                <Rive src={animation} />
              </Box>
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
              <Typography align="center" variant="body1">{subtext}</Typography>
            </>
          )
       }
      </Stack>
    </Stack>
  );
}
