import { createContext, useContext, useEffect, useState } from 'react';
import defaultAccountContext from 'constants/defaultAccountContext';

const AccountContext = createContext();

export default AccountContext;

function AccountContextProvider({ children }) {
  const persistedAccount = JSON.parse(localStorage.getItem('NUL_ACCOUNT'))
  const [account, setAccount] = useState(persistedAccount || defaultAccountContext);
  
  useEffect(() => {
    localStorage.setItem('NUL_ACCOUNT', JSON.stringify(account))
  }, [account])

  function setAccountAddress(addressType, type, address) {
    const _account = { ...account };
    _account.addresses = account?.addresses || {};
    _account.addresses[addressType] = account?.addresses?.[addressType] || {};
    _account.addresses[addressType][type] = address;
    setAccount(_account);
  }

  function storeValidatedAddressValue(addressType, address) {
    return setAccountAddress(addressType, 'serviceobjects', address);
  }

  function storeOriginalAddressValue(addressType, address) {
    return setAccountAddress(addressType, 'original', address);
  }

  function storeUserChoiceValue(addressType, value) {
    return setAccountAddress(addressType, 'selection', value);
  }

  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
        storeValidatedAddressValue,
        storeOriginalAddressValue,
        storeUserChoiceValue,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

function useAccount() {
  const context = useContext(AccountContext);

  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountContextProvider');
  }

  return context;
}

export { AccountContextProvider, useAccount };
