import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { init as initApm } from '@elastic/apm-rum';
import Confirmation from 'pages/steps/confirmation';
import Pay from 'pages/steps/pay';
import Review from 'pages/steps/review';
import CreateAccount from 'pages/steps/create-account';
import CommissionsSetup from 'pages/steps/commissions-setup';
import SelectProduct from 'pages/steps/starter-kit/select-product';
import SelectKit from 'pages/steps/starter-kit/select-kit';
import NiceCallback from 'pages/callback/nice';
import Home from 'pages/index';
import SubscribePage from 'pages/subscribe';
import ErrorBoundary from 'components/common/errors/ErrorBoundary';
import config from 'config';
import { useEnvironment } from 'contexts/EnvironmentContext';
import DevTools from 'components/tools/DevTools';
// import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
// import { formState } from 'state';


const apm = initApm({
  active: config.ELASTIC_APM_ENABLED,
  environment: config.APP_ENV,
  logLevel: config.ELASTIC_APM_LEVEL || "debug",
  serverUrl: config.ELASTIC_URI,
  serviceName: require("../package.json").name,
  // NOTE: The use of `process.env` here is intentional to set this at build-time.
  serviceVersion: process.env.REACT_APP_VERSION || "0.0.0",
});


export default function App() {
  const isDevEnvironment = useEnvironment()
  // TODO: Reconsider confirmation routes when we are no longer redirecting to ByDesign payment page.
  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <ApmRoute path="/steps/confirmation" component={Confirmation} />
          <ApmRoute path="/steps/pay" component={Pay} />
          <ApmRoute path="/steps/review" component={Review} />
          <ApmRoute path="/steps/create-account" component={CreateAccount} />
          <ApmRoute path="/steps/commissions-setup" component={CommissionsSetup} />
          <ApmRoute path="/steps/starter-kit/select-product" component={SelectProduct} />
          <ApmRoute path="/steps/starter-kit/select-kit" component={SelectKit} />
          <ApmRoute path="/nice-response" component={NiceCallback} />
          <ApmRoute path="/subscribe" component={SubscribePage} />
          <ApmRoute exact path="/" component={Home} />
          <ApmRoute component={() => (
            <Redirect from="/" to="/" />
          )} />
        </Switch>
        {isDevEnvironment && <DevTools />}
      </Router>
    </ErrorBoundary>
  );
}


// const PrivateRoute = ({ component: Component, ...rest }) => {

//   // Add your own authentication on the below line.
//   const {state, content} = useRecoilValueLoadable(formState)
//   if (state === 'loading') return 

//   return (
//     <ApmRoute
//       {...rest}
//       render={props => form ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to={{ pathname: '/', state: { from: props.location } }} />
//         )
//       }
//     />
//   )
// }
