import { post } from './request';

const defaultError = (
  message = 'Unknown Error',
  { cause, lineNumber, fileName = '/src/api/validation.js' } = {},
) => {
  const newError = Error(message, { cause }, fileName, lineNumber);
  console.debug(newError);
  return newError;
};
export function chinaIdValidation(values) {
  const { GOVERNMENT_NAME: governmentIDName, GOVERNMENT_ID: governmentID } =
    values;
  return post('/api/validateChineseGovId', {
    governmentIDName,
    governmentID,
  }).then(({ IsSuccessful }) => {
    if (!IsSuccessful)
      throw defaultError('Unable to validate China Government Id');
    else return;
  });
}

export const validateBankInfo = async (accountInfo) => {
  const {
    DOB: birthdate,
    IDENTITY: residentRegistrationNumber,
    NAME_ON_BANK: userName,
    NAME_BANK: bankname,
    BANK_ACCOUNT: bankAccountNumber,
    BANK_ACCOUNT_TYPE: bankAccountType,
  } = accountInfo;

  const bankInfo = {
    birthdate,
    residentRegistrationNumber,
    userName,
    bankname,
    bankAccountNumber,
    bankAccountType
  };

  const { responseMessage } = await post('/api/playNice', bankInfo)
  const parsedResponse = parseData(responseMessage);
  if (!responseMessage || !parsedResponse || parsedResponse?.code !== '0000') {
    return { response: false, code: parsedResponse.code };
  } else {
    return { response: true, code: parsedResponse.code };
  }
}

function parseData(data) {
  if (!data) return;
  const array = data.split('|').filter((el) => {
    return el !== '\n';
  });
  const details = array.pop();
  const code = array.pop();
  const orderId = array.pop();
  return { details, orderId, code };
}
