function XIcon() {
  return (
      <span className="valid-status error display-status data-testid='error'">
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(45)">
              <line y1="5" x2="10" y2="5" />
              <line x1="5" y1="-1.19249e-08" x2="5" y2="10" />
          </svg>
      </span>
  )
}
export default XIcon;
