const CA = {
  market: 'CA',
  enrollment: {
    form: {
      sections: {
        shipping: {
          fields: ['ADDRESS1', 'ADDRESS2', 'ZIP', 'CITY', 'STATE'],
        },
      },
    },
  },
  inputs: {
    STATE: {
      labelRef: 'PROVINCE',
    },
    ZIP: {
      labelRef: 'POSTAL_CODE',
    },
  },
};
export default CA;
