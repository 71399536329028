import { Box } from '@newulife/common'

const Page = ({
  sx = {},
  ...props
}) =>
  <Box
    {...props}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: {
        xs: '100%',
        md: '90%',
      },
      maxWidth: {
        md: 800,
      },
      backgroundColor: {
        xs: '#fafafa',
        md: '#fff',
      },
      m: {
        xs: '0 auto',
        md: 4,
        lg: 6,
      },
      borderRadius: {
        md: '17px',
      },
      boxShadow: {
        md: '0 0 25px rgba(0, 0, 0, .2)',
      },
      pb: {
        xs: 4,
        md: 2,
      },

      ...sx
    }}
  />

export default Page