import XIcon from 'components/common/icons/x';
import { Button, Typography } from '@newulife/common'
import useLockBodyScroll from 'hooks/useLockBodyScroll'

export default function SaveModal({
  children,
  loading,
  onClose,
  onSave,
  title,
  subtitle,
  datatestid = 'save',
  saveText = 'Save',
}) {
  const actionModalClasses = loading
    ? 'modal-content button-loading'
    : 'modal-content';

  const buttonClasses = loading ? 'button button-loading' : 'button';

  useLockBodyScroll()
  
  return (
    <div className="modal--wrapper confirmation-modal fade-in">
      <div className="modal">
        <button
          disabled={loading}
          onClick={onClose}
          className="modal-close"
          data-testid="close"
        >
          <XIcon close={onClose} />
        </button>
        <section className="modal-title">
          {title && (
            <Typography
              as="h2"
              variant="header1"
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              as="p"
              variant="body3"
            >
              {subtitle}
            </Typography>
          )}
        </section>
        <section className={actionModalClasses}>{children}</section>
        <div className="modal-footer">
          <Button
            onClick={onSave}
            className={buttonClasses}
            data-testid={datatestid}
          >
            <span className="button-text">{saveText}</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
