import { useState, useEffect } from 'react';

import { useAccount } from '../contexts/AccountContext';

const useInput = (id) => {
  const { account, setAccount } = useAccount();
  const defaultValue = account?.[id] || '';
  const [value, setValue] = useState(defaultValue);

  function handleChange(e) {
    const { value } = e.target;
    setValue(value);
  }
  useEffect(() => {
    setAccount({ ...account, [id]: value });
  }, [value]);

  return {
    handleChange,
    value,
  };
};

export default useInput;
