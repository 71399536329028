import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentfulApi from 'hooks/useContentfulApi';
import Address from 'components/common/Address';
import Modal from './Modal';
import { useModal } from 'contexts/ModalContext';
import CONTENTFUL from 'constants/contentful'

export default function InvalidAddressModal(props) {
  const { close } = useModal();

  const {
    callback,
    addressType,
    data: { addresses: { current } = {} } = {},
  } = props;
  const data = useContentfulApi(CONTENTFUL.invalid_address_modal);
  if (!data) {
    return null;
  }

  const {
    title,
    item1: shippingText,
    item2: billingText,
    item3: editAddress,
    item4: useAddress,
    richText,
  } = data.fields;

  function handleClick(string) {
    close();
    callback({ status: string });
  }

  return (
    <Modal id="invalid-address-modal" cancelType={false}>
      <section className="modal-title">
        <h2>{title}</h2>
      </section>
      <section className="modal-content">
        {documentToReactComponents(richText)}
        {addressType === 'shipping' && (
          <p className="text-center mb-40">{shippingText}</p>
        )}
        {addressType === 'account' && (
          <p className="text-center mb-40">{billingText}</p>
        )}
        {/* <h3 className="light text-center">{item3}</h3> */}
        <div className="center">
          <Address address={current} />
        </div>
      </section>
      <div className="bottom-section center">
        <button
          data-testid={`useAddress_btn`}
          className="button mb-10"
          onClick={() => handleClick('current')}
        >
          {useAddress}
        </button>
        <button
          data-testid={`editAddress_btn`}
          className="button secondary"
          onClick={() => handleClick('cancel')}
        >
          {editAddress}
        </button>
      </div>
    </Modal>
  );
}
