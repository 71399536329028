import { createContext, useContext, useEffect, useReducer } from 'react';

const SubscriptionContext = createContext();
const persistedSubscriptionCart = JSON.parse(
  localStorage.getItem('NUL_SUBSCRIPTION_CART'),
);
const initialState = persistedSubscriptionCart || { subscriptions: [] };

const reducer = (state, action) => {
  switch (action.type) {
    case 'clearCart': {
      localStorage.removeItem('NUL_SUBSCRIPTION_CART');
      return { subscriptions: [] };
    }
    case 'addSubscriptionToCart': {
      return {
        ...state,
        subscriptions: [
          ...state.subscriptions,
          {
            ...action.subscription,
            Quantity: action.quantity,
            isAutoship: true,
          },
        ],
      };
    }
    case 'decrementSubscription': {
      return {
        ...state,
        subscriptions: state.subscriptions.map((subscription) =>
          subscription.ProductID === action.id
            ? { ...subscription, Quantity: subscription.Quantity - 1 }
            : subscription,
        ),
      };
    }
    case 'incrementSubscription': {
      return {
        ...state,
        subscriptions: state.subscriptions.map((subscription) =>
          subscription.ProductID === action.id
            ? { ...subscription, Quantity: subscription.Quantity + 1 }
            : subscription,
        ),
      };
    }
    case 'removeSubscriptionFromCart': {
      return {
        ...state,
        subscriptions: state.subscriptions.filter(
          (subscription) => subscription.ProductID !== action.id,
        ),
      };
    }
    case 'replaceSubscriptions': {
      return { ...state, subscriptions: [...action.subscriptions] };
    }
    default: {
      throw new Error(
        `Unhandled action type in SubscriptionContext: ${action.type}`,
      );
    }
  }
};

const SubscriptionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem('NUL_SUBSCRIPTION_CART', JSON.stringify(state));
  }, [state]);

  // Validators
  const hasSubscriptionInCart = (ProductID) => {
    return state.subscriptions.find(
      (subscription) => subscription.ProductID === ProductID,
    );
  };

  // Getters
  const getTotalSubscriptionPrice = () => {
    return state.subscriptions.reduce(
      (accumulator, currentValue) =>
        accumulator +
        currentValue.Quantity *
          (currentValue.WholesalePrice ? currentValue.WholesalePrice : 0),
      0,
    );
  };

  // Volume is CV.
  const getTotalSubscriptionCV = () => {
    return state.subscriptions.reduce(
      (accumulator, currentValue) =>
        accumulator +
        currentValue.Quantity * (currentValue.Volume ? currentValue.Volume : 0),
      0,
    );
  };

  // Volume2 is QV.
  const getTotalSubscriptionQV = () => {
    return state.subscriptions.reduce(
      (accumulator, currentValue) =>
        accumulator +
        currentValue.Quantity *
          (currentValue.Volume2 ? currentValue.Volume2 : 0),
      0,
    );
  };

  // Parsers
  const parseSubscriptionsForOrder = ({ isUpdate = false }) => {
    return state.subscriptions.map((subscription) => ({
      productId: subscription.ProductID,
      quantity: subscription.Quantity,
      cv: subscription.Volume,
      qv: subscription.Volume2,
      price: subscription.Price,
      isAutoship: subscription.isAutoship,
      isUpdate: isUpdate,
    }));
  };

  return (
    <SubscriptionContext.Provider
      value={{
        state,
        dispatch,
        hasSubscriptionInCart,
        getTotalSubscriptionPrice,
        getTotalSubscriptionCV,
        getTotalSubscriptionQV,
        parseSubscriptionsForOrder,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

function useSubscriptions() {
  const context = useContext(SubscriptionContext);

  if (context === undefined) {
    throw new Error(
      'useSubscriptions must be used within a SubscriptionContextProvider',
    );
  }

  return context;
}

export { SubscriptionContextProvider, useSubscriptions };
