export function mergeProductsAndFetchedData(shopifyProducts, fetchedData) {
    const autoshipProducts = [];
    const oneTimeProducts = [];

    shopifyProducts.map(product => {
        const fetchedProduct = fetchedData.find(fd => fd.ProductID === product.id);
        if (fetchedProduct) {
            const enrichedProduct = {
                ...fetchedProduct,
                Quantity: product.quantity,
                isAutoship: product.autoship,
            };

            if (product.autoship) {
                autoshipProducts.push(enrichedProduct);
            } else {
                oneTimeProducts.push(enrichedProduct);
            }
        }
    });

    // Add autoshipProducts to oneTimeProducts, checking for duplicates
    autoshipProducts.map(autoshipProduct => {
        const index = oneTimeProducts.findIndex(oneTime => oneTime.ProductID === autoshipProduct.ProductID);
        if (index !== -1) {
            // Product exists, increase quantity
            oneTimeProducts[index].Quantity += autoshipProduct.Quantity;
        } else {
            // New product, add to oneTimeProducts
            oneTimeProducts.push({...autoshipProduct, isAutoship: false}); // Ensure isAutoship is set correctly
        }
    });

    return { autoshipProducts, oneTimeProducts };
}

export function getShopifyDisplayProducts(oneTimeProducts, autoshipProducts) {
        // Create deep copies of arrays with objects to work on
        let adjustedOneTimeProducts = oneTimeProducts.map(product => ({ ...product }));

        // Add autoshipProducts to oneTimeProducts, checking for duplicates
        autoshipProducts.map(autoshipProduct => {
            const matchingIndex = adjustedOneTimeProducts.findIndex(oneTime => oneTime.ProductID === autoshipProduct.ProductID);
            if (matchingIndex !== -1) {
                // Product exists
                if (adjustedOneTimeProducts[matchingIndex].Quantity === autoshipProduct.Quantity) {
                    // Remove product from oneTimeProducts
                    adjustedOneTimeProducts.splice(matchingIndex, 1);
                } else {
                    // Decrease quantity
                    adjustedOneTimeProducts[matchingIndex].Quantity -= autoshipProduct.Quantity;
                }
            }
        });

        return [...adjustedOneTimeProducts, ...autoshipProducts];
}