import { isEmpty } from 'lodash';

export const parseFullName = (firstName, lastName) => {
  if (!isEmpty(firstName) && !isEmpty(lastName)) {
    return `${firstName} ${lastName}`;
  } else if (!isEmpty(firstName)) {
    return `${firstName}`;
  } else {
    return '';
  }
};

export function getRepName(obj) {
  if (!obj) return '';
  const { firstName, lastName, FirstName, LastName, Company, RepDID } = obj;
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (FirstName && LastName) return `${FirstName} ${LastName}`;
  if (Company) return Company;
  return RepDID;
}
