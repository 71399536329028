import { useRef, useState } from 'react';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import { Select, MenuItem } from '@newulife/common';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { find } from 'lodash'

/**
 * Creates a form select dropdown.
 * https://corporate-ux.azurewebsites.net/ref/components-form-elements-form#properties
 *
 * @param {boolean} active Whether the select dropdown is currently clicked into.
 * @param {function} setActive Setter function for active state.
 * @param {string} selectId The id of the select button element.
 * @param {string} labelText The label text of the select element.
 * @param {Object[]} options The options shown in the dropdown.
 * @param {string} options[].text - The text of the option.
 * @param {string} value The current option selected.
 * @param {function} onChange The handler function that changes the current selected value.
 */

const getValue = (options, value) => {
  return find(options, { value: value?.toUpperCase?.() }) ? value?.toUpperCase?.() : value
}

export default function FormSelectDropdown({
  active,
  setActive,
  selectId,
  labelText,
  options = [],
  value: defaultValue = '',
  onChange,
}) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setActive(false));
  const [value, setValue] = useState(getValue(options, defaultValue));

  const handleChange = (e) => {
    setValue(getValue(options, e.target.value));
    onChange(getValue(options, e.target.value));
  };

  return (
    <FormControl>
      <InputLabel
        id={`${selectId}-label`}
        color="secondary"
        sx={{
          bgcolor: 'background.paper',
        }}
      >
        {labelText}
      </InputLabel>
      <Select 
        id={selectId}
        labelId={`${selectId}-label`} 
        value={getValue(options, value)} 
        onChange={handleChange}
      >
        {options.map(option => {
          const itemValue = option.value ?? option.text
          return (
            <MenuItem 
              key={itemValue} 
              value={itemValue}
            >
              {option.text}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
}

export function DropDownWrapper(props) {
  const { onChange: onChangeFromProps, id, label, options, value } = props;
  const [active, set_active] = useState(false);

  function onChange(event) {
    set_active(!active);
    onChangeFromProps(event);
  }

  const newProps = {
    active,
    setActive: set_active,
    selectId: id,
    labelText: label,
    options: options.map((option) => {
      return { text: option };
    }),
    value,
    onChange,
  };
  return <FormSelectDropdown {...newProps} />;
}

// import { useRef, useState } from 'react';
// import { isEmpty } from 'lodash';
// import useOutsideAlerter from 'hooks/useOutsideAlerter';

// /**
//  * Creates a form select dropdown.
//  * https://corporate-ux.azurewebsites.net/ref/components-form-elements-form#properties
//  *
//  * @param {boolean} active Whether the select dropdown is currently clicked into.
//  * @param {function} setActive Setter function for active state.
//  * @param {string} selectId The id of the select button element.
//  * @param {string} labelText The label text of the select element.
//  * @param {Object[]} options The options shown in the dropdown.
//  * @param {string} options[].text - The text of the option.
//  * @param {string} value The current option selected.
//  * @param {function} onChange The handler function that changes the current selected value.
//  */
// export default function FormSelectDropdown({
//   active,
//   setActive,
//   selectId,
//   labelText,
//   options = [],
//   value,
//   onChange,
// }) {
//   const wrapperRef = useRef(null);
//   useOutsideAlerter(wrapperRef, () => setActive(false));

//   const selectBoxWrapperStatus = active
//     ? 'active'
//     : !isEmpty(value)
//     ? 'completed'
//     : '';

//   let dropdownDirection = '';

//   if (active) {
//     dropdownDirection = getDirection(selectId);
//   }

//   return (
//     <div className="input-wrapper">
//       <div
//         id={selectId}
//         className={`select-box-wrapper ${selectBoxWrapperStatus} ${dropdownDirection}`}
//         ref={wrapperRef}
//       >
//         <button
//           data-testid={selectId}
//           type="button"
//           id={selectId}
//           className="selector"
//           onClick={() => setActive(!active)}
//           disabled={!options?.length}
//         >
//           <span className="selector-text">{value}</span>
//           <p className="label" htmlFor={selectId} aria-label={selectId}>
//             {labelText}
//           </p>
//           <svg
//             width="14"
//             height="9"
//             viewBox="0 0 14 9"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M0.796875 1L7.04263 7L13.2884 1"
//               stroke="#747679"
//               strokeWidth="2"
//             />
//           </svg>
//         </button>
//         <ul id={`${selectId}-list`}>
//           {options.map((option, index) => (
//             <li
//               className={`${option.text === value ? 'selected' : ''}`}
//               tabIndex="0"
//               key={index}
//               onClick={(e) => onChange(e.target.innerText)}
//             >
//               {option.text}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

// const getDirection = (id) => {
//   try {
//     let direction = '';

//     const selectEl = document.querySelector(`#${id}-list`);
//     const bounding = selectEl.getBoundingClientRect();

//     // TODO: Implement programmatic way of assigning max height to select dropdowns.
//     const maxHeight = 200;

//     if (
//       bounding.bottom + maxHeight >
//       (window.innerHeight || document.documentElement.clientHeight)
//     ) {
//       direction = 'upward';
//     }
//     return direction;
//   } catch {
//     return '';
//   }
// };

// export function DropDownWrapper(props) {
//   const { onChange: onChangeFromProps, id, label, options, value } = props;
//   const [active, set_active] = useState(false);

//   function onChange(event) {
//     set_active(!active);
//     onChangeFromProps(event);
//   }

//   const newProps = {
//     active,
//     setActive: set_active,
//     selectId: id,
//     labelText: label,
//     options: options.map((option) => {
//       return { text: option };
//     }),
//     value,
//     onChange,
//   };
//   return <FormSelectDropdown {...newProps} />;
// }
