import { post } from './request';

const subscription = {
  create: (data) => {
    return post('/api/createOrderItem', {
      ...data,
      isUpdate: false,
    }).then((subscriptionInfo) => subscriptionInfo?.[0].id);
  },
  update: async (data) => {
    return post('/api/createOrderItem', {
      ...data,
      isUpdate: true,
    }).then((subscriptionInfo) => subscriptionInfo?.[0]?.id);
  },
  addAll: async (subscriptionsArray, orderId, country) => {
    // if subscriptionArray is undefined or has no length there are no subscriptions so return true
    if (!subscriptionsArray?.length) return true;
    await Promise.all(
      subscriptionsArray.forEach((item) => {
        subscription.create({ ...item, orderId, country });
      }),
    );
    await subscription.mock();
    return true;
  },
  updateAll: async (subscriptionsArray, orderId, country) => {
    // if subscriptionArray is undefined or has no length there are no subscriptions so return true
    if (!subscriptionsArray?.length) return true;
    await Promise.all(
      subscriptionsArray.forEach((item) => {
        subscription.update({ ...item, orderId, country });
      }),
    );
    await subscription.mock();
    return true;
  },
  addToAutoship: (orderId) => {
    return post('/api/addItemsToAutoship', {
      orderRow: orderId,
    });
  },
  mock: (data) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(999);
      }, 500);
    });
  },
};
export default subscription;
