import TagManager from 'react-gtm-module';
import { isEmpty } from 'lodash';
import config from 'config';

export const initGoogleTagManager = () => {
  const { REACT_APP_GTM_ID, REACT_APP_GTM_AUTH, REACT_APP_GTM_PREVIEW } = config;
  // Only initialize if env variables are present.
  if (
    !isEmpty(REACT_APP_GTM_ID) &&
    !isEmpty(REACT_APP_GTM_AUTH) &&
    !isEmpty(REACT_APP_GTM_PREVIEW)
  ) {
    // Add all events that we are tracking.
    const tagManagerArgs = {
      gtmId: REACT_APP_GTM_ID,
      auth: REACT_APP_GTM_AUTH,
      preview: REACT_APP_GTM_PREVIEW,
      events: {
        removeFromCart: 'remove_from_cart',
      },
    };

    TagManager.initialize(tagManagerArgs);
  }
};

// Recommended to be called before any data layer push by GTM.
export const clearPreviousData = () => {
  const dataLayer = {
    ecommerce: null,
  };

  TagManager.dataLayer({ dataLayer });
};

export const gtmCaptureRemoveFromCart = ({
  data,
  marketName,
  variant,
  pathname,
}) => {
  const { ProductID, DisplayName } = data;

  const dataLayer = {
    event: 'remove_from_cart',
    ecommerce: {
      items: [
        {
          item_name: DisplayName,
          item_id: ProductID,
          item_category: marketName,
          item_variant: variant,
          item_list_name: pathname,
        },
      ],
    },
  };

  clearPreviousData();
  TagManager.dataLayer({ dataLayer });
};

export const gtmCaptureReplaceCart = ({
  data,
  marketName,
  variant,
  pathname,
}) => {
  const dataLayer = {
    event: 'remove_from_cart',
    ecommerce: {
      items: data.map((item) => ({
        item_name: item.DisplayName,
        item_id: item.ProductID,
        item_category: marketName,
        item_variant: variant,
        item_list_name: pathname,
      })),
    },
  };

  clearPreviousData();
  TagManager.dataLayer({ dataLayer });
};

export const gtmCapturePurchase = ({ data, currencyTypeCode }) => {
  const { orderInfo, orderItemArray } = data;

  const dataLayer = {
    event: 'purchase',
    ecommerce: {
      transaction_id: orderInfo?.orderId,
      value: orderInfo?.total,
      tax: orderInfo?.tax,
      shipping: orderInfo?.shipping,
      currency: currencyTypeCode,
      items: orderItemArray.map((orderItem) => ({
        item_name: orderItem?.DisplayName,
        item_id: orderItem?.productId,
        price: orderItem?.price,
        quantity: orderItem?.quantity,
      })),
    },
  };

  clearPreviousData();
  TagManager.dataLayer({ dataLayer });
};
