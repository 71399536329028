import { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { gtmCaptureRemoveFromCart } from 'api/gtm';
import ProductQtyOnlyCard from 'components/common/cards/ProductQtyOnlyCard';
import { useProducts } from 'contexts/ProductContext';
import { parseMaxQty } from 'helpers/parse';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function ProductQtyOnlyCardContainer({
  subscribeText,
  data,
  centered = false,
}) {
  const { countryInfo: { marketName } } = useRecoilValue(formState);
  const { dispatch, hasProductInCart } = useProducts();
  const [qty, setQty] = useState(0);

  const {
    ProductID,
    SignupOptionalItemQtyOptions,
    SmallImageURL,
    DisplayName,
    Volume,
    Volume2,
    WholesalePrice,
  } = data;
  const maxQty = parseMaxQty(SignupOptionalItemQtyOptions);

  useEffect(() => {
    const result = hasProductInCart(ProductID);

    if (result) {
      setQty(result.Quantity);
    }
  }, []);

  const decrement = () => {
    if (qty == 1) {
      const { pathname } = window.location;

      // Run only on this step.
      if (pathname === '/steps/starter-kit/select-product') {
        // GTM Analytics
        const variant = 'Product';
        gtmCaptureRemoveFromCart({ data, marketName, variant, pathname });
      }

      dispatch({
        type: 'removeProductFromCart',
        id: ProductID,
      });
    } else {
      dispatch({ type: 'decrementProduct', id: ProductID });
    }
    setQty(qty - 1);
  };

  const increment = () => {
    if (qty == 0) {
      // Validate product does not already exist in cart.
      const result = hasProductInCart(ProductID);
      if (!result) {
        dispatch({ type: 'addProductToCart', product: data, quantity: 1 });
      }
    } else {
      dispatch({ type: 'incrementProduct', id: ProductID });
    }
    setQty(qty + 1);
  };

  const validateIncrement = () => {
    if (!isNull(maxQty)) {
      if (qty < maxQty) {
        increment();
      }
    } else {
      increment();
    }
  };

  return (
    <ProductQtyOnlyCard
      subscribeText={subscribeText}
      centered={centered}
      imgSrc={SmallImageURL}
      imgAlt={DisplayName}
      title={DisplayName}
      cv={Volume}
      qv={Volume2}
      price={WholesalePrice}
      qty={qty}
      maxQty={maxQty}
      decrement={decrement}
      increment={validateIncrement}
    />
  );
}
