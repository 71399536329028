import { post } from './request';

const terms = {
  post: (localeId, country) => {
    return post('/api/enrollment/get-legal-terms', {
      area: {
        localeId,
        country
      }
    });
  }
};

export default terms;
