import { useState } from 'react';
import { Button, Typography, Checkbox, Stack } from '@newulife/common';
import { FormControlLabel } from '@mui/material';
import XIcon from 'components/common/icons/x';
import AddressParser from 'components/common/Address';
import useLockBodyScroll from './useLockBodyScroll';

function useModal(incomingData = {}) {
  const [data, setData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function ModalSelector(props) {
    const { type } = props;
    const newProps = { ...props, toggleOpen, data, setData, incomingData };
    if (!isOpen) return null;
    switch (type) {
      case 'address':
        return <Address {...newProps} />;
      case 'confirm':
        return <Confirm />;
      default:
        return <Default {...newProps} />;
    }
  }

  return {
    data,
    openModal: toggleOpen,
    Modal: ModalSelector,
  };
}

export default useModal;

function Default({
  children,
  toggleOpen,
  title,
  subtitle,
  buttonText = 'SAVE',
}) {

  useLockBodyScroll()

  return (
    <div className="modal--wrapper confirmation-modal fade-in">
      <div className="modal">
        <XIcon close={toggleOpen} />
        <section className="modal-title">
          {title && (
            <Typography variant="header1" as="h2">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography as="p" variant="body3">
              {subtitle}
            </Typography>
          )}
        </section>

        <section className="modal-content">{children}</section>

        <div className="modal-footer">
          <Button className="close" onClick={toggleOpen}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}

function Confirm({ children, toggleOpen, setData }) {
  return <div>click yes or no</div>;
}

function Address({ toggleOpen, setData, incomingData }) {
  const defaultSelection = 'corrected';
  const [selection, setSelection] = useState(defaultSelection);
  if (!incomingData) return null;
  const { currentAddress, correctedAddress } = incomingData;

  const { error } = correctedAddress;

  const invalidAddress =
    error || !validateStatus() || !validateResolutionLevel();

  function validateStatus() {
    const { Status } = correctedAddress;
    const validStatuses = ['Valid'];
    // dropdown address returns Id instead of Status
    if (correctedAddress.Id) return true;
    if (validStatuses.includes(Status)) return true;
    console.info(`Address status - ${Status} - is outside acceptable criteria`);
    return false;
  }

  function validateResolutionLevel() {
    const { ResolutionLevel } = correctedAddress;
    const validLevels = ['DPV', 'Premise', 'Subpremise'];
    if (validLevels.includes(ResolutionLevel)) return true;
    console.info(
      `Address Resolution Level - ${ResolutionLevel} - is outside acceptable criteria`,
    );
    return false;
  }
  // happens on address select modal
  function handleSelect(e) {
    const { value } = e.target;
    setSelection(value);
  }
  function closeModal() {
    setData(selection);
    toggleOpen();
  }

  if (invalidAddress) {
    return <InvalidAddressModal {...{ currentAddress, toggleOpen, setData }} />;
  } else {
    return (
      <div className="modal--wrapper confirmation-modal fade-in">
        <div className="modal">
          <XIcon close={closeModal} />
          <section className="modal-title">
            <h2>Confirm Your Address</h2>
            <p className="text-center wide-desktop">
              We have automatically corrected your <b>shipping address</b>.
              Please confirm the address you want to use
            </p>
          </section>
          <section className="modal-content">
            <h3 className="light text-center">You entered</h3>
            <div className="center">
              <FormControlLabel
                sx={{
                  alignItems: 'flex-start',
                }}
                control={
                  <Checkbox
                    id="current-address"
                    name="current-address"
                    aria-label="current-address"
                    onChange={handleSelect}
                    checked={selection === 'current'}
                  />
                }
                label={
                  <Stack pt={0.3}>
                    <label htmlFor="current-address">
                      <AddressParser address={currentAddress} />
                    </label>
                  </Stack>
                }
              />
            </div>
            <h3 className="light text-center">Corrected address</h3>
            <div className="center">
              <FormControlLabel
                sx={{
                  alignItems: 'flex-start',
                }}
                control={
                  <Checkbox
                    id="corrected-address"
                    name="corrected-address"
                    aria-label="corrected-address"
                    onChange={handleSelect}
                    checked={selection === 'corrected'}
                  />
                }
                label={
                  <Stack pt={0.3}>
                    <label htmlFor="corrected-address">
                      <AddressParser address={correctedAddress} />
                    </label>
                  </Stack>
                }
              />
            </div>
          </section>
          <div className="center">
            <button
              onClick={closeModal}
              className="button"
              data-testid="useThisAddress"
            >
              Use This Address
            </button>
          </div>
        </div>
      </div>
    );
  }
  function InvalidAddressModal({ toggleOpen, currentAddress, setData }) {
    return (
      <div className="modal--wrapper confirmation-modal fade-in">
        <div className="modal">
          <XIcon close={toggleOpen} />
          <div className="inner-content">
            <section className="modal-title">
              <h2>We Can't Verify Your Address</h2>
            </section>
            <section className="modal-content">
              <p className="text-center">
                Our system can't verify your address as entered. This could
                result in <strong>failed deliveries</strong>.
              </p>
              <p className="text-center mb-40">
                If you know the address below is correct, please continue.
                Otherwise please update your address.
              </p>
              <h3 className="light text-center">You entered</h3>
              <div className="center">
                <AddressParser address={currentAddress} />
              </div>
            </section>
            <div className="modal-footer">
              <Button
                onClick={(e) => {
                  setData('invalid');
                  setTimeout(() => {
                    toggleOpen();
                  }, 100);
                }}
                className="button secondary"
                data-testid="editAddress"
              >
                Edit Address
              </Button>
              <Button
                onClick={(e) => {
                  setData('override');
                  setTimeout(() => {
                    toggleOpen();
                  }, 100);
                }}
                data-testid="useThisAddress"
              >
                Use This Address
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
