export const thirteenDigitsRegex = /^\d+(-\d+)*$/;
export const tenToThirteenDigitsAndHyphenRegex = /^\d+(-\d+)?$|^\d{10,13}$/;
export const fiveDigitsRegex = /^\d{5}$/;
export const numericRegex = /^[0-9]*$/;
export const koreaDateRegex =
  /^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/;
export const kanjiOnlyRegex =
  /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;
export const EINRegex = /^(\d{2}-?\d{7}|XX-XXXXXXX)$/;
export const SSNRegex = /^(\d{3}-?\d{2}-?\d{4}|XXX-XX-XXXX)$/;
export const threeNonKanjiRegex =
  /^[\u3131-\uD79D0-9\s()-]*([a-z][\u3131-\uD79D0-9\s()-]*){0,3}$/gim;
export const reverseDate =
  /^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/;
export const koreanAddressRegex =
  /^[\u3131-\uD79D0-9\s\-().,;:/]*([a-z][\u3131-\uD79D0-9\s-().,;:/]*){0,3}$/gi;
export const hangulOnlyRegex = /^[\u3131-\uD79D\s/]*$/gi;
export const hangulOnlyThreeCharactersRegex = /^[\u3131-\uD79D\s/]{3}$/gi;
export const hangulAndNumbersAndThreeRomansRegex =
  /^[\u3131-\uD79D0-9\s-()]*([a-z][\u3131-\uD79D0-9\s-()]*){0,3}$/gi;
  export const noHyphenOrSpaceRegex = /^[^\- ]+$/
