import defaultLanguage from 'constants/defaultLanguage';
import config from 'config';

const initialForm = () => {
  const { NODE_ENV, REACT_APP_ENV } = config;

  switch (NODE_ENV) {
    case 'production':
      return productionForm;
    case 'development':
      return productionForm;
    default:
      return productionForm;
  }
};

export default initialForm;

// Production
const productionForm = {
  repDID: null,
  uplineRepDID: '',
  repName: '',
  repPosition: '',
  locale: defaultLanguage.locale,
  businessMarket: {},
  countryInfo: {},
  moneyInfo: {},
  orderId: null,
  userId: null,
  localeId: defaultLanguage.id,
  shipMethodId: null,
  guid: null,
  rep: {},
  authRep: {},
};

// Development
const developmentForm = {
  repDID: 'D0191929',
  uplineRepDID: '',
  repName: 'Joseph Juliano',
  repPosition: '',
  locale: defaultLanguage.locale,
  businessMarket: {
    Iso2: 'US',
    Iso3: 'USA',
    code: 'US',
    englishName: 'United States',
    id: 1,
    listTypeId: null,
    marketName: 'USA',
    name: 'USA',
  },
  moneyInfo: {
    countryName: 'USA',
    currencySymbol: '$',
    currencyTypeCode: 'USD',
  },
  countryInfo: {
    Iso2: 'US',
    Iso3: 'USA',
    code: 'US',
    englishName: 'United States',
    id: 1,
    listTypeId: null,
    marketName: 'USA',
    name: 'USA',
  },
  orderId: null,
  userId: null,
  localeId: defaultLanguage.id,
  shipMethodId: null,
  guid: null,
  rep: {},
  authRep: {},
};
