import { useEffect, useMemo, useState } from 'react';
import Header from 'components/steps/Header';
import useContentfulApi from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import { useRecoilValue } from 'recoil';
import { formState, inputState } from 'state';
import { NextContextProvider } from 'contexts/NextContext';
import Page from 'components/common/layout/Page';
import { Alert, Box, Stack, Typography } from '@newulife/common';
import Footer from 'components/steps/Footer';
import BankAccountForm from 'components/common/form/components/BankAccountForm';
import { getMarketConfigByCountry } from 'helpers/marketConfig';
import { useLoader } from 'contexts/LoaderContext';
import CONTENTFUL from 'constants/contentful'

const errorCodesList = ['B004', 'B104', 'B103', 'D400', 'MOOLAH_OTHER', 'MOOLAH_DUPLICATE_ACCOUNT']

export default function CommissionsSetup() {
  const { hideLoader } = useLoader()
  const loaderData = useContentfulApi(CONTENTFUL.loader_screen);
  const data = useContentfulApi(CONTENTFUL.commissions_setup);
  const {
    businessMarket: { iso2: market } = {},
    locale,
  } = useRecoilValue(formState);
  const [error, setError] = useState(null)

  const { BANK_ACCOUNT_TYPE } = useRecoilValue(inputState)
  const bankAccountType = useMemo(() => BANK_ACCOUNT_TYPE, [error])

  const config = useMemo(() => {
    return getMarketConfigByCountry(market);
  }, [market]);

  if (!data || !loaderData) {
    return null;
  }

  const {
    fields: {
      headerRef,
      pageTitle,
      teaserTitle: subTitle,
      banner3Ref: sectionTitle,
      banner4Ref: validationErrorCodes,
      banner5Ref: { fields: { repeaterList: errorCodes } = {} } = {},
    } = {},
  } = data;

  const renderEmailText = (text) => {
    const email = 'korea@newulife.com'
    const regex = new RegExp(email, 'i')
    const textSplitByEmail = text.split(regex)
    return (
      <Box sx={{
        a: {
          color: '#4E1822',
          textDecoration: 'underline',
        }
      }}>
        {textSplitByEmail[0]} <a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
        {textSplitByEmail[1]}
      </Box>
    )
  }

  const renderAlert = (error) => {
    let codes = [...errorCodes]
    const responseCode = errorCodesList.includes(error.code)
      ? error.code
      : error.type === 'NICE' ? error.type : 'MOOLAH_OTHER'
    if (responseCode === 'D400') {
      codes = errorCodes.map(errorCode => {
        if (errorCode.key === 'D400-title') {
          return {
            key: 'D400-title',
            value: codes.find(code => code.key === 'D400-title').value.replace('_', bankAccountType)
          }
        }
        return errorCode
      })
    }

    return (
      <Alert severity='error' sx={{ width: '100%', marginTop: '40px', marginBottom: '40px' }}>
        <Stack gap={1}>
          <Typography variant="subhead1">
            {codes.find(code => code.key === `${responseCode}-title`)?.value}
          </Typography>
          <Typography variant="body2" color='#4E1822'>
            {codes.find(code => code.key === `${responseCode}-subTitle`)?.value}
          </Typography>
          {error.showFullError && (
            <>
              <Typography my={1.5} variant="body2" color='#4E1822'>
                {renderEmailText(codes.find(code => code.key === `${responseCode}-text`).value)}
              </Typography>
              <Typography variant="caption" color='#4E1822'>
                {codes.find(code => code.key === 'errorCode').value}: {error.code}{`${error.requestId ? `-${error.requestId}` : ''}`}
              </Typography>
            </>
          )}
        </Stack>
      </Alert>
    )
  }

  return (
    <NextContextProvider>
      <Page className="commissions-setup">
        <Header data={headerRef} />
        <MainContent>
          <Typography variant="header1">{pageTitle}</Typography>
          <Typography variant="body2">{subTitle}</Typography>
          {error && renderAlert(error)}
          <BankAccountForm
            config={config}
            title={sectionTitle}
            setError={setError}
            validationErrorCodes={validationErrorCodes}
            loaderData={loaderData}
          />
        </MainContent>
        <Footer data={headerRef} />
      </Page>
    </NextContextProvider>
  );
}
