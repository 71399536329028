import { useEffect, useState, useRef } from 'react';
import Input from 'components/common/inputs/Input';
import { Typography, Alert, Grid, Stack } from '@newulife/common';
import SectionCard from './SectionCard';
import MarketsList from 'constants/markets';
import { post } from 'api/request';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { inputState } from 'state';
import { find } from 'lodash'
import useContainerOnBlurAndFocus from 'hooks/useContainerOnBlurAndFocus'
import { krGovIdValidation } from 'components/common/form/formValidationRules';

function PersonalInfoSection(props) {
  const krValues = useRef(['', ''])
  const inputs = useRecoilValue(inputState);
  const setInputs = useSetRecoilState(inputState);
  const {
    config,
    isValid,
    data: { title, krTitle } = {},
    inputs: dictionary,
  } = props;

  // Get proper value for Tax Validation
  const markets = new MarketsList();
  const market = markets.find(config?.market).country

  const country = config?.market;
  const fields = config?.enrollment?.form?.sections?.personal?.fields;

  const [dobIsValid, set_dobIsValid] = useState(false);
  const [realNameIsValid, set_realNameIsValid] = useState(false);
  const [idIsValid, set_idIsValid] = useState(false);
  const [idIsDuplicate, set_idIsDuplicate] = useState(false);
  const [personalInfoIsValid, set_personalInfoIsValid] = useState(false);
  const [realNameValidating, setRealNameValidating] = useState(false)

  const realNameValidation = async () => {
    setRealNameValidating(true)
    post(`/api/enrollment/ko/realname-validation`, {
      residentId: inputs.RESIDENT_REGISTRATION_NUMBER?.replace?.('-', ''),
      name: inputs.REAL_NAME,
    })
      .then(data => {
        setInputs(prev => ({
          ...prev,
          KR_ID_STATUS: data?.result,
          miscFields: {
            ...prev.miscFields || {},
            IsNameAndIDValidated: data?.result === "Valid"
          }
       }))
        setRealNameValidating(false)
      })
      .catch(() => {
        setInputs({
          ...inputs,
          KR_ID_STATUS: null,
          miscFields: {
            ...inputs.miscFields || {},
            IsNameAndIDValidated: false
          }
        })
        setRealNameValidating(false)
      });
  }

  const krValidation = async () => {
    if (
      idIsValid &&
      krValues.current[0] !== inputs.RESIDENT_REGISTRATION_NUMBER
    ) {
      setRealNameValidating(true)
      const isGovIdValid = await krGovIdValidation[0](inputs.RESIDENT_REGISTRATION_NUMBER, country, market)
      set_idIsDuplicate(!isGovIdValid)
      if (
        isGovIdValid &&
        realNameIsValid
      ) {
        realNameValidation()
      } else {
        setInputs({ ...inputs, KR_ID_STATUS: null })
        setRealNameValidating(false)
      }
    } else if (
      idIsValid &&
      realNameIsValid &&
      (krValues.current[0] !== inputs.RESIDENT_REGISTRATION_NUMBER ||
        krValues.current[1] !== inputs.REAL_NAME)
    ) {
      realNameValidation()
    }
    krValues.current = [inputs.RESIDENT_REGISTRATION_NUMBER, inputs.REAL_NAME]
  }

  const [setRef, isFocused] = useContainerOnBlurAndFocus(krValidation);

  const showAlert =
    ['Invalid', 'Blocked', 'Valid'].includes(inputs?.KR_ID_STATUS) &&
    !realNameValidating &&
    !isFocused &&
    realNameIsValid &&
    idIsValid &&
    !idIsDuplicate

  const forceError =
    idIsDuplicate &&
      idIsValid &&
      krValues.current[0] === inputs.RESIDENT_REGISTRATION_NUMBER ? 'kr-validation-govId' : undefined

  useEffect(() => {
    if (config?.market !== 'KR') {
      isValid(personalInfoIsValid || !fields?.length);
    }
  }, [
    personalInfoIsValid,
    fields,
    config?.market,
  ]);

  useEffect(() => {
    if (config?.market === 'KR') {
      set_personalInfoIsValid(inputs?.KR_ID_STATUS === 'Valid')
      isValid(inputs?.KR_ID_STATUS === 'Valid')
    } else {
      set_personalInfoIsValid(idIsValid && dobIsValid);
    }
  }, [
    idIsValid,
    dobIsValid,
    inputs?.KR_ID_STATUS,
    config?.market,
  ]);

  if (!fields?.length) return null;
  const inputProps = {
    dictionary,
    country,
    market,
  };

  return (
    <SectionCard
      title={country === 'KR' ? krTitle : title}
    >
      {country === 'KR' ? (
        <>
          <Stack
            ref={setRef}
            gap={2}
            tabIndex="0"
          >
            <Input
              id="REAL_NAME"
              {...config.inputs['REAL_NAME']}
              {...inputProps}
              isValid={set_realNameIsValid}
            />
            <Input
              id="RESIDENT_REGISTRATION_NUMBER"
              {...config.inputs['RESIDENT_REGISTRATION_NUMBER']}
              {...inputProps}
              forceError={forceError}
              isValid={set_idIsValid}
            />
          </Stack>
          {realNameValidating && (
            <div className={`loader-ring fadeIn`}>
              <div></div>
            </div>
          )}
          {showAlert && (
            <Alert
              severity={inputs?.KR_ID_STATUS !== "Valid" ? "error" : "success"}
              variant="standard"
            >
              <Grid gap={0.5} direction="column">
                <Typography align="center" variant="body2">
                  {find(dictionary, { id: `kr-real-name-validation-error-${inputs.KR_ID_STATUS}` })?.text}
                </Typography>
              </Grid>
            </Alert>
          )}
        </>
      ) : (
        <>
          <Input
            id="DOB"
            {...config.inputs['DOB']}
            {...inputProps}
            isValid={set_dobIsValid}
          />
          <Input
            id="IDENTITY"
            {...config.inputs['IDENTITY']}
            {...inputProps}
            isValid={set_idIsValid}
          />
        </>
      )}
    </SectionCard>
  );
}
export default PersonalInfoSection;
