import { useHistory, useLocation } from 'react-router-dom';
import { stepOrder } from 'constants/stepOrder';
import { useLoader } from 'contexts/LoaderContext';
import { useNextContextConsumer } from 'contexts/NextContext';
import { getStepOrderByShippingCountry } from 'helpers/stepOrder';
import config from 'config';
import { Button } from '@newulife/common';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function NextButton({ title, optionalTitle }) {
  const {
    repDID,
    repName,
    businessMarket: { iso2: marketIso2 } = {},
    countryInfo,
    localeId,
  } = useRecoilValue(formState);
  const {
    next: { enabled, skip, validate },
    error,
    tip,
    setError,
    setTip,
  } = useNextContextConsumer();

  // Custom Hooks
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const { pathname, search } = useLocation();

  const isLastStep = stepOrder[stepOrder.length - 1].pathname === pathname;

  if (isLastStep) {
    return null;
  }

  const goNext = () => {
    // Get step order by shipping country.
    const stepOrder = getStepOrderByShippingCountry(countryInfo.name);

    // Get index of current step in step order.
    const currentIndex = stepOrder.findIndex(
      (step) => step.pathname === pathname,
    );

    // Get URL of 1 step forward.
    const url = stepOrder[currentIndex + 1].pathname;

    history.push(url + search);
  };

  // Hide loaders exist in every if statement except for redirecting to ByDesign because loader needs to persist into ByDesign page.
  const handleClick = () => {
    if (enabled) {
      showLoader();
      // Validate if they can go forward.
      const valid = validate();
      valid
        .then((res) => {
          // passing an empty object will do nothing unless it matches
          if (typeof res === 'string') {
            // If a string is returned, set a tip. If not validated, set an error.
            setTip({ ...tip, enabled: true, message: res });
          } else if (!res) {
            setError({ ...error, enabled: true });
          } else if (res && marketIso2 === 'JP' && !config.FEATURE_FLAG_JAPAN_ENROLLMENT) {
            // Redirect to japan enrollment.
            if (
              pathname === '/steps/starter-kit/select-kit'
            ) {
              const uriRepName = encodeURIComponent(repName);
              const url = `https://newulife.jetoffice.net/NewULife/servlet/FrontEnd?act=Distributor.enrollNew&market=JPN&enrollerid=${repDID}&enrollername=${uriRepName}`;
              window.location.href = url;
              // Navigate to next step.
            }
          } else {
            if (pathname === '/steps/review') {
              const { guid } = res;
              const params = new URLSearchParams();
              params.set('sessionGuid', guid);
              params.set('localeId', localeId);
              const url = `${
                config.REACT_APP_BYDESIGN_PAYMENT_BASE_URL
              }/Personal/Signup/EnrollNew_Payment_Custom.asp?${params.toString()}`;
              window.location.href = url;
            } else if (
              pathname !== '/steps/create-account' &&
              pathname !== '/steps/commissions-setup'
            )
              goNext();
          }
        })
        .finally(() => {
          if (
            pathname !== '/steps/review' &&
            pathname !== '/steps/commissions-setup'
          )
            hideLoader();
        });
    } else {
      setTip({ ...tip, enabled: true });
    }
  };

  if (skip)
    return <SkipButton title={optionalTitle} handleClick={handleClick} />;
  return (
    <DefaultButton title={title} handleClick={handleClick} enabled={enabled} />
  );
}

const DefaultButton = ({ title, handleClick, enabled }) => {
  return (
    <Button
      className={`button next-button`}
      disabled={!enabled}
      data-testid="next-button"
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};

const SkipButton = ({ title, handleClick }) => {
  return (
    <Button
      className="ghost button next-button"
      data-testid="next-button"
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};
