import { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import useContentfulApi from 'hooks/useContentfulApi';
import { Typography, Stack, Button, Checkbox } from '@newulife/common'
import { FormControlLabel } from '@mui/material'
import Address from 'components/common/Address';
import Modal from './Modal';
import { useModal } from 'contexts/ModalContext';
import CONTENTFUL from 'constants/contentful'

export default function ConfirmAddressModal(props) {
  const { close } = useModal();
  const {
    callback,
    addressType = 'shipping',
    data: { addresses: { current, serviceobjects } = {} } = {},
  } = props;
  // Custom Hooks
  const data = useContentfulApi(CONTENTFUL.confirm_address_modal);

  // State
  const [statusMessage, set_statusMessage] = useState('serviceobjects');

  if (!data) {
    return null;
  }

  const onConfirm = (message) => {
    close();
    callback({ status: message });
  };

  const {
    title,
    item1,
    item2,
    buttonText,
    richText: shippingText,
    richText1: billingText,
  } = data.fields;

  return (
    <Modal id="confirm-address-modal" cancelType={false}>
      <Stack
        as="section"
        display="grid"
        gap={2}
        textAlign="center"
      >
        <Typography
          as="h2"
          variant="header1"
        >
          {title}
        </Typography>
        {!!addressType && (
          <Stack
            maxWidth="440px"
            justifySelf="center"
          >
            {addressType === 'Ship' && documentToReactComponents(shippingText)}
            {addressType === 'Bill' && documentToReactComponents(billingText)}
          </Stack>
        )}
      </Stack>
      <Stack
        as="section"
        className="modal-content"
        gap={2}
      >
        <Stack
          gap={1}
        >
          <Typography
            as="h3"
            variant="subhead2"
            color="neutralCool.main"
            textAlign="center"
          >
            {item1}
          </Typography>
          <div className="center">
            <FormControlLabel
              sx={{
                alignItems: 'flex-start',
              }}
              control={
                <Checkbox
                  id="CurrentAddress"
                  name="CurrentAddress"
                  data-testid="current-address-checkbox"
                  aria-label="CurrentAddress"
                  onChange={() => set_statusMessage('current')}
                  checked={statusMessage === 'current'}
                />
              }
              label={
                <Stack
                  pt={.3}
                >
                  <label htmlFor="CurrentAddress">
                    <Address address={current} />
                  </label>
                </Stack>
              }
            />
          </div>
        </Stack>
        <Stack
          gap={1}
          mb={2}
        >
          <Typography
            as="h3"
            variant="subhead2"
            color="neutralCool.main"
            textAlign="center"
          >
            {item2}
          </Typography>
          <div className="center">
            <FormControlLabel
              sx={{
                alignItems: 'flex-start',
              }}
              control={
                <Checkbox
                  id="SoAddress"
                  name="SoAddress"
                  aria-label="SoAddress"
                  onChange={() => set_statusMessage('serviceobjects')}
                  checked={statusMessage === 'serviceobjects'}
                />
              }
              label={
                <Stack
                  pt={.3}
                >
                  <label htmlFor="SoAddress">
                    <Address address={serviceobjects} />
                  </label>
                </Stack>
              }
            />
          </div>
        </Stack>
      </Stack>
      <Stack
        display="grid"
        justifyItems="center"
        justifyContent="center"
        gap={1}
      >
        <Button
          data-testid="useThisAddress"
          onClick={() => {
            onConfirm(statusMessage);
          }}
        >
          {buttonText}
        </Button>
        <Button
          onClick={() => {
            onConfirm('cancel');
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
}
