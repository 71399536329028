export default function Volume({ type, value }) {
  const parsedValue = value ? value.toFixed(2) : (0).toFixed(2);

  switch (type) {
    case 'cv': {
      return `CV: ${parsedValue}`;
    }
    case 'qv': {
      return `QV: ${parsedValue}`;
    }
    case 'amt': {
      return `${parsedValue}`;
    }
    default:
      throw new Error(`Volume type ${type} is not a valid case.`);
  }
}
