import { createContext, useContext, useEffect, useReducer } from 'react';
import { isNull } from 'lodash';

const ProductContext = createContext();
const persistedProductsCart = JSON.parse(
  localStorage.getItem('NUL_PRODUCTS_CART'),
);
const initialState = persistedProductsCart || {
  starterKit: null,
  products: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'clearCart': {
      localStorage.removeItem('NUL_PRODUCTS_CART');
      return { starterKit: null, products: [] };
    }
    case 'addStarterKit': {
      return {
        ...state,
        starterKit: {
          ...action.product,
          Quantity: action.quantity,
          isAutoship: false,
        },
      };
    }
    case 'addProductToCart': {
      return {
        ...state,
        products: [
          ...state.products,
          { ...action.product, Quantity: action.quantity, isAutoship: false },
        ],
      };
    }
    case 'decrementProduct': {
      return {
        ...state,
        products: state.products.map((product) =>
          product.ProductID === action.id
            ? { ...product, Quantity: product.Quantity - 1 }
            : product,
        ),
      };
    }
    case 'incrementProduct': {
      return {
        ...state,
        products: state.products.map((product) =>
          product.ProductID === action.id
            ? { ...product, Quantity: product.Quantity + 1 }
            : product,
        ),
      };
    }
    case 'removeProductFromCart': {
      return {
        ...state,
        products: state.products.filter(
          (product) => product.ProductID !== action.id,
        ),
      };
    }
    case 'replaceProducts': {
      return { ...state, products: [...action.products] };
    }
    case 'replaceProductsAndKit': {
      return {
        ...state,
        starterKit: action.starterKit,
        products: [...action.products],
      };
    }
    default: {
      throw new Error(
        `Unhandled action type in ProductContext: ${action.type}`,
      );
    }
  }
};

const ProductContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem('NUL_PRODUCTS_CART', JSON.stringify(state));
  }, [state]);

  // Validators
  const hasProductInCart = (ProductID) => {
    return state.products.find((product) => product.ProductID === ProductID);
  };

  // Getters
  const getTotalProductPrice = () => {
    const starterKitPrice =
      !isNull(state.starterKit) && state.starterKit.Price
        ? state.starterKit.Price
        : 0;
    return (
      state.products.reduce(
        (accumulator, currentValue) =>
          accumulator +
          currentValue.Quantity *
            (currentValue.WholesalePrice ? currentValue.WholesalePrice : 0),
        0,
      ) + starterKitPrice
    );
  };

  // Volume is CV.
  const getTotalProductCV = () => {
    const starterKitVolume =
      !isNull(state.starterKit) && state.starterKit.Volume
        ? state.starterKit.Volume
        : 0;
    return (
      state.products.reduce(
        (accumulator, currentValue) =>
          accumulator +
          currentValue.Quantity *
            (currentValue.Volume ? currentValue.Volume : 0),
        0,
      ) + starterKitVolume
    );
  };

  // Volume2 is QV.
  const getTotalProductQV = () => {
    const starterKitVolume2 =
      !isNull(state.starterKit) && state.starterKit.Volume2
        ? state.starterKit.Volume2
        : 0;
    return (
      state.products.reduce(
        (accumulator, currentValue) =>
          accumulator +
          currentValue.Quantity *
            (currentValue.Volume2 ? currentValue.Volume2 : 0),
        0,
      ) + starterKitVolume2
    );
  };

  // Parsers
  const parseProductsForOrder = ({ isUpdate = false }) => {
    const { starterKit, products } = state;
    const order = !isNull(starterKit) ? [starterKit, ...products] : products;

    return order.map((product) => ({
      productId: product.ProductID,
      quantity: product.Quantity,
      cv: product.Volume,
      qv: product.Volume2,
      price: product.Price,
      isAutoship: product.isAutoship,
      isUpdate: isUpdate,
    }));
  };

  return (
    <ProductContext.Provider
      value={{
        state,
        dispatch,
        hasProductInCart,
        getTotalProductPrice,
        getTotalProductCV,
        parseProductsForOrder,
        getTotalProductQV,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

function useProducts() {
  const context = useContext(ProductContext);

  if (context === undefined) {
    throw new Error('useProducts must be used within a ProductContextProvider');
  }

  return context;
}

export { ProductContextProvider, useProducts };
