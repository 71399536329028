
export const stepOrder = [
  {
    order: 0,
    pathname: '/',
  },
  {
    order: 1,
    pathname: '/steps/starter-kit/select-kit',
  },
  {
    order: 1,
    pathname: '/steps/starter-kit/select-product',
  },
  {
    order: 2,
    pathname: '/steps/create-account',
  },
  {
    order: 3,
    pathname: '/steps/review',
  },
  {
    order: 4,
    pathname: '/steps/pay',
  },
  {
    order: 5,
    pathname: '/steps/confirmation',
  },
];

// Non-North American step order without products.
export const stepOrderDefault = [
  {
    order: 0,
    pathname: '/',
  },
  {
    order: 1,
    pathname: '/steps/starter-kit/select-kit',
  },
  {
    order: 2,
    pathname: '/steps/create-account',
  },
  {
    order: 3,
    pathname: '/steps/review',
  },
  {
    order: 4,
    pathname: '/steps/pay',
  },
  {
    order: 5,
    pathname: '/steps/confirmation',
  },
];

export const stepOrderKorea = [
  {
    order: 0,
    pathname: '/',
  },
  {
    order: 1,
    pathname: '/steps/starter-kit/select-kit',
  },
  {
    order: 2,
    pathname: '/steps/create-account',
  },
  {
    order: 2,
    pathname: '/steps/commissions-setup'
  },
  {
    order: 3,
    pathname: '/steps/review',
  },
  {
    order: 4,
    pathname: '/steps/pay',
  },
  {
    order: 5,
    pathname: '/steps/confirmation',
  },
];
