import { useEffect, useState } from 'react';
import { getContentType } from 'api/contentful';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

function useGetInputs() {
  const { locale } = useRecoilValue(formState);
  const [inputs, set_inputs] = useState([]);
  useEffect(() => {
    getContentType('inputModel', locale).then(({ items }) => {
      set_inputs(
        items.map(
          ({
            fields: {
              descriptiveTitle: id,
              helperText,
              name: text,
              errorText: error,
            } = {},
          }) => ({
            id,
            helperText,
            text,
            error,
          }),
        ),
      );
    });
  }, [locale]);
  return inputs;
}

export default useGetInputs;
