/**
 * Returns an alert notifying the client that something went wrong on our end. (e.g. non-responsive cms or back-end)
 *
 * @param {string} message Message delivered to the client.
 * @param {function} close Function that is responsible for closing the alert.
 */
export default function GlobalBlueAlert({
  message = 'Please complete the following items to continue.',
  close,
}) {
  return (
    <div className="global-alert blue fade-in">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="9" strokeWidth="2" />
        <line x1="10" y1="4" x2="10" y2="12" strokeWidth="2" />
        <line x1="10" y1="14" x2="10" y2="16" strokeWidth="2" />
      </svg>
      <span datatestid="blue-alert" className="alert-message">
        <p>{message}</p>
      </span>
      <button onClick={() => close()}>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 7L1 12.9977L7 7ZM7 7L1 1L7 7ZM7 7L12.9977 1L7 7ZM7 7L13 13L7 7Z"
            stroke="#003B5E"
            strokeWidth="2"
          />
        </svg>
      </button>
    </div>
  );
}
