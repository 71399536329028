import { get } from './request';

export default function getLanguages(marketIso) {
  return get('/api/language').then((languages) => {
    return languages;
    // const availableLocales = {
    //   KR: ['en', 'ko'],
    // };
    // if (!availableLocales[marketIso]) return languages;
    // return languages.filter((language) => {
    //   const { code } = language;
    //   const _lang = code?.split('-')?.[0];
    //   return language;
    // });
  });
}
