import GlobalBlueAlert from 'components/common/alerts/GlobalBlueAlert';
import GlobalOrangeAlert from 'components/common/alerts/GlobalOrangeAlert';
import { useNextContextConsumer } from 'contexts/NextContext';
import { useEffect } from 'react';

export default function Alerts() {
  const { error, tip, setError, setTip } = useNextContextConsumer();
  const defaultTip = 'Please complete required items on the page to continue.'
  useEffect(() => {
    if (!tip.enabled && tip.message !== defaultTip) {
    setTip({...tip, message: defaultTip, enabled: false})
  }
}, [tip])
  return (
    <>
      {tip.enabled && (
        <GlobalBlueAlert
          visible={tip.enabled}
          message={tip.message}
          close={() => setTip({ ...tip, enabled: false })}
        />
      )}
      {error.enabled && (
        <GlobalOrangeAlert
          message={error.message}
          errorCode={error.code}
          close={() => setError({ ...error, enabled: false })}
        />
      )}
    </>
  );
}
