import {stepOrder, stepOrderKorea, stepOrderDefault } from 'constants/stepOrder';

export const getStepOrderByShippingCountry = (shipCountry) => {
  switch (shipCountry) {
    case 'Belgium NFR':
    case 'Canada':
    case 'Germany NFR':
    case 'Netherlands NFR':
    case 'Thailand':
    case 'United Kingdom NFR':
    case 'USA':
      return stepOrder;
    case 'Korea (the Republic of)':
      return stepOrderKorea;
    default:
      return stepOrderDefault;
  }
};
