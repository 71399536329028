const BE = {
  market: 'BE',
  inputs: {
    ZIP: {
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      labelRef: 'PROVINCE',
    },
  },
};
export default BE;
