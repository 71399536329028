import { useState } from 'react';
import { Typography, Stack, Divider, Link, Collapse } from '@newulife/common'
import Price from 'components/common/Price';
import Volume from 'components/common/Volume';
import styles from './styles/CartOrderTotals.module.css';

export default function CartOrderTotals({
  data,
  type,
  showSubtotal = true,
  showDiscount = false,
  subtotal = 0,
  subtotalTax = 0,
  shippingTax = 0,
  tax = 0,
  shipping = 0,
  discount = 0,
  total = 0,
  cv = 0,
  qv = 0,
}) {
  switch (type) {
    case 'subscription':
      return <Subscription data={data} total={total} cv={cv} qv={qv} />;
    default:
      return (
        <Default
          data={data}
          showSubtotal={showSubtotal}
          showDiscount={showDiscount}
          subtotal={subtotal}
          subtotalTax={subtotalTax}
          shippingTax={shippingTax}
          tax={tax}
          shipping={shipping}
          discount={discount}
          total={total}
          cv={cv}
          qv={qv}
        />
      );
  }
}

const Subscription = ({ data, total, cv, qv }) => {
  if (!data) return null;
  const { fields = {} } = data;
  const {
    item16: totalText = '',
    item17: cvText = '',
    item28: qvText = '',
  } = fields;
  return (
    <div className="total-review-summary">
      <div className="review-row border-top-bottom">
        <Typography
          as="p"
          variant="body3"
        >
          <span className="bold">{totalText}</span>
          <span className="font-18 bold total-cost" data-testid="total-Price">
            <Price value={total} />
          </span>
        </Typography>
      </div>
      <Typography
        as="p"
        variant="body3"
        color="neutralCool.main"
      >
        <span>{cvText}</span>
        <span>
          <Volume type="amt" value={cv} />
        </span>{' '}
        <br />
      </Typography>
      <Typography
        as="p"
        variant="body3"
        color="neutralCool.main"
      >
        <span>{qvText}</span>
        <span>
          <Volume type="amt" value={qv} />
        </span>
        <br />
      </Typography>
    </div>
  );
};

const Default = ({
  data,
  showSubtotal,
  showDiscount,
  subtotal = 0,
  subtotalTax = 0,
  shippingTax = 0,
  tax = 0,
  shipping = 0,
  discount = 0,
  total = 0,
  cv = 0,
  qv = 0,
}) => {
  // State
  const [showDetails, setShowDetails] = useState(false);

  // Contentful
  const {
    item13: subtotalText,
    item14: taxText,
    item15: shippingText,
    item16: totalText,
    item17: cvText,
    item28: qvText,
    item30: discountText,
    item32: showDetailsText,
    item33: hideDetailsText,
    item34: subtotalTaxText,
    item35: shippingTaxText,
  } = data.fields;

  return (
    <Stack
      gap={1.5}
    >
      <Divider light />
      <Stack
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 105px',
          sm: '1fr 185px',
        }}
        sx={{
          rowGap: 1,
          columnGap: 1.5,
        }}
        textAlign="right"
      >
        {showSubtotal && (
          <>
            <Typography
              variant="body1"
            >
              {subtotalText}
            </Typography>
            <Typography
              variant="body1"
              data-testid={subtotalText}
            >
              <Price value={subtotal} />
            </Typography>
          </>
        )}
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          {shippingText}
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          <Price value={shipping} />
        </Typography>
        <Stack
          display="inline-grid"
          gridAutoFlow={{
            sm: 'column',
          }}
          justifyContent="flex-end"
          alignItems="baseline"
          gap={1}
        >
          <Typography
            variant="body2"
            color="neutralCool.main"
          >
            {taxText}
          </Typography>
          <Link
            type="button"
            underline="hover"
            sx={{
              fontSize: 14,
              cursor: 'pointer',
            }}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? hideDetailsText : showDetailsText}
          </Link>
        </Stack>
        <Typography
          variant="body2"
          color="neutralCool.main"
          data-testid={taxText}
        >
          <Price value={tax} />
        </Typography>
        {showDetails && (
          <>
            <Typography
              variant="body2"
              color="neutralCool.main"
            >
              {subtotalTaxText}
            </Typography>
            <Typography
              variant="body2"
              color="neutralCool.main"
              data-testid={subtotalTaxText}
            >
              <Price value={subtotalTax} />
            </Typography>
            <Typography
              variant="body2"
              color="neutralCool.main"
            >
              {shippingTaxText}
            </Typography>
            <Typography
              variant="body2"
              color="neutralCool.main"
              data-testid={shippingTaxText}
            >
              <Price value={shippingTax} />
            </Typography>
          </>
        )}
      </Stack>
      <Divider light />
      <Stack
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 105px',
          sm: '1fr 185px',
        }}
        sx={{
          rowGap: 1,
          columnGap: 1.5,
        }}
        textAlign="right"
      >
        {discount !== 0 && showDiscount && (
          <>
            <Typography
              variant="body3"
              color="neutralCool.main"
            >
              {discountText}
            </Typography>
            <Typography
              variant="body3"
              color="neutralCool.main"
              sx={{
                fontSize: 18,
              }}
            >
              -<Price value={discount} />
            </Typography>
          </>
        )}
        <Typography
          variant="subhead1"
        >
          {totalText}
        </Typography>
        <Typography
          variant="subhead1"
        >
          <Price value={total} />
        </Typography>
      </Stack>
      <Divider light />
      <Stack
        display="grid"
        gridTemplateColumns={{
          xs: '1fr 105px',
          sm: '1fr 185px',
        }}
        sx={{
          rowGap: 1,
          columnGap: 1.5,
        }}
        textAlign="right"
      >
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          {cvText}
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          <Volume type="amt" value={cv} />
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          {qvText}
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
        >
          <Volume type="amt" value={qv} />
        </Typography>
      </Stack>
    </Stack>
  );
};
