import StarterKitCard from 'components/common/cards/StarterKitCard';
import RadioGroup from 'components/common/form/FormRadioGroup';
import Radio from 'components/common/form/FormRadio';
import { useProducts } from 'contexts/ProductContext';
import { Paper } from '@newulife/common'
import formatImageSrc from 'helpers/formatImageSrc'

export default function StarterKits({ kits, label }) {
  return (
    <RadioGroup
      type="tab-card"
      ariaLabelledBy={label}
    >
      {kits.map((kit, index) => (
        <StarterKit data={kit} key={index} />
      ))}
    </RadioGroup>
  );
}

const StarterKit = ({ data }) => {
  const { state, dispatch } = useProducts();

  const { ProductID, SmallImageURL, DisplayName, ShortDetail, Price } = data;

  return (
    <Radio
      value={ProductID}
      checked={state.starterKit?.ProductID === ProductID}
      onChange={() =>
        dispatch({ type: 'addStarterKit', product: data, quantity: 1 })
      }
    >
      <Paper
        className="starter-kits"
        active={state.starterKit?.ProductID === ProductID}
        elevationVariant="card"
        sx={{
          height: '100%',
          p: 2,
        }}
      >
        <StarterKitCard
          imgSrc={formatImageSrc(SmallImageURL)}
          imgAlt={DisplayName}
          title={DisplayName}
          contentHtml={ShortDetail}
          price={Price}
        />
      </Paper>
    </Radio>
  );
};
