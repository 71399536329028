import Header from 'components/steps/Header';
import Footer from 'components/steps/Footer';
import AddProductOrSubscriptions from 'components/steps/starter-kit/select-product/AddProductOrSubscriptions';
import { NextContextProvider } from 'contexts/NextContext';
import useContentfulApi from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';
import CONTENTFUL from 'constants/contentful'

export default function SelectProduct() {
  const data = useContentfulApi(CONTENTFUL.select_product);

  if (!data) {
    return null;
  }

  const { headerRef, banner1Ref } = data.fields;

  return (
    <NextContextProvider>
      <Page className="add-products">
        <Header data={headerRef} />
        <MainContent>
          <AddProductOrSubscriptions data={banner1Ref} />
        </MainContent>
        <Footer data={headerRef} />
      </Page>
    </NextContextProvider>
  );
}
