import { useEffect, useState } from 'react';
import { differenceBy } from 'lodash';
import { gtmCaptureReplaceCart } from 'api/gtm';
import { Typography, Alert, IconInfo } from '@newulife/common'
import ShadowCard from 'components/common/cards/ShadowCard';
import CartOrderTotals from 'components/common/cart/CartOrderTotals';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import SubscriptionOrderProductSection from './SubscriptionOrderProductSection';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function SubscriptionOrder({ data, modalData, update }) {
  const {countryInfo: { marketName }} = useRecoilValue(formState);
  const {
    state: { subscriptions },
    dispatch,
    getTotalSubscriptionPrice,
    getTotalSubscriptionCV,
    getTotalSubscriptionQV,
  } = useSubscriptions();

  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasDispatched, setHasDispatched] = useState(false);

  useEffect(() => {
    if (hasDispatched) {
      updateCart();
    }
  }, [hasDispatched]);

  const save = async (newSubscriptions) => {
    if (newSubscriptions) {
      setLoading(true);

      // GTM Analytics
      const data = differenceBy(subscriptions, newSubscriptions, 'ProductID');

      if (data.length !== 0) {
        const variant = 'Subscription';
        const { pathname } = window.location;

        gtmCaptureReplaceCart({ data, marketName, variant, pathname });
      }

      // Update Context
      dispatch({
        type: 'replaceSubscriptions',
        subscriptions: newSubscriptions,
      });
      setHasDispatched(true);
    } else {
      close();
    }
  };

  const updateCart = async () => {
    // Update
    await update();

    // Cleanup
    setLoading(false);
    setHasDispatched(false);

    // Close
    close();
  };

  const close = () => {
    setModalOpen(false);
  };

  if (!loading && subscriptions.length === 0) {
    return null;
  }

  const { item17, item18, item22, item23, item28 } = data.fields;

  const subtotal = getTotalSubscriptionPrice();
  const cv = getTotalSubscriptionCV();
  const qv = getTotalSubscriptionQV();

  return (
    <ShadowCard title={item18}>
      <div className="order-summary">
        <SubscriptionOrderProductSection
          data={data}
          modalData={modalData}
          subscriptions={subscriptions}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          loading={loading}
          save={save}
          close={close}
        />
        <CartOrderTotals
          type="subscription"
          totalText={item22}
          cvText={item17}
          qvText={item28}
          total={subtotal}
          cv={cv}
          qv={qv}
        />
        <Alert
          sx={{
            maxWidth: 300,
            ml: 'auto',
          }}
          icon={<IconInfo />}
        >
          <Typography
            as="p"
            variant="body2"
            color="inherit"
          >
            {item23}
          </Typography>
        </Alert>
      </div>
    </ShadowCard>
  );
}
