import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link, Typography } from '@newulife/common'

export const parseRichText = (value) => {
  // Matching general ui/ux for bold and regular text elements.
  return documentToReactComponents(value, {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Typography
            as="p"
            variant="body3"
          >
            {children}
          </Typography>
        )
      },
      [INLINES.HYPERLINK]: (node) => {
        return (
          <Link
            href={node.data.uri} 
            target="_blank" 
            rel="noopener noreferrer"
            underline="hover"
            sx={{
              cursor: 'pointer',
            }}
          >
            {node.content[0].value}
          </Link>
        );
      },
    },
  });
};
