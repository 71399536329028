const GB = {
  market: 'GB',
  inputs: {
    ZIP: {
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      required: false,
    },
  },
};
export default GB;
