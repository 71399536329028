import Price from '../Price';
import Volume from '../Volume';
import { NumberInput, Stack, Typography, Paper } from '@newulife/common'

export default function ProductSubscriptionOnlyCard({
  subscribeText,
  imgSrc,
  imgAlt,
  title,
  cv,
  qv,
  price,
  qty,
  maxQty,
  decrement,
  increment,
}) {
  return (
    <Paper
      data-testid={`${title?.replace(/\W/g, '')}-card`}
      elevationVariant="card"
      active={qty > 0}
    >
      <div className="inner-content">
        <div className="product-quantity-card">
          <div className="product-info-wrapper">
            <img src={imgSrc} alt={imgAlt} />
            <div className="product-info">
              <p className="product-title">{title}</p>
              {cv && qv && (
                <Stack
                  color="#747679"
                  gap={.5}
                >
                  <Typography
                    variant="subbody2"
                  >
                    <Volume type="cv" value={cv} />
                  </Typography>
                  <Typography
                    variant="subbody2"
                  >
                    <Volume type="qv" value={qv} />
                  </Typography>
                </Stack>
              )}
              <Typography
                data-testid={`${title?.replace(/\W/g, '')}-price`}
                as="p"
                variant="body3"
                className="product-price"
              >
                <Price value={price} />
              </Typography>
            </div>
          </div>
          <div className="card--footer space--between">
            <NumberInput
              data-testid={`${title?.replace(/\W/g, '')}-footer`}
              min={0}
              max={maxQty}
              value={qty}
              onChange={data => {
                data > qty ? increment() : decrement()
              }}
              IconButtonProps={{
                sx: { // app.min.css breaks the button states
                  '&:active, &:focus, &:hover': {
                    backgroundColor: '#fff',
                    color: '#7ab800',
                  },
                },
              }}
            />
            <span className="dark-green bold">{subscribeText}</span>
          </div>
        </div>
      </div>
    </Paper>
  );
}
