import { useEffect, useState } from 'react';
import { getMarketConfigByCountry } from 'helpers/marketConfig';
import config from 'config';
import { handleError } from 'api/request';

function useServiceObjects(listOfInputIds, string = '', country) {
  const persistedAddress = JSON.parse(localStorage.getItem('NUL_ADDRESS'));
  const [address, setAddress] = useState(persistedAddress || null);
  useEffect(() => {
    localStorage.setItem('NUL_ADDRESS', JSON.stringify(address));
  }, [address]);
  // initialize scripts and global options variable
  useEffect(() => {
    try {
      var CustomKey = config.REACT_APP_SERVICE_OBJECTS_KEY;
      const marketConfig = getMarketConfigByCountry(country).serviceObjects;
      const { autoComplete, inputs = {} } = marketConfig;
      // temp app requirement until we can figure out how to handle setting data to the dropdowns
      const marketHasDropDownInputs = Object.keys(inputs).some(
        ({ type }) => type === 'dropdown',
      );
      if (!autoComplete || marketHasDropDownInputs || !listOfInputIds.length)
        return; // SO will not be used

      window.options = {
        key: CustomKey,
        setBiasToIP: true,
        countries: { codesList: country },
        bar: { showCountry: true },
        languagePreference: 'en',
        isTrial: false,
      };

      //map input ids to SO ids
      const fieldsMap = soFieldMapping();

      // create fields from ids
      const fields = createFields(listOfInputIds, fieldsMap, string);

      var DOTSGlobalAddressComplete = new window.so.Address(
        fields,
        window.options,
      );

      // before results are shown catch errors and send to backend
      // @TODO add backend api call
      DOTSGlobalAddressComplete.listen(
        'results',
        (addressObj, { Debug, Error }) => {
          if (Error) console.error({ ip: Debug, message: Error });
        },
      );
      // data returned from call
      DOTSGlobalAddressComplete.listen('populate', (address, variations) => {
        setAddress(address);
      });
    } catch (e) {
      handleError(e);
    }
  }, []);

  return {
    address,
  };
}
export default useServiceObjects;

export function soFieldMapping(string = '') {
  const soFieldMap = new Map();

  const fields = [
    'ADDRESS1',
    'ADDRESS2',
    'ADDRESS3',
    'ADDRESS4',
    'ADDRESS5',
    'CITY',
    'STATE',
    'COUNTRY',
    'ZIP',
    'COUNTY',
  ];
  const SOfields = [
    'Address1',
    'Address2',
    'Address3',
    'Address4',
    'Address5',
    'Locality',
    'AdminArea',
    'Country',
    'PostalCode',
    'CountyName',
  ];
  fields.forEach((inputField, i) => {
    const sofield = SOfields[i];
    soFieldMap.set(inputField, sofield);
    if (string)
      soFieldMap.set(string.toUpperCase() + '_' + inputField, sofield);
  });

  return soFieldMap;
}

const searchFields = ['ADDRESS1'];
function createFields(ids, idMap, string) {
  const {
    //  COUNTRY,
    SEARCH,
    POPULATE,
    // PRESERVE
  } = window.so.fieldMode;
  return ids.map((id, i) => {
    const inputId = string.toUpperCase() + '_' + id.toUpperCase();
    const field = idMap.get(id);
    let mode = SEARCH | POPULATE;
    if (searchFields.includes(inputId) || i === 0) {
      return { element: inputId, field, mode };
    }
    mode = POPULATE;
    return { element: inputId, field, mode };
  });
}
