import { isNull } from 'lodash';
import CartOrderTotals from 'components/common/cart/CartOrderTotals';
import ShadowCard from 'components/common/cards/ShadowCard';
import { useProducts } from 'contexts/ProductContext';
import ProductOrderStarterKitSection from './ProductOrderStarterKitSection';
import ProductOrderProductSection from './ProductOrderProductSection';
import ProductOrderPromoSection from './ProductOrderPromoSection';

export default function ProductOrder({
  data,
  modalData,
  details,
  promos = [],
  hasPromotions,
  hasTotals,
  update,
}) {
  // Context
  const {
    state: { starterKit, products },
    getTotalProductPrice,
    getTotalProductCV,
    getTotalProductQV,
  } = useProducts();

  // If there is no starter kit or products in cart, don't render.
  if (isNull(starterKit) && products.length === 0) {
    return null;
  }

  // Contentful
  const { item6: title } = data.fields;
  const { banner2Ref, banner3Ref } = modalData;

  // Cart totals.
  const { OrderTotal, ShippingTotal, ProductTax, ShippingTax, TaxTotal } =
    details;
  const subtotal = getTotalProductPrice();
  const discount = subtotal + TaxTotal + ShippingTotal - OrderTotal;
  const cv = getTotalProductCV();
  const qv = getTotalProductQV();

  return (
    <ShadowCard title={title}>
      <div className="order-summary">
        <ProductOrderStarterKitSection
          data={data}
          modalData={banner2Ref}
          starterKit={starterKit}
          update={update}
        />
        <ProductOrderProductSection
          data={data}
          modalData={banner3Ref}
          products={products}
          update={update}
        />
        <ProductOrderPromoSection data={data} promos={promos} />
        <CartOrderTotals
          data={data}
          showSubtotal={true}
          showDiscount={hasPromotions && hasTotals}
          subtotal={subtotal}
          subtotalTax={ProductTax}
          shippingTax={ShippingTax}
          tax={TaxTotal}
          shipping={ShippingTotal}
          discount={discount}
          total={OrderTotal}
          cv={cv}
          qv={qv}
        />
      </div>
    </ShadowCard>
  );
}
