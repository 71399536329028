import { useAccount } from 'contexts/AccountContext';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import { inputState } from 'state';

function useAccountForm() {
  const form = useRecoilValue(formState);
  const inputs = useRecoilValue(inputState);
  const { account } = useAccount();
  const { parseProductsForOrder } = useProducts();
  const { parseSubscriptionsForOrder } = useSubscriptions();

  const productData = parseProductsForOrder({ isUpdate: false });
  const subscriptionData = parseSubscriptionsForOrder({ isUpdate: false });
  const items = [...productData, ...subscriptionData];
  const country = form?.countryInfo?.country;
  const products = items.map((item) => ({ ...item, country }));

  return { form, account, products, inputs };
}

export default useAccountForm;
