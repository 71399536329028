const ErrorPage = (props) => {
  const { errorMessage } = props;
  const isDevEnv = process.env.NODE_ENV !== 'production';
  return (
    <div style={{ padding: '20px' }}>
      {isDevEnv && (
        <div style={{ fontSize: '50px', marginBottom: '20px' }}>
          {errorMessage}
        </div>
      )}
      <h1>Sorry Something Went Wrong</h1>
      <h2>We are looking into it!</h2>
      <p>
        Something we depend on isn't working correctly so please be patient
        while we coordinate with our partners
      </p>
    </div>
  );
}

export default ErrorPage;
