import { toast } from 'react-toastify';
import { get } from './request';
import MarketsList from 'constants/markets';

export function getMarkets(locale) {
  return get(`/api/enrollment/market-list/${locale}`).then((data = []) => {
    if (data.length === 0) {
      toast.error('Could not load markets. Please contact support', { 
        autoClose: false,
        toastId: 'load-markets'
       })
    }
    const markets = new MarketsList().list;
    return markets
      .map((market) => {
        const { name } = market;
        const label = data.find(
          ({ englishName } = {}) => englishName === name,
        )?.marketName;
        return {
          ...market,
          label,
        };
      }) // add label to markets
      .sort((a, b) => a.iso2 > b.iso2); // alphebetize
  }).catch(err => {
    console.log(err)
  });
}

export function getCountries(locale) {
  return get(`/api/enrollment/country-list/${locale}`).then((data = []) => {
    if (data.length === 0) {
      toast.error('Could not load shipping countries. Please contact support', { 
        autoClose: false,
        toastId: 'load-shipping-countries'
      })
      throw new Error("Could not load shipping countries")
    }
    const markets = new MarketsList().list;
    return markets
      .map((market) => {
        const { country } = market;
        const label = data?.find(
          ({ englishName }) => englishName === country,
        )?.countryName;
        return {
          ...market,
          label,
        };
      }) // add label to markets
      .sort((a, b) => a.iso2 > b.iso2); // alphebetize
  });
}
