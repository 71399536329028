import { createClient } from 'contentful';
import config from 'config';
import { handleError } from './request';

const client = createClient({
  space: config.REACT_APP_CONTENTFUL_SPACE,
  accessToken: config.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: config.REACT_APP_CONTENTFUL_ENVIRONMENT,
});

export const getEntryByLocale = async (id, locale) =>
  await client.getEntry(id, { locale, include: 10 }).catch(handleError);

export const getContentType = (type, locale) =>
  client.getEntries({ content_type: type, locale }).catch(handleError);

const contentfulObj = {
  getContentType,
  getEntryByLocale,
  client,
};
export default contentfulObj;
