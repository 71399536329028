import { createContext, useContext, useState } from 'react';

const LoaderContext = createContext();

export default LoaderContext;

export function LoaderContextProvider({ children }) {
  const [active, setActive] = useState(false);
  const [animationActive, setAnimationActive] = useState(false);
  const [data, setData] = useState();

  // Separated show/hide instead of toggle function for clarity.
  const showLoader = ({ type, loaderData } = {}) => {
    type === 'animation' ? setAnimationActive(true) : setActive(true);
    if (loaderData) setData(loaderData)
  };

  const hideLoader = () => {
    setActive(false);
    setAnimationActive(false);
  };

  return (
    <LoaderContext.Provider value={{ active, animationActive, data, showLoader, hideLoader }}>
      {children}
    </LoaderContext.Provider>
  );
}

export function useLoader() {
  const context = useContext(LoaderContext);

  if (context === undefined) {
    throw new Error('useLoader must be used within a LoaderContextProvider');
  }

  return context;
}
