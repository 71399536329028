import { useLocation } from 'react-router-dom'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { findIndex } from 'lodash'
import { getStepOrderByShippingCountry } from 'helpers/stepOrder';
import { useRecoilValue } from 'recoil'
import { formState } from 'state'

const parseSteps = (data, pathname, countryInfo) => {
  const steps = getStepOrderByShippingCountry(countryInfo.name)
  const { order } = steps.find((step) => step.pathname === pathname);

  const {
    subTitle1: firstDigit,
    subTitle2: firstText,
    subTitle3: firstUrl,
    subTitle4: secondDigit,
    subTitle5: secondText,
    subTitle6: secondUrl,
    subTitle7: thirdDigit,
    subTitle8: thirdText,
    subTitle9: thirdUrl,
    subTitle10: fourthDigit,
    subTitle11: fourthText,
    subTitle12: fourthUrl,
  } = data?.fields || {};

  const stepsData = [
    {
      order: firstDigit,
      text: firstText,
      url: firstUrl,
      active: false,
    },
    {
      order: secondDigit,
      text: secondText,
      url: secondUrl,
      active: false,
    },
    {
      order: thirdDigit,
      text: thirdText,
      url: thirdUrl,
      active: false,
    },
    {
      order: fourthDigit,
      text: fourthText,
      url: fourthUrl,
      active: false,
    },
  ];

  return stepsData.map((step) =>
    parseInt(step.order) <= order ? { ...step, active: true } : step,
  );
};

export default function Header({ type = '', data }) {
  const { pathname } = useLocation()
  const { countryInfo } = useRecoilValue(formState);
  const stepOrder = getStepOrderByShippingCountry(countryInfo.name)
  const activeStepByPathname = stepOrder.find(step => step.pathname === pathname)?.order
  const steps = parseSteps(data, pathname, countryInfo);
  return (
    <Stepper
      activeStep={
        activeStepByPathname 
          ? activeStepByPathname - 1 
          : Math.max(findIndex(steps, { url: pathname }), 0)
      }
      sx={{
        width: '100%',
        maxWidth: {
          md: '75%',
        },
        my: {
          md: 2,
        },
      }}
    >
      {steps.map(step => (
        <Step
          key={step.text}
        >
          <StepLabel>
            {step.text}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
