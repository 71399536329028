export default function PromotionBanner({ country, data }) {
  const { whitelist, image1 } = data?.fields;

  if (country === 'USA') {
    if (!whitelist.includes('United States')) {
      return null;
    }
  } else if (!whitelist.includes(country)) {
    return null;
  }

  return (
    <img
      src={image1?.fields?.file?.url}
      alt={image1?.fields?.title}
      className="mb-20"
      style={{ borderRadius: '10px' }}
    />
  );
}
