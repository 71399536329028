import { useEffect } from 'react';
import useServiceObjects, { soFieldMapping } from 'hooks/useServiceObjects';
import { getMarketConfigByCountry } from 'helpers/marketConfig';
import Input from 'components/common/inputs/Input';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useValidationConsolidator from 'hooks/useValidationConsolidator';
import { findInDictionary } from 'helpers/parse';
import { inputState, shippingAddress as ship_address_state } from 'state';
import { functionsByCountry } from 'api/dropdowns'

/**
 * @params
 * fields = array of strings defined in dictionary
 * isValid = function that when called with true validates the section
 * type = (optional: default is 'account') if present all ids will be prepended with string.  Service Objects uses to map and it will be the property on AccountContext.so where address is saved
 */

function ServiceObjectsAddressSection(props) {
  // props
  const {
    label,
    formType,
    country,
    dictionary,
    isValid: isValidCallback,
    setAddress: setServiceObjectsAddress,
  } = props;

  // local constants
  const countryText = findInDictionary(dictionary, 'COUNTRY');
  const addressConfig = getMarketConfigByCountry(country);
  const CONFIG_FIELDS =
    addressConfig?.enrollment?.form?.sections?.shipping?.fields;
  const getStateRef = (field) =>
    `${formType.toUpperCase()}_${field.toUpperCase()}`;

  //hooks
  const { address } = useServiceObjects(CONFIG_FIELDS, formType, country);
  const validationArray = useValidationConsolidator(
    CONFIG_FIELDS,
    isValidCallback,
  );
  const [inputsState, set_inputsState] = useRecoilState(inputState);

  useEffect(() => {
    if (address) {
      const newValues = CONFIG_FIELDS.reduce(
        (stateRefObject, configFieldName) => {
          const soField = soFieldMapping(formType).get(configFieldName);
          const stateRef = getStateRef(configFieldName);
          return { 
            ...stateRefObject, 
            [stateRef]: address[soField],
          };
        },
        {},
      );
      set_inputsState({ ...inputsState, ...newValues });
      setServiceObjectsAddress(formType, address);
    }
  }, [address]);

  function onFormChange(id, value) {
    set_inputsState({ ...inputsState, [id]: value });
    setServiceObjectsAddress(formType);
  }

  return (
    <fieldset data-testid={`${formType.toUpperCase()}_ADDRESS_FIELDSET`} className="form-max flex-20 column">
      <p className="address-country">
        {countryText}: <strong>{label}</strong>
      </p>
      {CONFIG_FIELDS.map((field, index) => {
        return (
          <Input
            key={`${formType}-section-input-${index + 1}`}
            id={field}
            field={field}
            stateRef={getStateRef(field)}
            labelRef={addressConfig?.inputs?.[field].labelRef || field}
            country={addressConfig.market}
            formType={formType}
            dictionary={dictionary}
            onChange={onFormChange}
            value={inputsState[getStateRef(field)]}
            isValid={validationArray[index]}
          />
        );
      })}
    </fieldset>
  );
}

export default ServiceObjectsAddressSection;
