import { get, post } from './request';

const shipping = {
  get: ({ orderId, shippingCountry, locale }) =>
    get(
      `/api/getShippingMethod/:${orderId}/:${shippingCountry}/:${locale}`,
    ).then((shipMethods) => {
      if (shipMethods?.length > 0) return shipMethods;
      else throw Error('Invalid Ship Methods');
    }),
  updateShippingMethod: (data) => post('/api/updateShipMethod', data),
  updateShippingAddress: (data) => post('/api/orderUpdateAddress', data),
};

export default shipping;
