import { match } from 'components/common/form/formValidationRules';

const NL = {
  market: 'NL',
  inputs: {
    ZIP: {
      labelRef: 'POSTAL_CODE',
      validationChecks: [
        match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i, 'validation-zipcode'),
      ],
    },
    CITY: {
      required: false,
    },
    STATE: {
      labelRef: 'PROVINCE',
    },
  },
};
export default NL;
