import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import useContentfulApi from 'hooks/useContentfulApi';
import useQuery from 'hooks/useQuery';
import {
  repState,
  repDIDState,
  guidState,
  uplineRepDIDState,
  repPositionState,
  repIdParamState,
  idParamState,
  formState,
  persistRepState,
  inputState,
  accountAddress,
  shippingAddress,
} from 'state';
import Header from 'components/home/Header';
import Sponsor from 'components/home/Sponsor';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';
import { DownlineSearchCard } from 'components/common/cards/SponsorCard';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import CONTENTFUL from 'constants/contentful'

export default function HomePage() {
  const history = useHistory();
  const location = useLocation();

  // Set query params to persisted state
  const query = useQuery();
  const [guid, setGuid] = useRecoilState(guidState);
  const [uplineRepDID, setUplineRepDID] = useRecoilState(uplineRepDIDState);
  const [repPosition, setRepPosition] = useRecoilState(repPositionState);
  const [repIdParam, setRepIdParam] = useRecoilState(repIdParamState);
  const [idParam, setIdParam] = useRecoilState(idParamState);

  // State
  const rep = useRecoilValue(repState());
  const [repDID, setRepDID] = useRecoilState(repDIDState);

  // Handle query parameters and repDID updates
  useEffect(() => {
    if (query.get('guid')) setGuid(query.get('guid'));
    if (query.get('uplineRepDID')) setUplineRepDID(query.get('uplineRepDID'));
    if (query.get('position')) setRepPosition(query.get('position'));
    if (query.get('repId')) setRepIdParam(query.get('repId'));
    if (query.get('id')) setIdParam(query.get('id'));

    const repIdToSet = rep?.RepDID || repIdParam || idParam;
    setRepDID(repIdToSet);
  }, [query, rep, repIdParam, idParam, setGuid, setUplineRepDID, setRepPosition, setRepIdParam, setIdParam, setRepDID]);
  
  // Keep persisted search params
  useEffect(() => {
    const params = {};
    if (guid) params.guid = guid;
    if (uplineRepDID) params.uplineRepDID = uplineRepDID;
    if (repPosition) params.position = repPosition;
    if (repIdParam) params.repId = repIdParam;
    if (idParam) params.id = idParam;
    const query = new URLSearchParams(params);
    history.replace({ pathname: location.pathname, search: query.toString() });
  }, []);

  // clear localstorage, recoil state, and context state when
  // refreshing on homepage
  const { dispatch: productDispatch } = useProducts();
  const { dispatch: subscriptionDispatch } = useSubscriptions();
  const resetInputs = useResetRecoilState(inputState);
  const resetAdd1 = useResetRecoilState(accountAddress);
  const resetAdd2 = useResetRecoilState(shippingAddress);
  const resetForm = useResetRecoilState(formState);
  const resetRepDIDState = useResetRecoilState(repDIDState);
  const setPersistRep = useSetRecoilState(persistRepState);
  useEffect(() => {
    localStorage.clear();
    resetAdd1();
    resetAdd2();
    resetInputs();
    resetForm();
    resetRepDIDState();
    setPersistRep(true);
    productDispatch({ type: 'clearCart' });
    subscriptionDispatch({ type: 'clearCart' });
  }, []);

  // contenful
  const data = useContentfulApi(CONTENTFUL.index);
  if (!data) return null;
  const { banner1Ref, banner2Ref } = data?.fields;
  const headerContent = [banner1Ref?.fields?.item1, banner1Ref?.fields?.item2];
  const [item1, item2, ...cardContent] = banner2Ref?.fields.listArray.map(
    ({ fields: { textString } }) => textString,
  );
  const guidHeaderContent = [item1, item2];

  return (
    <>
      <Page className="welcome-screen">
        <MainContent>
          <Header contentful={repDID ? guidHeaderContent : headerContent} />
          {guid ? (
            <DownlineSearchCard contentful={cardContent} />
          ) : (
            <Sponsor data={banner1Ref} isAuthed={!!guid} />
          )}
        </MainContent>
      </Page>
    </>
  );
}
