const pageData = {
  US: {
    'en-US': {
      termsPDF:
        'https://resources.newulife.com/storage/policies/Policies%20and%20Procedures%20240716.pdf',
      gaiyoshomenPDF:
        'https://resources.newulife.com/storage/gc/japan/jp_gaiyoshomen_jp.pdf',
    },
    'zh-Hans-HK': {
      termsPDF:
        'https://d9b54x484lq62.cloudfront.net/newulife/personal/resources/us%20terms%20and%20policies%20in%20chinese.pdf',
    },
    'zh-Hant-TW': {
      termsPDF:
        'https://d9b54x484lq62.cloudfront.net/newulife/personal/resources/us%20terms%20and%20policies%20in%20chinese.pdf',
    },
  },
  TW: {
    'en-US': {
      termsPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/Policies%20and%20procedures%20政策與程序%2020220110.pdf',
    },
  },
  SG: {
    'en-US': {
      termsPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/Singapore%20Terms%20%5E0%20Policies%206.23.2022.pdf',
    },
  },
  MY: {
    'en-US': {
      termsPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/Malaysia%20Terms%20%5E0%20Policies%206%5E.27.pdf',
    },
  },
  // TH: {
  //   'en-US': {
  //     termsPDF:
  //       'https://resources.newulife.com/storage/global/enrollment/pdfs/Thailand%20Terms%20%5E0%20Policies%206%5E.27.pdf',
  //   },
  // },
  HK: {
    'zh-Hans-HK': {
      termsPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/HK-HKNFR.pdf',
    },
    'zh-Hant-TW': {
      termsPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/HK-HKNFR.pdf',
    },
  },
  KR: {
    'ko-KR': {
      termsPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/KO-P&P-AP-V2%20(1).pdf',
      privacyPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/230217%20%EB%89%B4%EC%9C%A0%EB%9D%BC%EC%9D%B4%ED%94%84%EC%BD%94%EB%A6%AC%EC%95%84_%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EB%8F%99%EC%9D%98%EC%84%9C.pdf'
    },
    'en-US': {
      termsPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/KR-EN-Terms&Policies-AP-V1.pdf',
      privacyPDF:
        'https://resources.newulife.com/storage/global/enrollment/pdfs/230217%20%EB%89%B4%EC%9C%A0%EB%9D%BC%EC%9D%B4%ED%94%84%EC%BD%94%EB%A6%AC%EC%95%84_%EA%B0%9C%EC%9D%B8%EC%A0%95%EB%B3%B4%EB%8F%99%EC%9D%98%EC%84%9C.pdf'
    },
  },
};
export default function localizedData(country = 'US', locale = 'en-US') {
  const first = pageData['US']['en-US'];
  const second = pageData['US'][locale];
  const third = pageData[country] ? pageData[country]['en-US'] : '';
  const fourth = pageData[country]?.[locale] ? pageData[country][locale] : '';
  const objectData = { ...first, ...second, ...third, ...fourth };
  return objectData;
}
