const SG = {
  market: 'SG',
  inputs: {
    fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'ZIP'],
    ZIP: {
      required: false,
      labelRef: 'POSTAL_CODE',
      mask: '999999',
    },
  },
};
export default SG;
