// import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const SubscribePage = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const productData = queryParams.get('product-data')

  // useEffect(() => {
  //   if (productData) {
     
  //   }
  // }, [
  //   productData,
  // ])

  return (
    <div>
      <h1>Subscribe Page</h1>
      <p>productData: {productData}</p>
    </div>
  )

}

export default SubscribePage