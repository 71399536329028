import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { guid } from 'api/guid';
import ProfileSVG from 'assets/svg/profile.svg';
import LearnSVG from 'assets/svg/learn.svg';
import { parseRichText } from 'helpers/contentful';
import config from 'config';
import { LoaderRing } from 'components/common/loaders/SpinnerLoader';
import { Stack, Skeleton } from '@newulife/common';

export default function Congratulations({
  data,
  rep,
  taxIdNotUnique,
  profileUrl,
  apiIsLoading,
}) {
  // Props
  const { RepDID, BillCountry } = rep;
  const { banner1Ref, banner2Ref } = data?.fields;

  return typeof BillCountry === 'string' && BillCountry === 'TAIWAN' ? (
    <TaiwanPage data={banner2Ref} rep={rep} profileUrl={profileUrl} apiIsLoading={apiIsLoading} />
  ) : (
    <DefaultPage
      data={banner1Ref}
      rep={rep}
      profileUrl={profileUrl}
      taxIdNotUnique={taxIdNotUnique}
      apiIsLoading={apiIsLoading}
    />
  );
}

const TaiwanPage = ({ data, rep, profileUrl, apiIsLoading }) => {
  const { title, item1, item2, item3, item4, item5, richText } = data?.fields;
  const { RepDID } = rep;

  return (
    <>
      <section className="intro-text center">
        <h1>{title}</h1>
        <p className="text-center wide-desktop">
          {item1} <strong>{RepDID}</strong>
        </p>
        <p className="text-center wide-desktop">{item2}</p>
      </section>
      <section>{parseRichText(richText)}</section>
      {apiIsLoading ? (
        <div className="grid form-max">
          <button className="button card-action-button shadow" disabled>
            <Skeleton
              variant="circular"
              width="78px"
              height="78px"
              sx={{ flex: 'none' }}
            />
            <div className="account-content" style={{ flex: '1 1' }}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: '21px',
                  width: '100%',
                  lineHeight: '25px',
                  marginBottom: '0',
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: '21px',
                  width: '50%',
                  lineHeight: '25px',
                  marginBottom: '4px',
                }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '100%' }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '100%' }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '75%' }}
              />
            </div>
          </button>
        </div>
      ) : (
        <div className="grid form-max">
          <a
            href={`${profileUrl}&ref=twenrollment`}
            className="card-action-button shadow"
          >
            <img src={ProfileSVG} alt="congratulations-profile" />
            <div className="account-content">
              <h4 className="green">{item4}</h4>
              <p>{item5}</p>
            </div>
          </a>
        </div>
      )}
    </>
  );
};

const DefaultPage = ({
  data,
  rep,
  profileUrl,
  taxIdNotUnique,
  apiIsLoading,
}) => {
  const {
    title,
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7,
    item8,
    buttonUrl2,
  } = data?.fields;
  const { RepDID } = rep;

  return (
    <>
      <section className="intro-text center">
        <h1>{title}</h1>
        <p className="text-center wide-desktop">
          {item1} <strong>{RepDID}</strong>
        </p>
        <p className="text-center wide-desktop">{item2}</p>
      </section>
      {taxIdNotUnique && <div style={{ marginBottom: '15px' }}>{item8}</div>}
      <p className="bold mb-40">{item3}</p>
      {apiIsLoading ? (
        <div className="grid form-max">
          <button className="button card-action-button shadow" disabled>
            <Skeleton
              variant="circular"
              width="78px"
              height="78px"
              sx={{ flex: 'none' }}
            />
            <div className="account-content" style={{ flex: '1 1' }}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: '21px',
                  width: '100%',
                  lineHeight: '25px',
                  marginBottom: '0',
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  fontSize: '21px',
                  width: '50%',
                  lineHeight: '25px',
                  marginBottom: '4px',
                }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '100%' }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '100%' }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '75%' }}
              />
            </div>
          </button>
          <button className="button card-action-button" disabled>
            <Skeleton
              variant="circular"
              width="78px"
              height="78px"
              sx={{ flex: 'none' }}
            />
            <div className="account-content" style={{ flex: '1 1' }}>
              <Skeleton
                variant="text"
                sx={{
                  fontSize: '21px',
                  width: '100%',
                  lineHeight: '25px',
                  marginBottom: '4px',
                }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '100%' }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '100%' }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: '1rem', width: '75%' }}
              />
            </div>
          </button>
        </div>
      ) : (
        <div className="grid form-max">
          <a
            href={`${profileUrl}&ref=enrollment`}
            className="button card-action-button shadow"
            data-testid="congratulations-profile Claim"
          >
            <img src={ProfileSVG} alt="congratulations-profile" />
            <div className="account-content">
              <h4 className="green">{item4}</h4>
              <p>{item5}</p>
            </div>
          </a>
          <a className="button card-action-button" href={buttonUrl2}>
            <img src={LearnSVG} alt="congratulations-learn" />
            <div className="account-content">
              <h4 className="green">{item6}</h4>
              <p>{item7}</p>
            </div>
          </a>
        </div>
      )}
    </>
  );
};
