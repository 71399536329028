import { useState, useEffect, Fragment, useMemo } from 'react';
import Input from 'components/common/inputs/Input';
import ShadowCard from 'components/common/cards/ShadowCard';
import useValidationConsolidator from 'hooks/useValidationConsolidator';
import MarketsList from 'constants/markets';
import TermsPageModal from 'components/common/modals/TermsModal';
import { Checkbox, Stack, FormControlLabel, Typography, Divider, Link } from '@newulife/common'
import { getTermLabelMap, termSourceMap, localeIdMap } from 'constants/termsPage';
import { useRecoilValue, useRecoilState } from 'recoil'
import { formState } from 'state'
import terms from 'api/terms';
import envConfig from 'config'
import localizedData from 'translations/pages/create-account'
import useContentfulApi from 'hooks/useContentfulApi'
import { find, isEmpty } from 'lodash'
import product from '../../../../api/product';

function GeneralSection(props) {

  const contentfulKRTerms = useContentfulApi(props.isTerms && '1or2h9PC78g1xWORQPr9dV'); // Korea Enrollment Terms and Conditions
  const [form, setForm] = useRecoilState(formState)
  const [termsData, setTermsData] = useState(null)
  const [termsDataLoading, setTermsDataLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState({ open: false, modalProps: {} });
  const { localeId, countryInfo, locale, ...rest } = useRecoilValue(formState)
  const isKoreaTerms = props.isTerms && countryInfo?.iso2 === 'KR'
  const { config, data = {}, isValid, inputs: dictionary, name } = props;

  const [
    requiredOptions,
    optionalOptions,
  ] = useMemo(() => {
    if (!isKoreaTerms) return [[], []]
    return [
      contentfulKRTerms?.fields?.requiredCheckboxItems?.map?.(o => {
        let temp = {}
        termsData?.forEach(o1 => {
          if (o1.AreaId === o?.fields?.byDesignAreaId) temp = o1
        })
        if (isEmpty(temp)) return null
        return {
          ...temp,
          ...o.fields,
        }
      })?.filter(o => !!o) || [],
      contentfulKRTerms?.fields?.optionalCheckboxItems?.map?.(o => {
        let temp = {}
        termsData?.forEach(o1 => {
          if (o1.AreaId === o?.fields?.byDesignAreaId) temp = o1
        })
        if (isEmpty(temp)) return null
        return {
          ...temp,
          ...o.fields,
        }
      })?.filter(o => !!o) || [],
    ]
  }, [
    termsData,
    isKoreaTerms,
    termsDataLoading,
    contentfulKRTerms,
  ])

  //Set default values for terms if Korea
  useEffect(() => {
    if (isKoreaTerms) {
      setForm(prev => {
        const combinedTerms = [...(requiredOptions || []), ...(optionalOptions || [])].map((o, idx) => {
          const existingTerm = prev.terms.find(term => term.TermId === o?.TermId)

          if (existingTerm) {
            return existingTerm;
          } else {
            return {
              TermId: o?.TermId,
              IsAgreedTo: false,
              ...(o.MiscField ? { MiscField: o.MiscField } : {})
            }
          }
        });

        return {
          ...prev,
          terms: combinedTerms,
        }
      })
    }
  }, [requiredOptions, optionalOptions, isKoreaTerms])

  const fields =
    isKoreaTerms ? requiredOptions?.map?.(o => o.TermId) || [] :
      config?.enrollment?.form?.sections?.[name]?.fields || []
  const { title, sectionList, list } = data;

  function allFieldsValidApiCall(validationValue) {
    isValid(validationValue);
  }

  // Get proper value for Tax Validation
  const markets = new MarketsList();
  const market = markets.find(config?.market).country

  const arrayOfInputValidationFunctions = useValidationConsolidator(
    fields,
    allFieldsValidApiCall,
  );

  const allChecked = form.terms && Object.values(form.terms).every(term => term?.IsAgreedTo);

  const handleCheckAll = e => {
    if (allChecked) {
      document.querySelectorAll('.js-check').forEach(el => {
        if (el.querySelector('input').checked) el.querySelector('input').click()
      })
    } else {
      document.querySelectorAll('.js-check').forEach(el => {
        if (!el.querySelector('input').checked) el.querySelector('input').click()
      })
    }
  }

  // const handleCheckAll = e => {
  //   if (allChecked) {
  //     // If all are checked, uncheck all
  //     setForm(prev => {
  //       const combinedTerms = [...(requiredOptions || []), ...(optionalOptions || [])].map((o, idx) => {
  //         const existingTerm = prev.terms.find(term => term.TermId === o?.TermId)
  //         if (existingTerm && existingTerm.IsAgreedTo !== false) {
  //           // find(arrayOfInputValidationFunctions, existingTerm.TermId)?.(false)
  //           return { ...existingTerm, IsAgreedTo: false };
  //         }
  //         else {
  //           return existingTerm
  //         }
  //       });

  //       return {
  //         ...prev,
  //         terms: combinedTerms,
  //       }
  //     })
  //   } else {
  //     // If not all are checked, check all
  //     setForm(prev => {
  //       const combinedTerms = [...(requiredOptions || []), ...(optionalOptions || [])].map((o, idx) => {
  //         const existingTerm = prev.terms.find(term => term.TermId === o?.TermId)
  //         if (existingTerm && existingTerm.IsAgreedTo !== true) {
  //           // find(arrayOfInputValidationFunctions, existingTerm.TermId)?.(true)
  //           return { ...existingTerm, IsAgreedTo: true };
  //         } else {
  //           return existingTerm
  //         }
  //       });

  //       return {
  //         ...prev,
  //         terms: combinedTerms,
  //       }
  //     })
  //   }
  // }

  const handleChange = (idx, idx1, field) => {
    const validationIndex = idx === 0 ? idx1 : idx1 + requiredOptions.length // don't @ me

    setForm(prev => {

      const terms = prev.terms?.map?.(o => {
        if (o.TermId === field?.TermId) {
          const temp = !!!o.IsAgreedTo
          if (idx === 0) arrayOfInputValidationFunctions[validationIndex]?.(temp) // validate required fields only

          return { ...o, IsAgreedTo: temp }
        }
        return o
      }) || []

      return {
        ...prev,
        terms,
      }
    })
  }

  const getTerms = async () => {
    setTermsDataLoading(true)
    const localeId = await product.getLocaleId(countryInfo.name, locale)
    const res = await terms.post(localeId || 1, countryInfo?.name?.toUpperCase())
    setTermsData(res)
    setTermsDataLoading(false)
  }

  useEffect(() => {
    if (isKoreaTerms && locale && countryInfo.name) getTerms()
  }, [
    locale,
    countryInfo.name,
    isKoreaTerms,
  ])

  if (!fields?.length) return null;

  return (
    <>
      <ShadowCard title={title}>
        {isKoreaTerms ? (
          <Stack
            gap={4}
          >
            <div
              className="form-max flex-20 column"
            >
              {sectionList?.map((item) => <p key={item}>{item}</p>)}
              <Stack
                gap={2}
              >
                <FormControlLabel
                  // data-testid={`toggle-${id}`}
                  sx={{
                    alignItems: 'flex-start',
                  }}
                  control={
                    <Checkbox
                      id="select-all"
                      // name={id}
                      // aria-label={label}
                      onChange={handleCheckAll}
                      checked={allChecked}
                    />
                  }
                  label={
                    <Stack gap={1} pt={0.3}>
                      <Typography
                        as="label"
                        variant="body3"
                        htmlFor="select-all"
                      >
                        {contentfulKRTerms?.fields?.selectAllCheckboxItem?.fields?.name}
                      </Typography>
                    </Stack>
                  }
                />
                <Typography
                  variant="body3"
                >
                  {contentfulKRTerms?.fields?.selectAllCheckboxItem?.fields?.disclaimerText}
                </Typography>
              </Stack>
            </div>
            <Divider />
            <div
              className="form-max flex-20 column"
            >
              {[requiredOptions, optionalOptions].map((o, idx) => (
                (o?.length > 0) && (
                  <Fragment
                    key={`termsSection${idx}`}
                  >
                    {optionalOptions?.length > 0 && (<Typography
                      variant="body1"
                    >
                      {idx === 0 ? contentfulKRTerms?.fields?.requiredSectionText : contentfulKRTerms?.fields?.optionalSectionText}
                    </Typography>
                    )}

                    {o?.map?.((field, idx1) => (
                      <FormControlLabel
                        key={`terms${idx}${idx1}`}
                        sx={{
                          alignItems: 'flex-start',
                        }}
                        control={
                          <Checkbox
                            className="js-check"
                            id={field?.TermId.toString()}
                            name={field?.TermId.toString()}
                            checked={!!find(form.terms, { TermId: field?.TermId })?.IsAgreedTo}
                            onChange={() => handleChange(idx, idx1, field)}
                          />
                        }
                        label={
                          <Stack gap={1} pt={0.3}>
                            {field?.checkboxLabel && (
                              <Typography
                                as="label"
                                variant="body3"
                                htmlFor={field?.TermId}
                              >
                                {field?.checkboxLabel}
                              </Typography>
                            )}
                            {field?.linkedFile?.fields?.file?.url ? (
                              <Link
                                href={field?.linkedFile?.fields?.file?.url}
                                target="_blank"
                                underline="hover"
                                sx={{
                                  fontWeight: 500,
                                  display: 'block',
                                }}
                              >
                                {field?.linkLabel}
                              </Link>
                            ) : field?.modalText ? (
                              <Link
                                underline="hover"
                                sx={{
                                  fontWeight: 500,
                                  display: 'block',
                                }}
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setModalOpen({ open: true, modalProps: field })
                                }}
                              >
                                {field?.linkLabel}
                              </Link>
                            ) : null}
                          </Stack>
                        }
                      />
                    ))}
                  </Fragment>
                )
              ))}
            </div>
          </Stack>
        ) : (
          <div
            className="form-max flex-20 column"
          >
            {fields.map((field, index) => {
              return (
                <Input
                  id={field}
                  key={`${name}-${field}`}
                  dictionary={dictionary}
                  isValid={arrayOfInputValidationFunctions[index]}
                  data={data}
                  country={config.market}
                  market={market}
                // onOpenModal={() => handleOpenModal(field)}
                />
              );
            })}
          </div>
        )}
      </ShadowCard>
      {modalOpen.open && (
        <TermsPageModal
          {...modalOpen.modalProps}
          close={() => setModalOpen({ open: false, modalProps: {} })}
        />
      )}
    </>
  );
}
export default GeneralSection;
