import { createContext, useContext, useState } from 'react';
import InvalidAddressModal from 'components/common/modals/InvalidAddressModal';
import ConfirmAddressModal from 'components/common/modals/ConfirmAddressModal';

const initialState = {
  type: 'invalid-address',
  open: false,
};
const ModalContext = createContext();

const ModalRenderer = ({ modal }) => {
  const { type, ...newProps } = modal;
  switch (type) {
    case 'confirm-address':
      return <ConfirmAddressModal {...newProps} />;
    case 'invalid-address':
      return <InvalidAddressModal {...newProps} />;
    default:
      throw new Error(`Unhandled modal type in ModalRenderer: ${type}`);
  }
};

// Used to manage modal types and the opened/closed status of them.
const ModalContextProvider = ({ children }) => {
  const [modal, setModal] = useState(initialState);
  const open = () => {
    // Scroll parent to the top.
    const parentOrigin = 'https://dash.newulife.com/';
    if (window.parent.origin === parentOrigin) {
      window.parent.postMessage({ task: 'scrollToTop' }, parentOrigin);
    }
    // Scroll to top.
    window.scrollTo(0, 0);
    // Prevent scrolling on body.
    // document.body.classList.add('modal-open') @TODO remove
    setModal({ ...modal, open: true });
  };

  const close = () => {
    // Remove scroll lock on body.
    document.body.classList.remove('modal-open');
    setModal(initialState);
  };
  const set = (newState) => {
    setModal({ ...modal, ...newState });
  };

  return (
    <ModalContext.Provider value={{ open, close, set }}>
      {modal.open && <ModalRenderer modal={modal} />}
      {children}
    </ModalContext.Provider>
  );
};

// Custom hook to consume context.
const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContextProvider');
  }

  return context;
};

export { ModalContextProvider, useModal };
