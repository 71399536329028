/**
 * Docs: https://corporate-ux.azurewebsites.net/enrollment/loader-documentation.php
 *
 * @param {boolean} active whether the loader is active or not.
 * @returns a loader that is used when a user must wait when there is a system action blocking the loading of content.
 */

import { useEffect, useState } from 'react';

export default function Loader({ active = false }) {
  if (!active) {
    return null;
  }

  return (
    <div className="modal--wrapper loader-state fade-in">
      <LoaderRing active={active} />
    </div>
  );
}

export function LoaderRing({ active = false, time = 400, variant = '' }) {
  const [show, setShow] = useState(false)
  const isButton = variant === 'button';

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), isButton ? 0 : time);
    return () => clearTimeout(timer);
  }, [time, active, isButton])

  if (!active || !show) {
    return null;
  }

  return (
    <div className={`loader-ring fadeIn ${isButton && ' small-loader'}`}>
      <div></div>
    </div>
  );
}
