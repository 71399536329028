import Header from 'components/steps/Header';
import Footer from 'components/steps/Footer';
import YourStarterKit from 'components/steps/starter-kit/select-kit/YourStarterKit';
import { NextContextProvider } from 'contexts/NextContext';
import useContentfulApi from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';
import CONTENTFUL from 'constants/contentful'
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import { useHistory } from 'react-router-dom';


export default function SelectKit() {
  const data = useContentfulApi(CONTENTFUL.select_kit);
  const history = useHistory();

  const formData = useRecoilValue(formState);

  if(!formData.rep.RepDID) {
    history.push('/');
  }

  if (!data) {
    return null;
  }

  const { headerRef } = data.fields;

  return (
    <NextContextProvider>
      <Page className="starter-kit-screen">
        <Header data={headerRef} />
        <MainContent>
          <YourStarterKit data={data} />
        </MainContent>
        <Footer data={headerRef} />
      </Page>
    </NextContextProvider>
  );
}
