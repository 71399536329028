import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import StarterKits from './StarterKits';
import PromotionBanner from './PromotionBanner';
import { Typography, Stack } from '@newulife/common'
import { formState, kitsState } from 'state';
import { LoaderRing } from 'components/common/loaders/SpinnerLoader';
import envConfig from 'config';
import JapanEnrollmentTooltip from './JapanEnrollmentTooltip';

export default function SelectYourStarterKit({ data }) {
  const form = useRecoilValue(formState);
  let { countryInfo: { country, iso2 } = {} } = form;
  const { state, contents } = useRecoilValueLoadable(kitsState);
  if (state === 'hasError') {
    return null
  }
  const {
    banner1Ref: {
      fields: { item6 },
    },
    banner2Ref,
  } = data.fields;
  if (iso2 === 'JP' && !envConfig.FEATURE_FLAG_JAPAN_ENROLLMENT) {
    return <JapanEnrollmentTooltip data={data.fields.banner1Ref} />;
  }
  if (state === 'loading') {
    return (
      <Stack mt={4} position="relative">
        <LoaderRing active />
      </Stack>
    )
  }
  if (state !== 'hasValue' || contents.length === 0) {
    return null;
  }

  return (
    <section className="reveal">
      <Typography
        variant="body3"
        textAlign="center"
        mb={2}
      >
        {item6}
      </Typography>
      {/* Promotion banner is whitelisted by shipping country. */}
      <PromotionBanner country={country} data={banner2Ref} />
      <form>
        <StarterKits kits={contents} label="Starter Kits" />
      </form>
    </section>
  );
}
