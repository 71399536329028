export default function FormRadio({
  value = '',
  checked,
  onChange,
  children,
  datatestid,
}) {
  return (
    <label className="radio-container" tabIndex="0" htmlFor={value}>
      <input
        name="method"
        type="radio"
        className="radio-hidden"
        data-testid={datatestid}
        value={value}
        id={value}
        aria-label={value}
        aria-checked={checked ? 'true' : 'false'}
        checked={checked}
        onChange={() => onChange()}
      />
      {children}
    </label>
  );
}
