import { Box } from '@newulife/common'

export default function FormRadioGroup({
  type = '',
  ariaLabelledBy,
  children,
}) {
  return (
    <Box
      className={`grid ${type}`}
      role="radiogroup"
      aria-labelledby={ariaLabelledBy}
      sx={{
        // containerType: 'size',
      }}
    >
      {children}
    </Box>
  );
}
