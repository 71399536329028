const MY = {
  serviceObjects: {
    autoComplete: false,
  },
  market: 'MY',
  inputs: {
    fields: ['ADDRESS1', 'ADDRESS2', 'ZIP', 'CITY', 'STATE'],
    ZIP: {
      required: false,
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      type: 'dropdown',
    },
  },
};
export default MY;
