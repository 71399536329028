import Alerts from 'components/steps/Alerts';
import BackButton from './BackButton';
import NextButton from './NextButton';

export default function Footer({ data }) {
  const { text1, text2, text3 } = data.fields;
  return (
    <div className="page-actions">
      <div className="left-btn">
        <BackButton title={text1} />
      </div>
      <div className="right-btn">
        <NextButton title={text2} optionalTitle={text3} />
      </div>
      <Alerts />
    </div>
  );
}
