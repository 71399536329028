import { isNull } from 'lodash';
import ReviewYourOrder from 'components/steps/review/ReviewYourOrder';
import Header from 'components/steps/Header';
import Footer from 'components/steps/Footer';
import { NextContextProvider } from 'contexts/NextContext';
import useContentfulApi from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import CONTENTFUL from 'constants/contentful'

export default function Review() {
  // Context
  const { orderId, userId, localeId } = useRecoilValue(formState);

  // Custom Hooks
  const data = useContentfulApi(CONTENTFUL.review);

  if (!data || isNull(orderId) || isNull(userId) || isNull(localeId)) {
    return null;
  }

  const {
    headerRef,
    banner1Ref,
    banner2Ref,
    banner3Ref,
    banner4Ref,
    banner5Ref,
  } = data?.fields;

  return (
    <NextContextProvider>
      <Page className="review-screen">
        <Header data={headerRef} />
        <MainContent>
          <ReviewYourOrder
            data={banner1Ref}
            productData={{ banner2Ref, banner3Ref }}
            subscriptionData={banner4Ref}
            disclaimer={banner5Ref}
          />
        </MainContent>
        <Footer data={headerRef} />
      </Page>
    </NextContextProvider>
  );
}
