function CheckIcon({ width = 14, height = 14, stroke = 2, classes = '' }) {

    return (
        <span className={`${classes} valid-status complete display-status`} data-testid='valid'>
            <svg className="valid-complete display-status" width={width} height={height} viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.23242 6.64611L6.14441 11L14.575 1" strokeWidth={stroke} />
            </svg>
        </span>
    )
}

export default CheckIcon;