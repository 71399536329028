import { useEffect, useState } from 'react';
import Input from 'components/common/inputs/Input';
import SectionCard from './SectionCard';
import useValidationConsolidator from 'hooks/useValidationConsolidator';
import { useAccount } from 'contexts/AccountContext';
import { findInDictionary } from 'helpers/parse';
import user from 'api/user';

function BankSection(props) {
  const { config, data = {}, isValid = () => { }, inputs: dictionary, name } = props;

  const fields = config?.enrollment?.form?.sections?.[name]?.fields || [];
  const { title, sectionList } = data;

  const arrayOfInputValidationFunctions = useValidationConsolidator(
    fields, // empty array makes the section optional
    isValid,
  );

  if (!config?.enrollment?.form?.sections?.[name]?.fields) return null;
  return (
    <SectionCard title={title} list={sectionList}>
      {fields.map((field, index) => {
        const inputConfig = config.inputs[field];
        if (field === 'jp-bank-info')
          return (
            <JPBankIdBranchIdComponent
              key={field}
              config={config}
              dictionary={dictionary}
              isValid={arrayOfInputValidationFunctions[index]}
            />
          );
        return (
          <Input
            {...inputConfig}
            id={field}
            key={`${name}-${field}`}
            dictionary={dictionary}
            isValid={arrayOfInputValidationFunctions[index]}
            data={data}
            country={config.market}
          />
        );
      })}
    </SectionCard>
  );
}
export default BankSection;

function JPBankIdBranchIdComponent(props) {
  const { config, dictionary, isValid } = props;
  const { account, setAccount } = useAccount();
  const [bankId, set_bankId] = useState(account['ID_BANK'] || '');
  const [bankName, set_bankName] = useState(account['NAME_BANK_JP'] || '');
  const [branchId, set_branchId] = useState(account['ID_BRANCH'] || '');
  const [branchName, set_branchName] = useState(account['NAME_BRANCH'] || '');

  useEffect(() => {
    user.getJapanBankInfo(bankId).then(set_bankName);
  }, [bankId]);
  useEffect(() => {
    user.getJapanBranchInfo(bankId, branchId).then(set_branchName);
  }, [branchId]);

  useEffect(() => {
    const valid = (bankName && branchName) || (!bankName && !branchName);
    setAccount({ ...account, NAME_BANK_JP: bankName, NAME_BRANCH: branchName });
    isValid(!!valid);
  }, [bankName, branchName]);

  function setBankId(id, value) {
    setAccount({ ...account, [id]: value });
    switch (id) {
      case 'ID_BANK':
        if (!value) {
          set_bankName('');
        }
        set_bankId(value);
        break;
      case 'ID_BRANCH':
        set_branchId(value);
        break;
      case 'NAME_BANK':
        set_bankName(value);
        break;
      case 'NAME_BRANCH':
        set_branchName(value);
        break;
      default:
        console.error('unknown type');
    }
  }

  const inputProps = {
    country: config.market,
    dictionary,
  };
  const errorMessage = findInDictionary(dictionary, 'ID_BRANCH', false).error;
  return (
    <>
      <Input {...inputProps} id="ID_BANK" onChange={setBankId} />
      <Input
        {...inputProps}
        id="NAME_BANK"
        value={bankName}
        onChange={setBankId}
      />
      <Input {...inputProps} id="ID_BRANCH" onChange={setBankId} />
      <Input
        {...inputProps}
        id="NAME_BRANCH"
        value={branchName}
        onChange={setBankId}
      />
      {/* <Response data={bankInfo} errorMessage={errorMessage} /> */}
    </>
  );
}
// function Response(props) {
//   const { data, errorMessage } = props;
//   const { bank, branch } = data;
//   if (data === 'init') return null;
//   if (!bank || !branch)
//     return (
//       <div style={{ color: '#9a3b26', fontSize: '.8rem' }}>{errorMessage}</div>
//     );
//   return (
//     <>
//       <Input />
//     </>
//   );
// }
