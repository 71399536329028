const HK = {
  serviceObjects: {
    autoComplete: false,
  },
  market: 'HK',
  enrollment: {
    form: {
      sections: {
        shipping: {
          fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'STATE'],
        },
      },
    },
  },
  inputs: {
    STATE: {
      labelRef: 'DISTRICT',
      type: 'dropdown',
    },
  },
};
export default HK;
