import { useRef, useState, useCallback } from 'react';

function useContainerOnBlurAndFocus(handlerBlur) {
  const ref = useRef();
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = useCallback(
    (event) => {
      // Use the current target instead of the target to ensure the event
      // delegation during the bubble phase is handled correctly.
      const currentTarget = ref.current;

      // If currentTarget contains relatedTarget, it means the blur event
      // is due to a child element receiving focus, so ignore this event.
      if (!currentTarget || currentTarget.contains(event.relatedTarget)) {
        return;
      }

      handlerBlur(event);
      setIsFocused(false); //Update focus state on blur
    },
    [handlerBlur]
  );

  const handleFocus = useCallback(
    (event) => {
      const currentTarget = ref.current;
      if(currentTarget && currentTarget.contains(event.target)) {
        setIsFocused(true); //Update focus state on focus
      }
    },
    []
  );

  // Set up the event listener for the blur event
  const setRef = useCallback(
    (node) => {
      if (ref.current) {
        // Make sure to remove the event listener on the old node
        ref.current.removeEventListener('focusout', handleBlur);
        ref.current.removeEventListener('focusin', handleFocus);
      }

      ref.current = node;

      if (ref.current) {
        // Add the event listener to the new node
        ref.current.addEventListener('focusout', handleBlur);
        ref.current.addEventListener('focusin', handleFocus);
      }
    },
    [handleBlur, handleFocus]
  );

  return [setRef, isFocused];
}

export default useContainerOnBlurAndFocus;
