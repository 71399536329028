export default function AddressFormat({ address }) {
  if (!address) return null;
  if (address.ADDRESS1) return <Address address={address} />;
  else return <SOAddress address={address} />;
}

function Address(props) {
  const { COUNTRY } = props.address;
  switch (COUNTRY) {
    case 'KR':
      return <KRTemplate {...props} />;
    default:
      return <DefaultTemplate {...props} />;
  }
}
function DefaultTemplate(props) {
  const { ADDRESS1, ADDRESS2, CITY, STATE, ZIP, COUNTRY } = props.address;
  return (
    <>
      <AddressChunk data={[ADDRESS1]} />
      <AddressChunk data={[ADDRESS2]} />
      <AddressChunk data={[CITY]} />
      <AddressChunk data={[STATE]} />
      <AddressChunk data={[ZIP]} />
      <AddressChunk data={[COUNTRY]} />
    </>
  );
}
function KRTemplate(props) {
  const { ADDRESS1, ADDRESS2, CITY, STATE, ZIP } = props.address;
  return (
    <>
      <AddressChunk data={[CITY, STATE, ADDRESS1]} />
      <AddressChunk data={[ADDRESS2]} />
      <AddressChunk data={[ZIP]} />
      <AddressChunk data={[ADDRESS2]} />
    </>
  );
}
function AddressChunk({ data }) {
  return (
    <div style={{ padding: '.1rem .4rem' }}>
      {data.map((data) => {
        if (!data) return null;
        return data;
      })}
    </div>
  );
}

export function SOAddress({ address }) {
  if (!address) return null;
  const {
    Address1: ADDRESS1,
    Address2: ADDRESS2,
    AdministrativeArea: STATE,
    Country: COUNTRY,
    Locality: CITY,
    PostalCode: ZIP,
  } = address;
  const _address = {
    ...address,
    ADDRESS1,
    ADDRESS2,
    CITY,
    STATE,
    COUNTRY,
    ZIP,
  };
  return <Address address={_address} />;
}
