import { Stack, Typography, Divider, Box } from '@newulife/common'

export default function CartOrderHeader({
  productText,
  qtyText,
  unitPriceText,
  ...props
}) {
  return (
    <Box
      sx={{
        boxShadow: '0 4px 0 0 #fff, 0 5px 0 0 #ededed',
      }}
    >
      <Stack
        data-testid={productText?.replace(/\W/g, '')}
        display={{
          xs: 'none',
          md: 'grid',
        }}
        alignItems="flex-end"
        gridTemplateColumns="120px repeat(3, 1fr)"
        // gridTemplateColumns="repeat(3, 1fr)"
        gap={2}
        py={1}
        {...props}
      >
        <Typography
          variant="body3"
        >
          {productText}
        </Typography>
        <div />
        <Typography
          variant="body3"
          textAlign="right"
        >
          {qtyText}
        </Typography>
        <Typography
          variant="body3"
          textAlign="right"
        >
          {unitPriceText}
        </Typography>
      </Stack>
      <Divider
        light
      />
    </Box>
  );
}
