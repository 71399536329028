import { Box, Stack, Typography } from '@newulife/common'
import Price from 'components/common/Price';
import Volume from 'components/common/Volume';
import formatImageSrc from 'helpers/formatImageSrc'

export default function CartOrderCell({
  hasDiscount = false,
  imgSrc,
  imgAlt,
  title,
  price = 0,
  discountedPrice = 0,
  cv,
  qv,
  qty,
}) {
  return (
    <Stack
      display="grid"
      gap={1}
      gridTemplateColumns="auto 1fr"
      alignItems="center"
      py={2}
    >
      <Box
        width="120px"
        height="120px"
        sx={{
          'img': {
            width: '100%',
            height: 'auto',
          },
        }}
      >
        <img src={formatImageSrc(imgSrc)} alt={imgAlt} />
      </Box>
      <Stack
        display="grid"
        gap={1.5}
        sx={{
          gridTemplateColumns: {
            md: `repeat(${qty ? 3 : 2}, 1fr)`,
          },
          gridTemplateAreas: {
            md: `
              "title ${qty ? 'quantity ' : ''}price"
              "volume ${qty ? 'quantity ' : ''}price"
            `,
          },
        }}
      >
        <Typography
          data-testid={title?.replace(/\W/g, '')}
          variant="body2"
          sx={{
            gridArea: {
              md: 'title',
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subhead2"
          sx={{
            gridArea: {
              md: 'price',
            },
            alignSelf: {
              md: 'center',
            },
            justifySelf: {
              md: 'flex-end',
            },
          }}
        >
          {hasDiscount ? (
            <>
              <span className="strike-through">
                <Price value={price}></Price>
              </span>
              <br />
              <Price value={discountedPrice} />
            </>
          ) : (
            <Price value={price} />
          )}
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
          sx={{
            gridArea: {
              md: 'volume',
            },
          }}
        >
          {cv ? <Volume type="cv" value={cv} /> : ''}
          {qv ? ' | ' : ''}
          {qv ? <Volume type="qv" value={qv} /> : ''}
        </Typography>
        {qty && (
          <Typography
            variant="body3"
            sx={{
              gridArea: {
                md: 'quantity',
              },
              textAlign: {
                md: 'right',
              },
              alignSelf: {
                md: 'center',
              },
            }}
          >
            {qty}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
