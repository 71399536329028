import defaultMarketConfig from 'marketConfigs/default';

export function getMarketConfigByInputProps(incomingProps) {
  const { country, id } = incomingProps;
  const inputConfig = getMarketConfigByCountry(country)?.[id];
  return { ...incomingProps, ...inputConfig };
}

export function getMarketConfigByCountry(country) {
  const marketConfig = country
    ? require('marketConfigs/' + country)?.default
    : {};
  return deepMap(defaultMarketConfig, marketConfig);
}

function deepMap(obj1 = {}, obj2 = {}) {
  const newObj = { ...obj1 };
  Object.keys(obj2).forEach((key) => {
    if (
      typeof obj2[key] === 'string' ||
      typeof obj2[key] === 'number' ||
      typeof obj2[key] === 'boolean' ||
      typeof obj2[key] === 'function'
    )
      newObj[key] = obj2[key];
    else if (Array.isArray(obj2[key])) {
      newObj[key] = [...obj2[key]];
    } else {
      newObj[key] = deepMap(newObj[key], obj2[key]);
    }
  });
  return newObj;
}
