import { useEffect, useState } from 'react';
import getDownLineSponsors from 'api/downlineSponsors';
import { LoaderRing } from 'components/common/loaders/SpinnerLoader';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function InputDropdown(props) {
  // props and context
  const {
    contentful,
    onSelect: onSelectFromProps = () => {},
    text,
    repName,
    isValueSelected,
  } = props;
  const [text_noResults, text_reset, ...contentfulText] = contentful;
  const { repDID } = useRecoilValue(formState);
  // state
  const [showResults, set_showResults] = useState(false);
  const [focused, set_focused] = useState(false);
  const [value, set_value] = useState('');
  const [options, set_options] = useState([]);
  const [loading, set_loading] = useState(false);
  // effects
  useEffect(() => {
    const el = document.getElementsByClassName('combo-box-wrapper')[0];
    if (focused) el.classList.add('active');
    else el.classList.remove('active');
  }, [focused]);

  // add keyup on Enter event listener - we need to create global actions and events at an app level rather than on a component level
  useEffect(() => {
    function onEnter(e) {
      if (e.code === 'Enter' && value) {
        onSearch();
      }
    }
    document.addEventListener('keyup', onEnter);
    return () => {
      document.removeEventListener('keyup', onEnter);
    };
  }, [value]);

  // functions
  function onChange(e) {
    set_value(e.target.value);
  }

  function onSearch() {
    if (value) {
      set_focused(false);
      set_loading(true);
      getDownLineSponsors({ repDID, searchString: value })
        .then((res) => {
          if (res?.length) {
            set_options(res);
            set_showResults(true);
          } else {
            set_value(text_noResults);
          }
        })
        .finally(() => {
          set_loading(false);
        });
    }
  }
  function onSelect(string, index) {
    set_value(string);
    set_showResults(false);
    onSelectFromProps(options[index]);
    set_options([]);
  }
  function onReset() {
    set_value('');
    set_options([]);
    onSelectFromProps();
  }
  // render
  return (
    <>
      <div className="input-wrapper">
        <div className="combo-box-wrapper complete">
          <input
            type="text"
            id="downline-dropdown-input"
            list="dropdownlist"
            onChange={onChange}
            placeholder={repName}
            value={value}
            autoComplete="off"
            onFocus={(e) => {
              set_focused(true);
            }}
            onBlur={(e) => {
              setTimeout(() => {
                set_focused(false);
              }, 100);
            }}
          />
          <label htmlFor="downline-dropdown-input" aria-label={text}>
            {text}
          </label>
          <svg
            style={{ top: '20px' }}
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.796875 1L7.04263 7L13.2884 1"
              stroke="#747679"
              strokeWidth="2"
            ></path>
          </svg>

          {!!options.length ? (
            <UlList
              options={options.map(({ RepName }) => RepName)}
              isOpen={true}
              toggle={() => {
                set_showResults(!showResults);
              }}
              onSelect={onSelect}
            />
          ) : (
            <InfoBox
              contentful={contentfulText}
              onSearch={onSearch}
              disabled={!value}
            />
          )}
        </div>
      </div>
      {isValueSelected && (
        <button
          className="ghost"
          style={{ margin: '10px 0' }}
          onClick={onReset}
        >
          {text_reset}
        </button>
      )}
      <LoaderRing active={loading} />
    </>
  );
}
function UlList(props) {
  const { options, isOpen, toggle, onSelect } = props;

  if (!options?.length) return null;

  function onClick(value, index) {
    onSelect(value, index);
    toggle();
  }
  return (
    <ul style={{ maxHeight: '175px' }} className={isOpen ? 'reveal' : 'hide'}>
      {options.map((item, i) => {
        return (
          <li
            onClick={() => {
              onClick(item, i);
            }}
            key={`list-item-${i}`}
          >
            {item}
          </li>
        );
      })}
    </ul>
  );
}
function InfoBox(props) {
  const { onSearch, disabled, contentful } = props;
  const [text_downlineSearch, text_search, text_pressEnter] = contentful;
  return (
    <div className="box-data">
      <div className="initial-prompt">
        <div className="flex-20 column">
          <p style={{ marginTop: '15px' }}>{text_downlineSearch}</p>
          <div className="search">
            <button onClick={onSearch} disabled={disabled}>
              {text_search}
            </button>
            <span style={{ paddingLeft: '5px' }}>
              {text_pressEnter}
              <svg
                style={{ marginLeft: '1rem' }}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="16" height="16" fill="white"></rect>
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.4304 2L13.4304 8.41667H3.8797L5.64818 6.64818L4.35182 5.35182L1.01849 8.68515L0.370304 9.33333L1.01849 9.98151L4.35182 13.3148L5.64818 12.0185L3.8797 10.25H14.3471H15.2637L15.2637 9.33333L15.2637 2H13.4304Z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
