import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { get } from 'api/request';
import SaveModal from 'components/common/modals/SaveModal';
import { useProducts } from 'contexts/ProductContext';
import ProductQtyOnlyCardContainer from '../starter-kit/select-product/ProductQtyOnlyCardContainer';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function ProductOrderProductModal({
  data,
  products,
  loading,
  save,
  close,
}) {
  const form = useRecoilValue(formState);
  const { state, dispatch } = useProducts();

  const [items, setItems] = useState([]);

  const {
    countryInfo: { name: country },
    repDID,
    locale,
  } = form;

  useEffect(() => {
    if (!isEmpty(country)) {
      getProducts();
    }
  }, [country, locale]);

  useEffect(() => {
    if (!isEmpty(products)) {
      dispatch({ type: 'replaceProducts', products: products });
    }
  }, []);

  const getProducts = async () => {
    get(`/api/enrollmentGetProducts/:${country}/:${repDID}/:${locale}`).then((res) => {
      if (typeof res !== 'string') {
        sort(res);
      }
    });
  };

  // Merge Sort
  const sort = (arr) => {
    const filterArr = arr.filter(
      (product) =>
        (product.SignupOptionalItem && product.SignupAutoshipOption) ||
        (product.SignupOptionalItem && !product.SignupAutoshipOption),
    );
    const selectedIds = products.map((product) => product.ProductID);

    let left = [];
    let right = [];

    filterArr.forEach((product) => {
      if (selectedIds.includes(product.ProductID)) {
        left.push(product);
      } else {
        right.push(product);
      }
    });

    setItems(left.concat(right));
  };

  const { title, buttonText, item1 } = data?.fields;

  return (
    <SaveModal
      loading={loading}
      onSave={() => save(state.products)}
      onClose={() => close()}
      title={title}
      saveText={buttonText}
    >
      <div className="grid tab-card-small mb-40">
        {items.map((item, index) => {
          return (
            <ProductQtyOnlyCardContainer
              subscribeText={item1}
              data={item}
              centered
              key={index}
            />
          );
        })}
      </div>
    </SaveModal>
  );
}
