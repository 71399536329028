const defaultLanguage = {
  id: 1,
  name: 'English',
  code: 'en',
  locale: 'en-US',
  englishCountry: 'USA',
  languageName: 'English'
};

export default defaultLanguage;
