import Layout from 'components/steps/Layout';
import ConfirmAndPay from 'components/steps/pay/ConfirmAndPay';
import CONTENTFUL from 'constants/contentful';
import { NextContextProvider } from 'contexts/NextContext';
import useContentfulApi from 'hooks/useContentfulApi';

export default function Pay() {
  const data = useContentfulApi(CONTENTFUL.pay);

  if (!data) {
    return null;
  }

  const { banner1Ref } = data.fields;

  return (
    <NextContextProvider>
      <Layout>
        <ConfirmAndPay data={banner1Ref} />
      </Layout>
    </NextContextProvider>
  );
}
