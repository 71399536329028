import { useHistory, useLocation } from 'react-router-dom';
import initialForm from 'constants/initialForm';
import { getStepOrderByShippingCountry } from 'helpers/stepOrder';
import { Button, Box } from '@newulife/common'
import { useRecoilState } from 'recoil';
import { formState } from 'state';
import useQuery from 'hooks/useQuery';

export default function BackButton({ title }) {
  const [form, setForm] = useRecoilState(formState);
  const query = useQuery();
  const history = useHistory();
  const { pathname, search } = useLocation();

  const goBack = () => {
    
    if (form.isShopifyUser && pathname === '/steps/create-account') {
      window.location.assign(form.referrer || 'https://shop-us.newulife.com/checkout')
    } else {
      // Get step order by shipping country.
      const stepOrder = getStepOrderByShippingCountry(form?.countryInfo?.name);
  
      // Get index of current step in step order.
      const currentIndex = stepOrder.findIndex(
        (step) => step.pathname === pathname,
      );
  
      // Get URL of 1 step back.
      const url = stepOrder[currentIndex - 1].pathname;
  
      // Reset form if going back to starting screen.
      if (url === '/') {
        setForm(initialForm());
      }
  
      // remove orderId if going to create-account page
      if (url === '/steps/create-account') {
        setForm({ ...form, orderId: null });
      }
  
      history.push(url + search);
    }
  };

  return (
    <Button 
      className="button back-button" 
      onClick={() => goBack()}
    >
      <Box
        sx={{
          display: 'inline-flex',
          transform: 'rotate(90deg)',
        }}
      >
        <svg
          width="11"
          height="6"
          viewBox="0 0 11 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0.362793 0.558105L5.38528 5.12766L10.4078 0.558105" stroke="currentColor" />
        </svg>
      </Box>
      {title}
    </Button>
  );
}
