import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { post } from 'api/request';
import { getContentType } from 'api/contentful';
import { reverseDate, kanjiOnlyRegex, koreanAddressRegex } from 'helpers/regex';
import { getKoreaBirthday } from 'helpers/parse';

dayjs.extend(relativeTime);

async function getInputs(locale) {
  const { items } = await getContentType('inputModel', locale);
  return parseContentfulInputModel(items);
}

function parseContentfulInputModel(array) {
  return array.map((input) => input.fields);
}

/**
 * Combined requirements
 */
export const optional = {
  validation: () => {
    return true;
  },
  errorMessage: '',
};
export const required = {
  validation: (value) => {
    if (!value) return false;
    else return true;
  },
  errorMessage: 'requiredFields',
};
export const characters_5 = {
  validation: (value) => {
    if (!value) return true;
    if (value.length !== 5) return false;
    else return true;
  },
  errorMessage: 'incorrect-format',
};

export const match = (regex, errorMessage = 'incorrect-format') => {
  return {
    validation: (value) => {
      if (value && value.match(regex)) return true;
      return false;
    },
    errorMessage,
  };
};

export const eighteenYearsOld = (format) => {
  return {
    validation: (value) => {
      try {
        if (!reverseDate.test(value)) throw 'regex fail';
        if (!dayjs(value, format, true).isValid()) throw 'dayjs date test fail';
        const age = dayjs().diff(dayjs(value), 'year');
        if (age < 18 || age >= 115) throw 'outside age range';
        return true;
      } catch (e) {
        console.warn(e);
        return false;
      }
    },
    errorMessage: 'You must be over 18 and birthdate must be valid',
  };
};

export const krEmail = () => {
  return {
    validation: (value) => {
      value = value.toLowerCase();
      if (
        !value.includes('#') &&
        value.match(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
        )
      )
        return true;
      else return false;
    },
    errorMessage: 'validation-email',
  };
};
export const email = () => {
  return {
    validation: (value) => {
      /**
       * This does 3 tests: 
       * 1. Check for spaces at the beginning or end
       * 2. Check that the last character in the last domain extension (e.g. ".com", etc.) is a special character (fails if it is)
       * 3. Check that there is not only one lowecase letter for the domain extension (e.g. ".c")
       * */ 
      if (/\s/.test(value) || !/[a-z]$/.test(value) || /\.[a-z]$/.test(value)) {
        return false;
      }

      /**
       * This is the main email regex. I think it was taken from here: https://emailregex.com/index.html
       */
      value = value.toLowerCase();
      const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      return emailRegex.test(value);
    },
    errorMessage: 'validation-email',
  };
};
export const uniqueEmail = [
  (email) => {
    if (!email) return Promise.resolve(true);
    return post(`/api/validateEmail`, {
      email,
    })
      .then(({ IsSuccessful = false } = {}) => IsSuccessful)
      .catch(() => false);
  },
  'validation-unique-email',
];

export const govIdValidation = [
  (taxId, country, market) => {
    country = market
    return post(`/api/checkDuplicateTaxId`, {
      taxId,
      country,
    })
      .then((isDuplicate) => {
        return !isDuplicate;
      })
      .catch(() => {
        return false;
      });
  },
  'validation-govId',
];

export const krGovIdValidation = [
  async (taxId, country, market) => {
    country = market
    return await post(`/api/checkDuplicateTaxId`, {
      taxId,
      country,
    })
      .then((isDuplicate) => {
        return !isDuplicate;
      })
      .catch(() => {
        return false;
      });
  },
  'kr-validation-govId',
];

export const chinaGovId = [
  (taxId, country) => {
    return post(`/api/checkDuplicateTaxId`, {
      taxId,
      country,
    })
      .then((isDuplicate) => {
        return true;
      })
      .catch(() => {
        return true;
      });
  },
  'validation-govId',
];

export const taiwanZip = () => {
  return {
    validation: async (zip) => {
      try {
        if (!zip) return false;
        const data = await post('/api/testTaiwanZip', { zip });
        return !!data.length;
      } catch {
        return false;
      }
    },
    errorMessage: 'validation-taiwanzip',
  };
};

export const v2 =
  (array = []) =>
    (value, locale, country) => {
      const promises = array.map((obj) => obj.validation(value, locale, country));
      return Promise.all(promises).then((resultArray) => {
        const falseIndex = resultArray.indexOf(false);
        // if is empty array or no failed validations return true
        if (!resultArray.length || falseIndex < 0) return;

        // return error message
        return array[falseIndex].errorMessage;
      });
    };

export default v2;

export function kanjiOnly() {
  return match(kanjiOnlyRegex, 'validation-kanji-only');
}
export function hangulOnly() {
  return match(koreanAddressRegex, 'validation-hangul-only');
}

export const validKrBirthdayInId = () => {
  return {
    validation: async (taxId) => {
      // Parse out the birthday from the Korea RRN
      const result = getKoreaBirthday(taxId)
      return result ? true : false
    },
    errorMessage: 'validation-kr-birthday-in-id',
  };
}