import { Box } from '@newulife/common'

const MainContent = ({
  sx = {},
  ...props
}) =>
  <Box
    className="main-content"
    {...props}
    gap={2}
    sx={{
      width: '100%',
      py: 4,
      px: 2,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      ...sx
    }}
  />

export default MainContent
