import ShadowCard from 'components/common/cards/ShadowCard';

export default function SectionCard(props) {
  // title is outside of shadow card
  // list is inside card in bold
  const { title, list, children } = props;
  return (
    <ShadowCard title={title}>
      <div className="form-max flex-20 column">
        {list &&
          list.map((item) => {
            return <p key={item}>{item}</p>;
          })}
        {children}
      </div>
    </ShadowCard>
  );
}
