import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import InputDropdown from '../form/dropdowns/InputDropdown';
import { getRepName } from 'helpers/rep';
import config from 'config';
import { Typography, Button } from '@newulife/common'
import { useRecoilState, useRecoilValue, } from 'recoil';
import { formState, repState } from 'state';
import user from 'api/user';
import { toast } from 'react-toastify';

const host = config.REACT_APP_BYDESIGN_IMAGE_HOST_URL;

export default function SponsorCard({ imgUrl, name, idText, repDID }) {
  const isImageCard = !!imgUrl;
  const imageSource = host + '/' + imgUrl;
  const sponsorIdText = `${idText}: ${repDID}`;
  const sponsorName = name || sponsorIdText;
  const defaultClass = 'card sponsor-card';
  const cardClasses = isImageCard ? defaultClass + ' image' : defaultClass;

  return (
    <div className={cardClasses} data-testid="sponsorCard">
      {imgUrl && <img src={imageSource} alt={`Profile`} />}
      <Typography
        variant="header2"
        // className="sponsor--name"
        px={2}
        mt={2}
        mb={.4}
        mx="auto"
      >
        {sponsorName}
      </Typography>
      {sponsorName === name && (
        <Typography
          // className="sponsor--id"
          variant="subbody2"
          mb={2}
          px={2}
          textAlign="center"
        >
          {sponsorIdText}
        </Typography>
      )}
    </div>
  );
}

export const DownlineSearchCard = ({ contentful }) => {
  const rep = useRecoilValue(repState())
  const [form, setForm] = useRecoilState(formState)
  const [text_sponsorId, text_distributor, text_continue, ...contentfulText] =
    contentful;

  const { push } = useHistory();
  const { search } = useLocation();

  const [downlegDistributor, set_downlegDistributor] = useState();

  if (!rep) return null;
  const currentRep = downlegDistributor ? downlegDistributor : rep;
  const { PublicImageUrl: imgUrl, RepDID: id } = currentRep;

  const classes = imgUrl ? 'card sponsor-card image' : 'card sponsor-card';

  function onSelectOption(selectedDist) {
    if (selectedDist?.RepDID) {
      const { RepDID } = selectedDist;
      user.get(RepDID).then((result) => {
        if (typeof result === 'string' && result.includes('Cancelled'))  {
          toast.error(result, { toastId: result, autoClose: false })
          // Don't block, just notify with toast
          // throw response2
        } else {
          set_downlegDistributor(result);
        }
      });
    } else {
      set_downlegDistributor();
    }
  }

  function handleContinue() {
    if (downlegDistributor) {
      setForm({
        ...form,
        rep: downlegDistributor,
        repDID: downlegDistributor.RepDID,
        repName: getRepName(downlegDistributor),
      });
    }
    push(`/steps/starter-kit/select-kit${search}`);
  }

  return (
    <>
      <div className="form-max">
        <section className="card--container">
          <div
            style={{ padding: '0 20px 20px' }}
            className={classes}
            data-testid="sponsorImage"
          >
            {imgUrl && <img src={`${host}/${imgUrl}`} alt={`Profile`} />}
            <p style={{ paddingTop: '15px' }} className="sponsor--id">
              {`${text_sponsorId}: ${id ? id : ''}`}
            </p>
            <InputDropdown
              contentful={contentfulText}
              onSelect={onSelectOption}
              text={text_distributor}
              repName={getRepName(rep)}
              isValueSelected={!!downlegDistributor}
            />
          </div>
        </section>
      </div>
      <Button
        style={{ display: 'block', margin: '0 auto' }}
        datatestid="becomeAMemberMobile"
        onClick={handleContinue}
      >
        {text_continue}
      </Button>
    </>
  );
};
