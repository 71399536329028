import { Typography, Stack, Box } from '@newulife/common'
import Price from 'components/common/Price';
import Volume from 'components/common/Volume';
import formatImageSrc from 'helpers/formatImageSrc'

export default function ReviewOrderCell({
  imgSrc,
  imgAlt,
  title,
  price,
  cv,
  qv,
  qty,
}) {
  return (
    <Stack
      display="grid"
      gridTemplateColumns="auto 1fr"
      alignItems="center"
      py={1}
    >
      <Box
        sx={{
          height: {
            xs: 90,
            sm: 100,
          },
          width: {
            xs: 90,
            sm: 100,
          },
          mr: {
            xs: 1.5,
            sm: 2,
          },
          'img': {
            objectFit: 'contain',
            maxWidth: '100%',
            maxHeight: '100%',
          },
        }}
      >
        <img src={formatImageSrc(imgSrc)} alt={imgAlt} />
      </Box>
      <Stack
        data-testid={`${title?.replace(/\W/g, '')}-details`}
        gap={1}
      >
        <Stack
          display="grid"
          gridTemplateColumns={{
            sm: '1fr auto',
          }}
          gap={1}
          alignItems="center"
        >
          <Typography
            variant="body2"
          >
            {title}
          </Typography>
          <Typography
            variant="body3"
          >
            <Price value={price} />
          </Typography>
        </Stack>
        <Stack
          display="grid"
          gridTemplateColumns={{
            sm: '1fr auto',
          }}
          gap={1}
          alignItems="center"
        >
          <Typography
            data-testid="cv-qv"
            variant="body3"
            color="neutralCool.main"
          >
            {cv ? <Volume type="cv" value={cv} /> : ''}
            {qv ? ' | ' : ''}
            {qv ? <Volume type="qv" value={qv} /> : ''}
          </Typography>
          <Typography
            variant="body3"
          >
            {`QTY: ${qty}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
