import Header from 'components/steps/Header';
import MainContent from 'components/common/layout/MainContent';
import Page from 'components/common/layout/Page';

// Deprecated.
export default function Layout({ children, type }) {
  return (
    <Page>
      <Header type={type} />
      <MainContent>{children}</MainContent>
    </Page>
  );
}
