import {
  email,
  uniqueEmail,
  eighteenYearsOld,
} from 'components/common/form/formValidationRules';
import { match } from 'components/common/form/formValidationRules';
const defaultConfig = {
  serviceObjects: {
    autoComplete: true,
  },
  enrollment: {
    form: {
      sections: {
        account: {
          fields: ['NAME_FIRST', 'NAME_LAST', 'COMPANY', 'EMAIL', 'PASSWORD'],
          order: 0,
        },
        shipping: {
          fields: ['ADDRESS1', 'ADDRESS2', 'CITY', 'STATE', 'ZIP'],
          order: 1,
        },
        personal: {
          fields: undefined,
          order: 2,
        },
        bank: {
          fields: undefined,
          order: 3,
        },
        tax: {
          fields: undefined,
          order: 4,
        },
        identity: {
          fields: undefined,
          order: 5,
        },
        terms: {
          fields: ['AGE', 'TERMS'],
          order: 6,
        },
      },
    },
  },
  inputs: {
    //user
    NAME_FIRST: {
      forId: 'first name',
      autoCompleteName: 'given-name',
    },
    NAME_LAST: {
      forId: 'last name',
      autoCompleteName: 'family-name',
    },
    EMAIL: {
      type: 'email',
      validationChecks: [email()],
      onBlur: uniqueEmail,
    },
    IDENTITY: {},
    DOB: {
      validationChecks: [eighteenYearsOld('YYYY-MM-DD')],
    },
    NAME_GOVERNMENT: {},
    //bank
    NAME_BANK: {},
    COMPANY: { required: false },
    PHONE: { type: 'tel', labelRef: 'SHIPPING_PHONE' },
    SHIPPING_PHONE: { type: 'tel' },
    //bank
    NAME_ON_BANK: {},
    BANK_ACCOUNT: { labelRef: 'BANK_ACCOUNT' },
    ID_BANK: {},
    ID_BRANCH: {},
    //address
    ADDRESS1: { soId: 'Address1', validationChecks: [match(/^.{1,50}$/)] },
    ADDRESS2: {
      soId: 'Address2',
      required: false,
      validationChecks: [match(/^.{1,50}$/)],
    },
    ADDRESS3: { soId: 'Address3', required: false },
    ADDRESS4: { soId: 'Address4', required: false },
    ADDRESS5: { soId: 'Address5', required: false },
    CITY: { soId: 'Locality', validationChecks: [match(/^.{1,50}$/)] },
    STATE: { soId: 'AdminArea' },
    COUNTY: { required: false, soId: 'CountyName', hide: true },
    ZIP: { labelRef: 'ZIP_CODE', soId: 'PostalCode' },
    COUNTRY: { labelRef: 'COUNTRY', soId: 'Country' },
    //misc
    PASSWORD: {},
    AGE: { type: 'switch' },
    TERMS: { type: 'switch' },
    GAIYOSHOMEN: { type: 'switch' },
  },
};
export default defaultConfig;
