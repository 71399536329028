import { match } from 'components/common/form/formValidationRules';

const NZ = {
  market: 'NZ',
  inputs: {
    ZIP: {
      validationChecks: [match(/^[a-z0-9]{4}$/i, 'validation-4characters')],
    },
    STATE: {
      required: false,
    },
  },
};
export default NZ;
