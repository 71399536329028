// react and context
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@newulife/common';

// components
import Input from 'components/common/inputs/Input';
import { validateBankInfo } from 'api/validation';
import SectionCard from './SectionCard';
import { 
  inputState, 
  accountAddress as account_address_state,
  shippingAddress as shipping_address_state,
  formState 
} from 'state';
import { useRecoilState } from 'recoil';
import { findInDictionary } from 'helpers/parse';
import { useNextContextConsumer } from 'contexts/NextContext';
import useGetInputs from 'hooks/useGetInputs';
import envConfig from 'config';
import { useLoader } from 'contexts/LoaderContext';
import useAccountForm from 'hooks/useAccountForm';
import api from 'api';
import { useHistory } from 'react-router-dom';

function BankAccountForm(props) {
  const {
    config,
    loaderData,
    setError,
    validationErrorCodes: { fields: { 
      item2: codeString,
    } = {} } = {},
    title: { fields: { textString: title } = {} } = {},
  } = props;
  const [form, setForm] = useRecoilState(formState);
  const [inputs, setInputs] = useRecoilState(inputState);
  const [, set_account_address] = useRecoilState(
    account_address_state,
  );
  const [, set_shipping_address] = useRecoilState(
    shipping_address_state,
  );
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const enrollData = useAccountForm();
  const [status, setStatus] = useState('')
  const [showFullError, setShowFullError] = useState(false)
  const country = config?.market;
  const fields = config?.enrollment?.form?.sections?.banking?.fields;
  const { next, setNext } = useNextContextConsumer();
  const dictionary = useGetInputs();
  const [bankNameIsValid, setBankNameIsValid] = useState(false);
  const [nameOnBankIsValid, setNameOnBankIsValid] = useState(false);
  const [bankAccountIsValid, setBankAccountIsValid] = useState(false);

  const {
    addresses
  } = form;
  const niceErrorMap = useMemo(() => {
    const map = new Map()
    if (codeString)
      codeString
        .split(';')
        .map(codePair => codePair.split('|'))
        .forEach(codePairArr => map.set(codePairArr[0], codePairArr[1]))
    return map;
  }, [codeString])

  useEffect(() => {
    if (
      bankAccountIsValid &&
      nameOnBankIsValid &&
      bankNameIsValid
    ) {
      setNext({ ...next, enabled: !!inputs.BANK_ACCOUNT_TYPE, validate: onNextButton });
    } else {
      setNext({ ...next, enabled: false });
    }
  }, [
    bankAccountIsValid,
    nameOnBankIsValid,
    bankNameIsValid,
    inputs.BANK_ACCOUNT_TYPE,
    config?.market,
  ]);

  async function onNextButton() {
    setStatus('validating');
    return {};
  }

  useEffect(() => {
    if (status === 'validating') {
      handleNextButton();
    }
  }, [status]);

  const handleNextButton = async () => {
    if (envConfig.FEATURE_FLAG_LOADING_INDICATOR) {
      showLoader({ type: 'animation', loaderData })
    } else {
      showLoader();
    }
    try {
      await validateBankInfoApiCall()
      await api.order
        .createAll({ ...enrollData, addresses })
        .then(({ orderId, userId }) => {
          hideLoader()
          const [top, bottom] = addresses;
          set_account_address(top);
          set_shipping_address(bottom);
          setForm({ ...form, orderId, userId });
          history.push('/steps/review');
        })
    } catch(err) {
      handleError(err)
    }
  }

  function handleError(error) {
    hideLoader()
    const { code, errors, requestId } = error;
    if (code || errors) setError({ 
      type: code ? 'NICE' : 'createUserComplete', 
      code: code || errors[0]?.code,
      showFullError, 
      requestId 
    });
    setStatus('complete');
    if (!showFullError) setShowFullError(true)
    console.warn(error);
  }

  const validateBankInfoApiCall = async () => {
    const { response, code } = await validateBankInfo(inputs);
    if (code !== '0000' && code !== 'S606') { 
      throw Object.assign(
        new Error(code),
        { response, code }
      )
    } else {
      return
    }
  }

  function dropdownOnChange(id, value) {
    setInputs({ ...inputs, [id]: value });
  }

  if (!fields?.length) return null;
  const inputProps = {
    dictionary,
    country,
  };

  return (
    <SectionCard title={title}>
      <Input
        id="NAME_BANK"
        {...config.inputs['NAME_BANK']}
        {...inputProps}
        isValid={setBankNameIsValid}
        onChange={dropdownOnChange}
      />
      <Input
        id="NAME_ON_BANK"
        {...config.inputs['NAME_ON_BANK']}
        {...inputProps}
        isValid={setNameOnBankIsValid}
      />
      <Input
        id="BANK_ACCOUNT"
        {...config.inputs['BANK_ACCOUNT']}
        {...inputProps}
        isValid={setBankAccountIsValid}
      />
      {config?.market === 'KR' ? (
        <FormControl>
          <FormLabel 
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              marginBottom: '10px',
            }}
            color='secondary'
          >{findInDictionary(dictionary, 'BANK_ACCOUNT_TYPE')}</FormLabel>
          <RadioGroup
            // row
            id="BANK_ACCOUNT_TYPE"
            onChange={(e) => setInputs({ ...inputs, BANK_ACCOUNT_TYPE: e.target.value })}
            aria-labelledby="bank-account-type"
            name="BANK_ACCOUNT_TYPE"
            value={inputs.BANK_ACCOUNT_TYPE || ''}
          >
            <FormControlLabel 
              value="individual" 
              control={<Radio />} 
              label={findInDictionary(dictionary, 'BANK_ACCOUNT_TYPE_INDIVIDUAL')}
            />
            <FormControlLabel 
              value="business" 
              control={<Radio />} 
              label={findInDictionary(dictionary, 'BANK_ACCOUNT_TYPE_BUSINESS')}
            />
          </RadioGroup>
        </FormControl>
      ) : null}
    </SectionCard>
  );
}

export default BankAccountForm;
