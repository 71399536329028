import ShippingSVG from 'assets/svg/shipping.svg';
import PickupSVG from 'assets/svg/pickup.svg';
import ShadowCard from 'components/common/cards/ShadowCard';
import Radio from 'components/common/form/FormRadio';
import Price from 'components/common/Price';
import Shipping from './Shipping';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';
import { Typography, Stack, Paper } from '@newulife/common';

export default function SelectShippingMethod({
  data,
  methods,
  validateMethod,
}) {
  // Context
  const { shipMethodId } = useRecoilValue(formState);

  const { item1 } = data.fields;

  return (
    <ShadowCard title={item1}>
      <form id="shipping-type" className="form-max">
        <div className="grid">
          {methods.map((method, index) => (
            <RadioType
              data={method}
              currentId={shipMethodId}
              validateMethod={validateMethod}
              key={index}
            />
          ))}
        </div>
        <Shipping data={data} />
      </form>
    </ShadowCard>
  );
}

const RadioType = ({ data, currentId, validateMethod }) => {
  const { ID, Description, Price: price, OverrideAddress } = data;
  const checked = currentId === ID

  return (
    <Radio
      value={ID}
      checked={checked ? true : false}
      onChange={() => validateMethod(data.ID)}
      datatestid={Description?.replace(/\W/g, '')}
    >
      <Paper
        elevationVariant="card"
        active={checked}
      >
        <Stack
          display="grid"
          gridTemplateColumns="auto 1fr"
          alignItems="center"
          gap={2}
          py={1}
          px={2}
          sx={{
            'img': {
              filter: checked ? 'grayscale(0)' : 'grayscale(100%)',
              transition: '.2s ease-in',
            },
          }}
        >
          <img
            src={OverrideAddress ? PickupSVG : ShippingSVG}
            alt={OverrideAddress ? 'pickup-svg' : 'shipping-svg'}
          />
          <Stack
            display="inline-grid"
            gap={1}
            gridAutoFlow="column"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body1"
              className="inherit-color"
            >
              {Description}
            </Typography>
            <Typography
              variant="body1"
              className="inherit-color"
            >
              <Price value={price} />
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Radio>
  );
};
