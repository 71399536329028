/**
 * Function
 * Maintain lists of country functionality in one place
 *
 * Add a country to Service Objects
 * List of countries with dropdowns
 */

class MarketsList {
  constructor() {
    this.list = marketsList;
  }
  marketsThatAutoSelectCountry = [
    'TW', 
    'KR', 
    'JP', 
    'CN',
    // 'TH',
  ];
  socountriesNoValidation = ['US']; // US is the only country SO guarantees
  socountries = [
    // these countries need a secondary validation check
    ...this.socountriesNoValidation,
    'CA',
    'AU',
    'NZ',
    'DE',
    'BE',
    'NL',
    'GB',
  ];
  find(string) {
    // string can be iso2, market, or country
    const isoMatch = this.list.find(({ iso2 }) => string === iso2);
    if (isoMatch) return isoMatch;
    const marketMatch = this.list.find(({ market }) => string === market);
    if (marketMatch) return marketMatch;
    const countryMatch = this.list.find(({ country }) => string === country);
    if (countryMatch) return countryMatch;
  }
  getCountries() {
    return this.list.map(({ country }) => country);
  }
  getMarkets() {
    return this.list.map(({ name }) => name);
  }
  get_2() {
    return this.list.map(({ iso2 }) => iso2);
  }
  get_3() {
    return this.list.map(({ iso3 }) => iso3);
  }
  getDropdownCountries() {
    this.list = this.list.filter((market) => {
      const { iso2 } = market;
      return this.socountries.includes(iso2);
    });
    return this;
  }
  getDropdownCountriesNoValidation() {
    this.list = this.list.filter((market) => {
      const { iso2 } = market;
      return this.socountriesNoValidation.includes(iso2);
    });
    return this;
  }
}

const marketsList = [
  {
    name: 'USA',
    country: 'USA',
    iso2: 'US',
    iso3: 'USA',
  },
  {
    name: 'Canada',
    country: 'Canada',
    iso2: 'CA',
    iso3: 'CAN',
  },
  {
    name: 'Hong Kong Cross Market',
    country: 'China',
    iso2: 'CN',
    iso3: 'CHN',
    shipMarket: 'HK',
  },
  {
    name: 'Hong Kong',
    country: 'Hong Kong',
    iso2: 'HK',
    iso3: 'HKG',
  },
  {
    name: 'Taiwan',
    country: 'Taiwan',
    iso2: 'TW',
    iso3: 'TWN',
  },
  {
    name: 'Australia NFR',
    country: 'Australia',
    iso2: 'AU',
    iso3: 'AUS',
  },
  {
    name: 'New Zealand NFR',
    country: 'New Zealand',
    iso2: 'NZ',
    iso3: 'NZL',
  },
  {
    name: 'Japan NFR',
    country: 'Japan',
    iso2: 'JP',
    iso3: 'JPN',
  },
  {
    name: 'Germany NFR',
    country: 'Germany',
    iso2: 'DE',
    iso3: 'DEU',
  },
  {
    name: 'Malaysia',
    country: 'Malaysia',
    iso2: 'MY',
    iso3: 'MYS',
  },
  {
    name: 'Thailand',
    country: 'Thailand',
    iso2: 'TH',
    iso3: 'THA',
  },
  {
    name: 'Singapore',
    country: 'Singapore',
    iso2: 'SG',
    iso3: 'SGP',
  },
  {
    name: 'United Kingdom NFR',
    country: 'United Kingdom',
    iso2: 'GB',
    iso3: 'GBR',
  },
  {
    name: 'Netherlands NFR',
    country: 'Netherlands',
    iso2: 'NL',
    iso3: 'NLD',
  },
  {
    name: 'Belgium NFR',
    country: 'Belgium',
    iso2: 'BE',
    iso3: 'BEL',
  },
  {
    name: 'Korea (the Republic of)',
    country: 'Korea (the Republic of)',
    iso2: 'KR',
    iso3: 'KOR',
  },
];

export default MarketsList;
