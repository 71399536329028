const TH = {
  market: 'TH',
  inputs: {
    fields: ['ADDRESS1', 'ADDRESS2', 'ZIP', 'CITY', 'STATE'],
    ZIP: {
      required: false,
      labelRef: 'POSTAL_CODE',
    },
    STATE: {
      type: 'dropdown',
      labelRef: 'PROVINCE',
    },
  },
};
export default TH;
