import { Fragment } from 'react'
import { Typography, Stack, Divider } from '@newulife/common'
import ReviewOrderCell from './ReviewOrderCell';
import { isNull } from 'lodash';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import Price from 'components/common/Price';
import Volume from 'components/common/Volume';
import formatImageSrc from 'helpers/formatImageSrc'

function ReviewOrderTotals({
  orderTitle,
  orderPrice,
  volumeTitle,
  volumeTotal,
  volume2Title,
  volume2Total,
}) {
  return (
    <div>
      <Stack
        py={1}
      >
        <Typography
          variant="body1"
          mb={1}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
          }}
        >
          <span>{orderTitle}</span>
          <span data-testid="order-price">
            <Price value={orderPrice} />
          </span>
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
          mb={.5}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
          }}
        >
          <span>{volumeTitle}</span>
          <span data-testid="volume-total">
            <Volume type="amt" value={volumeTotal} />
          </span>
        </Typography>
        <Typography
          variant="body2"
          color="neutralCool.main"
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
          }}
        >
          <span>{volume2Title}</span>
          <span data-testid="volume-total">
            <Volume type="amt" value={volume2Total} />
          </span>
        </Typography>
      </Stack>
      <Divider light />
    </div>
  );
}

function ReviewOrderProductSection({ data }) {
  const { state, getTotalProductPrice, getTotalProductCV, getTotalProductQV } =
    useProducts();

  const { item2, item3, item4, item9 } = data.fields;
  const price = getTotalProductPrice();
  const cv = getTotalProductCV();
  const qv = getTotalProductQV();

  return (
    <Stack
      mb={8}
    >
      <Typography
        variant="body1"
        mb={1}
      >
        {item2}
      </Typography>
      {!isNull(state.starterKit) && (
        <>
          <Divider light />
          <ReviewOrderCell
            imgSrc={formatImageSrc(state.starterKit.SmallImageURL)}
            imgAlt={state.starterKit.DisplayName}
            title={state.starterKit.DisplayName}
            price={state.starterKit.Price}
            cv={state.starterKit.Volume}
            qv={state.starterKit.Volume2}
            qty={state.starterKit.Quantity}
          />
        </>
      )}
      {state.products.map((product, index) => (
        <Fragment
          key={index}
        >
          <Divider light />
          <ReviewOrderCell
            imgSrc={formatImageSrc(product.SmallImageURL)}
            imgAlt={product.DisplayName}
            title={product.DisplayName}
            price={product.WholesalePrice}
            cv={product.Volume}
            qv={product.Volume2}
            qty={product.Quantity}
          />
        </Fragment>
      ))}
      <Divider light />
      <ReviewOrderTotals
        orderTitle={item3}
        orderPrice={price}
        volumeTitle={item4}
        volumeTotal={cv}
        volume2Title={item9}
        volume2Total={qv}
      />
    </Stack>
  );
}

function ReviewOrderSubscriptionSection({ data }) {
  const {
    state,
    getTotalSubscriptionPrice,
    getTotalSubscriptionCV,
    getTotalSubscriptionQV,
  } = useSubscriptions();

  if (state.subscriptions.length === 0) {
    return null;
  }

  const { item3, item4, item5, item6, item7, item9 } = data.fields;
  const price = getTotalSubscriptionPrice();
  const volume = getTotalSubscriptionCV();
  const volume2 = getTotalSubscriptionQV();

  return (
    <Stack
      data-testid="subscription-section"
      mb={8}
    >
      <Typography
        variant="body1"
        mb={1}
      >
        {item5}
      </Typography>
      <Typography
        variant="body2"
        color="neutralCool.main"
        mb={2}
        data-testid={state.subscriptions.title}
      >
        {item6}
        <strong>{item7}</strong>
      </Typography>
      {state.subscriptions.map((subscription, index) => (
        <Fragment
          key={index}
        >
          <Divider light />
          <ReviewOrderCell
            imgSrc={formatImageSrc(subscription.SmallImageURL)}
            imgAlt={subscription.DisplayName}
            title={subscription.DisplayName}
            price={subscription.WholesalePrice}
            cv={subscription.Volume}
            qv={subscription.Volume2}
            qty={subscription.Quantity}
          />
        </Fragment>
      ))}
      <Divider light />
      <ReviewOrderTotals
        orderTitle={item3}
        orderPrice={price}
        volumeTitle={item4}
        volumeTotal={volume}
        volume2Title={item9}
        volume2Total={volume2}
      />
    </Stack>
  );
}

export default function ReviewOrderModal({ data }) {
  const { title, item1, item8 } = data.fields;

  return (
    <>
      <Stack
        display="grid"
        justifyItems="center"
        textAlign="center"
        gap={2}
        pb={4}
      >
        <Typography
          variant="header1"
        >
          {title}
        </Typography>
        <Typography
          variant="subhead2"
          maxWidth="440px"
        >
          {item1}
        </Typography>
      </Stack>
      <ReviewOrderProductSection data={data} />
      <ReviewOrderSubscriptionSection data={data} />
      <Typography
        variant="body2"
        textAlign="center"
        color="neutralCool.main"
        fontStyle="italic"
        pb={4}
      >
        {item8}
      </Typography>
    </>
  );
}
