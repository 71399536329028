import { default as React, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot, useRecoilSnapshot } from 'recoil';
import { ToastContainer } from 'react-toastify';
import { initGoogleTagManager } from 'api/gtm';
import Layout from 'components/common/Layout';
import Loader from 'components/common/loaders/SpinnerLoader';
import { AccountContextProvider } from 'contexts/AccountContext';
import { ModalContextProvider } from 'contexts/ModalContext';
import { LoaderContextProvider } from 'contexts/LoaderContext';
import { ProductContextProvider } from 'contexts/ProductContext';
import { SubscriptionContextProvider } from 'contexts/SubscriptionContext';
import { ThemeProvider } from '@newulife/common'
import ErrorBoundary from './components/common/errors/ErrorBoundary';
import App from 'App.jsx';
import reportWebVitals from 'reportWebVitals'
import { EnvironmentProvider } from './contexts/EnvironmentContext'

import 'react-toastify/dist/ReactToastify.css';
import 'styles/app.min.css';
import 'styles/enrollment.min.css';

// function DebugObserver() {
//   const snapshot = useRecoilSnapshot();
//   useEffect(() => {
//     console.debug('The following atoms were modified:');
//     for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
//       console.debug(node.key, snapshot.getLoadable(node));
//     }
//   }, [snapshot]);

//   return null;
// }

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      {/* <DebugObserver /> */}
      <ErrorBoundary>
      <ToastContainer theme="colored" position='bottom-left'/>
        <LoaderContextProvider>
          <ProductContextProvider>
            <SubscriptionContextProvider>
              <AccountContextProvider>
                <ThemeProvider>
                  <EnvironmentProvider>
                    <ModalContextProvider>
                      <React.Suspense fallback={<Loader active />}>
                        <Layout>
                          <App />
                        </Layout>
                      </React.Suspense>
                    </ModalContextProvider>
                  </EnvironmentProvider>
                </ThemeProvider>
              </AccountContextProvider>
            </SubscriptionContextProvider>
          </ProductContextProvider>
        </LoaderContextProvider>
      </ErrorBoundary>
    </RecoilRoot>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Initialization is dependent on environment variables being present.
initGoogleTagManager();
