export const localeIdMap = {
  "Dutch - Belgium": "36",
  "Dutch - Netherlands": "35",
  "English": "37",
  "English": "39",
  "English": "-3",
  "English": "-2",
  "English": "-1",
  "English": "43",
  "English - Belgium": "-22",
  "English - Germany": "-15",
  "English - Ireland": "41",
  "English - Japan": "-11",
  "English - Korea": "-24",
  "English - Malaysia": "-12",
  "English - Netherlands": "-16",
  "English - Singapore": "131",
  "English - Thailand": "-25",
  "English - United Kingdom": "47",
  "Español": "134",
  "French - Belgium": "53",
  "German - Germany": "59",
  "Japanese - Japan": "72",
  "Korean": "73",
  "ko-KR": "73",
  "KR": "73",
  "Malay - Malaysia": "77",
  "Simplified Chinese - Belgium": "-23",
  "Simplified Chinese - Germany": "-18",
  "Simplified Chinese - Malaysia": "-13",
  "Simplified Chinese - Netherlands": "-17",
  "Simplified Chinese - Singapore": "-14",
  "Simplified Chinese - United Kingdom": "-19",
  "Thai - Thailand": "123",
  "简体中文": "27",
  // "简体中文": "28",
  // "简体中文": "-9",
  // "简体中文": "-8",
  // "简体中文 ": "-7",
  // "简体中文": "-4",
  // "繁體中文": "-6",
  // "繁體中文": "-5",
  // "繁體中文": "31",
  // "繁體中文": "-10",
}

export const getTermLabelMap = (list) => {
  const termLabelMap = {
    TERMS: list[0],
    GAIYOSHOMEN: list[1],
    PRIVACY: list[2],
    AGE_20: '',
    AGE: '',
    PRIVACY_POLICY: list[2],
    PERSONAL_INFO_POLICY: list[2],
    UNIQUE_INFO_POLICY: list[2],
    THIRD_PARTY_POLICY: list[2],
    MARKETING_POLICY: list[2],
  };

  return termLabelMap;
};

export const termSourceMap = {
  TERMS: 'terms',
  GAIYOSHOMEN: 'gaiyoshomen',
  AGE_20: '',
  AGE: '',
  PRIVACY_POLICY: 'privacyPolicy',
  PERSONAL_INFO_POLICY: 'personalInfoPolicy',
  UNIQUE_INFO_POLICY: 'uniqueInfoPolicy',
  THIRD_PARTY_POLICY: 'thirdPartyPolicy',
  MARKETING_POLICY: 'marketingPolicy',
};
