import ProductCardContainer from './ProductCardContainer';
import ProductQtyOnlyCardContainer from './ProductQtyOnlyCardContainer';
import ProductSubscriptionOnlyCardContainer from './ProductSubscriptionOnlyCardContainer';

export default function ProductCardContainerRenderer({ data, cardText }) {
  const { SignupOptionalItem, SignupAutoshipOption } = data;
  const { item2, item3, item4 } = cardText?.fields;

  // SignupOptionalItem flag enables individual product buying.
  // SignupAutoshipOption flag enables subscribing.
  if (SignupOptionalItem && SignupAutoshipOption) {
    return (
      <ProductCardContainer
        data={data}
        subscribeText={item2}
        initialOrderText={item4}
      />
    );
  } else if (SignupOptionalItem && !SignupAutoshipOption) {
    return <ProductQtyOnlyCardContainer data={data} subscribeText={item2} />;
  } else if (!SignupOptionalItem && SignupAutoshipOption) {
    return (
      <ProductSubscriptionOnlyCardContainer data={data} subscribeText={item3} />
    );
  } else {
    return null;
  }
}
