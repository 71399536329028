const DE = {
  market: 'DE',
  inputs: {
    ZIP: {
      required: false,
      labelRef: 'POSTAL_CODE',
    },
  },
};
export default DE;
