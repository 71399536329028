import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { gtmCapturePurchase } from 'api/gtm';
import order from 'api/order';
import user from 'api/user';
import Header from 'components/steps/Header';
import Congratulations from 'components/steps/confirmation/Congratulations';
import useContentfulApi from 'hooks/useContentfulApi';
import MainContent from 'components/common/layout/MainContent';
import { useRecoilState } from 'recoil';
import { formState } from 'state';
import { toast } from 'react-toastify';
import CONTENTFUL from 'constants/contentful'


export default function Confirmation() {
  // Context
  const [form, setForm] = useRecoilState(formState);

  // Custom Hooks
  const data = useContentfulApi(CONTENTFUL.confirmation);
  const location = useLocation();

  // State
  const [rep, setRep] = useState({});
  const [taxIdNotUnique, set_taxIdNotUnique] = useState(false);
  const [profileUrl, setProfileUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const params = new URLSearchParams(location.search);
  const orderId = params.get('OrderID');
  const tempOrderId = params.get('OnlineOrderID');
  const repId = params.get('repDID');

  useEffect(() => {
     // Someone came to this page directly, redirect to home page
    if(!orderId) {
      window.location.href = '/';
    }

    apiCalls();
  }, []);

  useEffect(() => {
    const handleUnload = (e) => {
      localStorage.clear();
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => window.removeEventListener('beforeunload', handleUnload);
  }, []);

  const apiCalls = async () => {
    const user = await getUser();
    const response = await updateOrderAndRepId();
    await getLanguageCode();
    await getGtmInfo(user);
    
    if (response?.ValidationErrors?.API_VALIDATE_REPSSN_ERROR === 'SSN/TaxID1 already in use.') {
      set_taxIdNotUnique(true);
    } else {
      set_taxIdNotUnique(false);
      setProfileUrl(response?.profileUrl)
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    const res = await user.get(repId);

    if (typeof res !== 'string') {
      setRep(res);
      return res;
    } else {
      toast.error(res, { toastId: res, autoClose: false })
      return {};
    }
  };

  const updateOrderAndRepId = async () => {
    return order.updateOrderAndRepId({
      orderId,
      tempOrderId,
      repId,
    });
  };

  const getLanguageCode = async () => {
    const res = await user.getLanguageCode(repId);

    if (typeof res === 'object') {
      const { languageCode: locale } = res[0];
      setForm({ ...form, locale });
    }
  };

  const getGtmInfo = async (rep) => {
    const { ShipCountry } = rep;

    // Get order information for gtm.
    const data = await order.getGtmInfo({ orderId });

    // Get currency used for enrollment.
    const res = await user.getMoneyInfo(ShipCountry);
    if (res.length === 0)
      toast.warn('Error in API call "getMoneyInfo"', {
        toastId: 'getMoneyInfo-warn'
      })

    if (typeof order === 'object' && typeof res === 'object') {
      const { currencyTypeCode } = res[0];
      gtmCapturePurchase({ data, currencyTypeCode });
    }
  };

  if (!data || isEmpty(rep)) {
    return null;
  }

  const { headerRef } = data?.fields;

  return (
    <div className="page success">
      <Header type="confirmation" data={headerRef} />
      <MainContent>
          <Congratulations
          data={data}
          rep={rep}
          taxIdNotUnique={taxIdNotUnique}
          profileUrl={profileUrl}
          apiIsLoading={isLoading}
          />
      </MainContent>
    </div>
  );
}
