import React, { createContext, useContext } from 'react'
import config from 'config'

const isDevEnvironment = config.APP_ENV === 'development' || config.APP_ENV === 'localhost'
const EnvironmentContext = createContext(isDevEnvironment)

export const EnvironmentProvider = ({ children }) => {
  return (
    <EnvironmentContext.Provider value={isDevEnvironment}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export const useEnvironment = () => useContext(EnvironmentContext)